<template>
	<Modal ref="baseModal">
		<div class="share-member">
      <div class="share-member-wrap" style="overflow: auto">
        <div class="admin-logo-image"> <!-- edit : 2023.11.10 -->
          <img id="profileImg5" src="@/assets/img/default-profile.svg" alt="" class="img-profile"> <!-- edit : 2023.11.10 -->            
        </div>
        <div>  
          <div class="flex-box">
                      <span class="label-text">{{ L('Name') }} ( {{ L('Id') }} )</span>
                      <span class="inner-field-text">{{result.userName === undefined ? '' :  result.userName + ' (' + result.userId +') '}}</span> <!-- 수정 -->
          </div>
          <div class="flex-box">
                      <span class="label-text">{{ L('Email') }}</span>
                      <span class="inner-field-text">{{ result.email === undefined ? '' :  result.email }}</span>
          </div>
          <div class="flex-box">
                      <span class="label-text">{{ L('Phone number') }}</span>
                      <span class="inner-field-text">{{ this.phoneFomatter(result.phoneNumber) }}</span>
          </div>
          <div class="flex-box">
                      <span class="label-text">{{ L('Cell Phone number') }}</span>
                      <span class="inner-field-text">{{ this.phoneFomatter(result.cellPhoneNumber)}}</span>
          </div>
          
          <div class="flex-box">
                      <span class="label-text">{{ L('Department') }}</span>
                      <span class="inner-field-text">{{ result.departmentName}}</span>
          </div>          
          <div class="flex-box">
                      <span class="label-text">{{ L('Position') }}</span>
                      <span class="inner-field-text">{{ result.positionName}}</span>
          </div>
          <div class="confirm-buttons" style="text-align:center;">
              <button class="btn btn-orange btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="close">{{L('Close')}}</button>
          </div>
        </div> 
      </div>
    </div>
	</Modal>
</template>


<script>
import api from '../../api';
import { ref } from "vue";

import Modal from "@/components/files/utils/Modal.vue";
import { L, alert2, showToast, formatDate, formatDateTime, overwriteConfirm } from "../../utils";
import profileImg from '@/assets/img/default-profile.svg';

export default {
  name: "FolderModal",
  emits: ['select','close'],
  components: {
    Modal,
  },
  props:{
    files: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      result: [],
      profileImg: null,
    };
  },

  methods :{
    async load(item) {

      let _userId = "";
      if(location.pathname == '/sharedProgress'){
        if(item.sharedOut){
          _userId = item.shareWith
        } else {
          _userId = item.sharedBy
        }
      }else{
        _userId = item.sharedBy
      } 
      const _userInfo = await api.getUserInfo(_userId);

      this.result = _userInfo;
      if(this.result.profileImg === undefined || this.result.profileImg.indexOf('default-profile.svg') > 0) this.result.profileImg = profileImg;
 
      document.getElementById('profileImg5').src = this.result.profileImg;
      
    },

    show(item){ 
        this.$refs.baseModal.open();
        this.load(item);
    },

    close(){
        this.$refs.baseModal.close();        
        
    },
    phoneFomatter(num,type){
      var formatNum = '';
      if(num == undefined)  return; 
      
      if(num.length==11){
          if(type==0){
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
          }else{
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
          }
      }else if(num.length==8){
          formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
      }else{
          if(num.indexOf('02')==0){
              if(type==0){
                  formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
              }else{
                  formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
              }
          }else{
              if(type==0){
                  formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
              }else{
                  formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
              }
          }
      }
      return formatNum;
    },
  }
};
</script>



