
import { createRouter, createWebHistory } from 'vue-router'
import MyFilesView from '../views/MyFilesView.vue';
import { isMobile } from 'mobile-device-detect';

export const isMobileDevice = isMobile;

const ROUTES = {
  login: () => import('../views/LoginView.vue'),
  search: () => import('../views/SearchView.vue'),
  recent: () => import('../views/RecentView.vue'),
  starred: () => import('../views/StarredView.vue'),
  sharedOut: () => import('../views/SharedOutView.vue'),
  sharedIn: () => import('../views/SharedInView.vue'),
  sharedDisarmed: () => import('../views/SharedDisarmedView.vue'),
  sharedProgress: () => import('../views/SharedProgressView.vue'),
  listLinkShare: () => import('../views/SharedLinkView.vue'),
  SharedTransFer: () => import('../views/SharedTransFerView.vue'),

  recycle: () => import('../views/RecycleView.vue'),
  settings: () => import('../views/SettingsView.vue'),
  security: () => import('../views/SecurityView.vue'),  
  
  
  organize: () => import('../views/OrganizeView.vue'),  
  organizeTab: () => import('../components/files/utils/TreeTab.vue'),
  
  adminShare: () => import('../views/AdminShare.vue'),
  adminSecurity: () => import('../views/AdminSecurity.vue'),
  adminTheme: () => import('../views/AdminTheme.vue'),
  
  link: () => import('../views/ShareLink.vue'),
  edit: () => import('../views/EditView.vue'),

  groupStatistics: () => import('../views/AdminGroupStatistics.vue'),
  departmentStatistics: () => import('../views/AdminDepartmentStatistics.vue'),
  userStatistics: () => import('../views/AdminUserStatistics.vue'),


  versions: () => import('../views/VersionView.vue'),  

}

const routes = Object.keys(ROUTES).map(key => ({ path: '/' + key, name: key, component: ROUTES[key] }));
routes.push({ path: '/', name: 'my-files', component: MyFilesView });
if(isMobile){  
  import ('@/assets/css/styleM.scss');
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      setTimeout(() => window.scrollTo(0, 0), 300)
      return { top: 0 }
    }
  }
})

export default router
