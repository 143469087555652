import Swal from 'sweetalert2';
import { TOAST_TIMEOUT } from './const';

import { useL10nStore } from './stores/l10n';
import { usePreferenceStore } from './stores/preference';


export const progressBar = () => {
  let timerInterval
  Swal.fire({
    title: L('Lehua Drive'),
    //html: '',
    timer: 1000000,
    timerProgressBar: false,
    didOpen: () => {
      Swal.showLoading()
      // const b = Swal.getHtmlContainer().querySelector('b')      
    },
    willClose: () => {
      clearInterval(timerInterval)
    }
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer')
    }
  })
};


export const alert2 = (text, title, icon) => {
  document.querySelector('.modal.open')?.removeAttribute('tabindex');

  const styles = document.querySelector('html').getAttribute('style')
  const topBackgroundColor = styles.match(/--top-background-color:\s*([^;]+)/)[1].trim();

  return Swal.fire({
    title: title || L('Lehua Drive'),
    html: text.replace(/</g, '&lt;').replace(/\n/g, '<br>').replace(/\\n/g, '<br>'),
    icon: icon || 'info',
    confirmButtonText: L('Ok'),
    confirmButtonColor: topBackgroundColor || '#FEBE10',
  }).then(() => {
    document.querySelector('.modal.open')?.setAttribute('tabindex', '0');
  });
};



export const confirm2 = (text, title, icon) => {
  
  const styles = document.querySelector('html').getAttribute('style')
  const topBackgroundColor = styles.match(/--top-background-color:\s*([^;]+)/)[1].trim();

  document.querySelector('.modal.open')?.removeAttribute('tabindex');

  return Swal.fire({
    title: title || L('Lehua Drive'),
    html: text.replace(/</g, '&lt;').replace(/\n/g, '<br>'),
    icon: icon || 'question',
    confirmButtonText: L('Ok'),
    confirmButtonColor: topBackgroundColor || '#FEBE10',
    showCancelButton: true,
    cancelButtonText: L('Cancel'),
    customClass: { actions: 'my-actions',
                   cancelButton: 'order-1',
                   confirmButton: 'order-2',
                   denyButton: 'order-3', 
                 },
  }).then(function(result) {
    document.querySelector('.modal.open')?.setAttribute('tabindex', '0');
    return result.value === true;
  });
};

export const prompt2 = (text, title, icon, moreOptions) => {
  document.querySelector('.modal.open')?.removeAttribute('tabindex');

  const styles = document.querySelector('html').getAttribute('style')
  const topBackgroundColor = styles.match(/--top-background-color:\s*([^;]+)/)[1].trim();

  return Swal.fire({
    title: title || L('Lehua Drive'),
    html: text.replace(/</g, '&lt;').replace(/\n/g, '<br>'),
    input: 'text',
    customClass: { input: 'browser-default',
                   actions: 'my-actions',
                   cancelButton: 'order-1',
                   confirmButton: 'order-2',
                   denyButton: 'order-3', 
                 },
    // icon: icon || 'question',
    icon: icon || 'info',
    //imageUrl: '/src/assets/img/ico-exclamation-mark.png', 
    //imageAlt: 'A tall image',

    confirmButtonText: L('Ok'),
    confirmButtonColor: topBackgroundColor || '#FEBE10',
    
    showCancelButton: true,
    cancelButtonText: L('Cancel'),
    cancelButtonColor: '#CBCCCE',
    ...(moreOptions || {}),
  }).then(function(result) {
    document.querySelector('.modal.open')?.setAttribute('tabindex', '0');
    return result.value;
  });
};

export const showLoader = () => {
  //document.getElementById('page-loader').style.display = 'flex';
};

export const hideLoader = () => {
  //document.getElementById('page-loader').style.display = 'none';
};

export const showError = async (result) => {
  hideLoader();

  var msg;
  if (!result) {
    msg = L('Unknown error');
    console.log(new Error().stack)
  } else if (result.responseJSON) { // jQuery
    msg = result.responseJSON.message || result.responseJSON.error;
    console.log(result.responseJSON);
  } else if ('status' in result) { // fetch
    try {
      if (result.json)
        result = await result.json();
      else if (result.responseText)
        result = JSON.parse(result.responseText);
    } catch (e) {
      // 무시
    }
  }

  if (!msg) {
    if (result.message || result.error || result.errors) {
      msg = result.message || result.error || result.errors.join('\n');
      if (msg && msg.search(/\{\d+:.+?\}/) >= 0) {
        const args = [];
        msg = msg.replace(/\{(\d+):(.+?)\}/g, (_m, p1, p2) => {
          args.push(p2);
          return `{${p1}}`;
        });
        msg = L(msg, ...args);
      } else {
        msg = L(msg);
      }

    } else {
      if (result.status === 0) {
        msg = L('Network failure');
      } else if (result.status === 401) {
        msg = L('Login required');
      } else if (result.status === 403) {
        msg = L('You don\'t have permission or your session has expired');
      } else if (result.status === 404) {
        msg = L('Item not found');
      } else {
        msg = L('Unknown server error');
      }
    }
  }

  if (!msg) {
    msg = String(result);
  }

  showToast(msg, 'error');
}

export const showToast = (text, icon) => {

  return Swal.fire({
    toast: true,
    html: text.replace(/</g, '&lt;').replace(/\n/g, '<br>'),
    //html: '선택한 [_111] 디렉토리의  하위 파일중 <br> [ /_111/IOS 공유 조직도 화면시안.pptx, /_111/000/관리자사이트디자인.pptx]<br> 의 파일이 사용중입니다.',
    position: 'center',
    iconColor: 'white',
    showConfirmButton: false,
    width: '35rem',
    timer: TOAST_TIMEOUT,
    icon,
    customClass: {
      popup: 'colored-toast'
    },
  });
}

const toDateObject = d => {
  if (!d) return '';

  const t = typeof(d);

  if (t === 'number' || t === 'string')
    return new Date(d);

  if (d.getFullYear)
    return d;

  return null;
}

export const formatDateTime = (date, opt) => { 
  const d = toDateObject(date);
  if (!d) return '';

  return d.toLocaleString(usePreferenceStore().lang, opt || { dateStyle: 'medium', timeStyle: 'short' });
}

export const formatDate = (date, opt) => {
  const d = toDateObject(date);
  if (!d) return '';

  return d.toLocaleDateString(usePreferenceStore().lang, opt || { dateStyle: 'medium' });
};

export const formatYmd = (date) => {
  const d = toDateObject(date);
  if (!d) return '';

  return d.getFullYear() + (d.getMonth() < 9 ? '-0' : '-') + (d.getMonth() + 1)
      + (d.getDate() < 10 ? '-0' : '-') + d.getDate();
}

export const formatFileSize = (size) => {
  if (!size) return '';

  if(size < 0 || size == 4096) size = 0;
  if (size < 1000)
    return size + ' B';
  else if (size < 1000 * 1024)
    return (size / 1024).toPrecision(3) + ' KB';
  else if (size < 1000 * 1024 * 1024)
    return (size / 1024 / 1024).toPrecision(3) + ' MB';
  else if (size < 1000 * 1024 * 1024 * 1024)
      return (size / 1024 / 1024 / 1024).toPrecision(3) + ' GB';
  else
    return (size / 1024 / 1024 / 1024 / 1024).toPrecision(3) + ' TB';
}

export const fileSize = (size) => {
  if (!size) return '';

  if (size < 1000)
    return size + ' B';
  else if (size < 1000 * 1024)
    return (size / 1024).toPrecision(3) + ' KB';
  else if (size < 1000 * 1024 * 1024)
    return (size / 1024 / 1024).toPrecision(3) + ' MB';
  else if (size < 1000 * 1024 * 1024 * 1024)
      return (size / 1024 / 1024 / 1024).toPrecision(3) + ' GB';
  else
    return (size / 1024 / 1024 / 1024 / 1024).toPrecision(3) + ' TB';
}

export const fileSize2 = (size) => {
  if (!size) return '';

  return (size / 1024 / 1024 / 1024).toPrecision(3);
}


export const sizeType = (min) => {
  
  if (min < 1000)
    return 'Byte';
  else if (min < 1000 * 1024)
    return 'KB';
  else if (min < 1000 * 1024 * 1024)
    return 'MB';
  else if (min < 1000 * 1024 * 1024 * 1024)
      return 'GB';
  else
    return 'TB';
 
}

export const statisticsSize = (size, min) => {
  if (!size) return '';

  if (min < 1000)
    return size;
  else if (min < 1000 * 1024)
    return (size / 1024).toPrecision(3);
  else if (min < 1000 * 1024 * 1024)
    return (size / 1024 / 1024).toPrecision(3);
  else if (min < 1000 * 1024 * 1024 * 1024)
      return (size / 1024 / 1024 / 1024).toPrecision(3);
  else
    return (size / 1024 / 1024 / 1024 / 1024).toPrecision(3);
 
}

export const getMin = (data) => {
  return data.reduce((min, b) => Math.min(min, b.size == 0 ? min : b.size), data[0].size == 0 ? data[1].size : data[0].size);
}

export const L = (key, ...args) => {
  const text = useL10nStore().l10n?.[key] || key;
  return text.replace(/\{(\d+)\}/g, (match, number) => args[number] ?? match);
}

export const parentOf = path => { 
  if (path.endsWith("/"))
    path = path.slice(0, path.length - 1);

  if (!path.startsWith("/"))
    path = "/" + path;

  if (path === '/')
    return null;

  const index = path.lastIndexOf('/');
  if (index <= 0)
    return "/";

  return path.slice(0, index);
}

export const createSelection = (el, start, end) => {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(start, end);
  } else if (el.createTextRange) {
    var range = el.createTextRange();
    range.collapse(true);
    range.moveEnd('character', end);
    range.moveStart('character', start);
    range.select();
    el.focus();
  } else if (typeof el.selectionStart !== 'undefined' ) {
    el.selectionStart = start;
    el.selectionEnd = end;
    el.focus();
  }
}

export const overwriteConfirm = async (count, filename) => {

  document.querySelector('.modal.open')?.removeAttribute('tabindex');

  // const prompt = count > 1 ? L(`overwriteConfirmN {0}`, count) : L(`overwriteConfirm1 {0}`, filename);
  const prompt = count > 1 ? L(`{0}개 파일이 중복됩니다`, count) : L(`{0} 파일이 중복됩니다`, filename);
  
  // const inputOptions = {
  //   'replace': count === 1 ? L('Replace existing file') : L('Replace existing items'),
  //   'keep': count === 1 ? L('Keep both files') : L('Keep all items')
  // };

  const inputOptions = {
    'replace': count === 1 ? L('덮어쓰기') : L('덮어쓰기'),
    'keep': count === 1 ? L('건너뛰기') : L('건너뛰기')
  };

  const result = await Swal.fire({
    title: L('파일 중복'),
    // imageUrl: '../src/assets/img/ico-exclamation-mark.png',
    imageAlt: 'A tall image',
    html: prompt,
    input: 'radio',
    inputOptions: inputOptions,
    inputValidator: (value) => {
      if (!value) {
        return L('Please select an option');
      }
    },
    icon: 'warning',
    confirmButtonText: L('Ok'),
    showCancelButton: true,
    cancelButtonText: L('Cancel'),
  });
  
  document.querySelector('.modal.open')?.setAttribute('tabindex', '0');
  
  return result.value;
}


export const  byteCheck = (s) =>{
  if (s == null || s.length == 0) {
    return 0;
  }

  var size = 0;
  for ( var i = 0; i < s.length; i++) {
    size += charByteSize(s.charAt(i));
  }
  return size;
}

export const charByteSize =(ch) => {
  if (ch == null || ch.length == 0) {
    return 0;
  }

  var charCode = ch.charCodeAt(0);

  if (charCode <= 0x00007F) {
    return 1;
  } else if (charCode <= 0x0007FF) {
    return 2;
  } else if (charCode <= 0x00FFFF) {
    return 3;
  } else {
    return 4;
  }
}

export const cutByteLength = (s, len) => {
  if (s == null || s.length == 0) {
    return 0;
  }

  var size = 0;
  var rIndex = s.length;

  for ( var i = 0; i < s.length; i++) {
    size += charByteSize(s.charAt(i));
    if( size == len ) {
      rIndex = i + 1;
      break;
    } else if( size > len ) {
      rIndex = i;
      break;
    }
  }
  return s.substring(0, rIndex);
}
