<template>
      <div class="tabs1">
        <ul>
          <li v-for="(tab, idx) in tabMenuArr" :key="tab.organizeId" role="presentation" :class="tab.organizeId === currTab ? 'active': ''">
            <template v-if="tabMenuArr[idx] ==='Search' || tabMenuArr[idx] ==='Favorites'">
              <a href="#etc2"  @click="handleClick($event, tab.organizeId)">
                <i class="icon"></i>
                <span>{{ L(tab.name) }}</span>
            </a>
            </template>
            <template v-else>
              <a href="#etc"  @click="handleClick($event, tab.organizeId)">
                <i class="icon"></i>
                <span>{{ L(tab.name) }}</span>
              </a>
            </template>
          </li>
        </ul>   
    </div>
</template>
<script>
import api from '../../../api';
import { shallowRef } from 'vue';

import { usePreferenceStore } from '../../../stores/preference';
import { useSessionStore } from '../../../stores/session';

export default {
  setup() {
    const preference = usePreferenceStore();
    const session = useSessionStore();

    return { preference, session };
  },
  emits: ['command','handleClick'],
  components: {
  },
  data: function () {
    return {
        tabMenuArr: [],
        currTab: this.preference.detailTab,
      };
  },
  computed: {
    detailOpen() {
      return this.preference.detailOpen;
    },
  },
  watch: {
    detailOpen() {
      if (this.detailOpen) this.load();
    },
    selected() {
      if (this.detailOpen) this.load();
    },
  },
  mounted() {
    this.currTab = '';
    this.load(); 
  },

  methods: {

    async load() {
      this.tabMenuArr = await api.organizeTab();

      this.tabMenuArr.push({id:0, organizeId:'Favorites',name:this.L('친구목록'), depth:0 });
      this.tabMenuArr.push({id:0, organizeId:'Search',name:this.L('Search'), depth:0 });
      
      if(this.currTab === '') {
        this.currTab = this.tabMenuArr[0].organizeId;
        this.preference.setDetailTab(this.tabMenuArr[0].organizeId);
      }
      this.$emit('command',  this.currTab);
    },

    handleClick(e, component) {
      //if(component != "Search"){
        this.currTab = component;
        this.preference.setDetailTab(this.currTab); 
        this.$emit('command', component);
      // }else{
      //   this.preference.setDetailTab(this.tabMenuArr[0].organizeId); 
      // }
    },

    searchClick(component) {
      this.currTab = component;
      this.preference.setDetailTab(this.currTab);     
    },
    
    async handleDescription(e) {
      alert(e.target.value);
    }
  },
};
</script>

<style>
.file-list { height: 500px; flex: 1; overflow-y: auto; }

.file-detail {
  width: var(--tree-width); min-width: var(--tree-width); height: 100%; overflow: auto;
  background-color: white; border-left: solid 1px #ddd;
  transition: transform 0.3s ease-in-out;
}
.detail-open .file-detail { transform: translateX(-100%); }

.file-detail-info .card { overflow: hidden; }
.file-detail-info .preview {
  display: flex; justify-content: center; align-items: center;
  height: calc(var(--tree-width) * 5 / 9);
}
.file-detail-info .preview img { width: 100%; height: 100%; }
.file-detail-info .preview .file-icon img { width: 90px; }

.tab-content .tab-pane {padding:10px 5px;}
.file-detail-info .card .card-text .badge {margin: 5px 5px 5px 0;}
.file-detail-info .card-body .description { cursor: pointer; }

.file-detail-info .card .card-img-top.recyclebin {
  opacity:0.4;
}
.file-detail-info .card .card-img-top.recyclebin::after {
  position:absolute;
  font-weight: bolder;
  font-size: 5rem;
  content:'No';
  opacity:0.5;
  top:1.5rem;
  left: calc((100% - 5rem) / 2);
}
@media screen and (min-width: 1024px) {
  .detail-open .file-detail { transform: translateX(0); }
}
.file-detail .filename-wrap { display: flex; justify-content: flex-end; align-items: center; padding:10px 0;}
.file-detail .filename-wrap .filename { flex: 1; font-weight:300; }
</style>
