import api from '../../api';

// --- Implement Cut/Copy/Paste --------------------------------------------
export const copyPaste = (action, node) => {
    var clipboardNode = null;
    var pasteMode = null;
    switch( action ) {
    case "cut":
    case "copy":
        clipboardNode = node;
        pasteMode = action;
        break;
    case "paste":
        if( !clipboardNode ) {
            alert("Clipoard is empty.");
            break;
        }
        if( pasteMode == "cut" ) {
            // Cut mode: check for recursion and remove source
            var cb = clipboardNode.toDict(true);
            if( node.isDescendantOf(cb) ) {
                alert("Cannot move a node to it's sub node.");
                return;
            }
            node.addChildren(cb);
            node.render();
            clipboardNode.remove();
        } else {
            // Copy mode: prevent duplicate keys:
            var cb = clipboardNode.toDict(true, function(dict, node){
                dict.title = "Copy of " + dict.title;
                delete dict.key; // Remove key, so a new one will be created
            });
            alert("cb = " + JSON.stringify(cb));
            // node.addChildren(cb);
            // node.render();
            node.applyPatch(cb);
        }
        clipboardNode = pasteMode = null;
        break;
        default:
        alert("Unhandled clipboard action '" + action + "'");
    }
};

// --- Contextmenu helper --------------------------------------------------
export const bindContextMenu = (span) =>{
    // Add context menu to this node:
    $(span).contextMenu({menu: "myMenu"}, function(action, el, pos) {
        // The event was bound to the <span> tag, but the node object
        // is stored in the parent <li> tag
        var node = $.ui.fancytree.getNode(el);
        switch( action ) {
        case "cut":
        case "copy":
        case "paste":
            copyPaste(action, node);
            break;
        default:
            alert("Todo: appply action '" + action + "' to node " + node);
        }
    });
};

export const contextMenuEvent = (event) => {}

export const handleCreateContextMenu = (event) =>{

        // 기본 Context Menu가 나오지 않게 차단
        event.preventDefault();
        
        // Context Menu Element 생성
        const ctxMenuId = 'dochi_context_menu';
        const ctxMenu = document.createElement('div');
        
        // Context Menu Element 옵션 설정
        ctxMenu.id = ctxMenuId;
        ctxMenu.className = 'custom-context-menu';
        
        // 위치 설정
        ctxMenu.style.top = event.pageY+'px';
        ctxMenu.style.left = event.pageX+'px';
        
        // 메뉴 목록 생성
        ctxMenu.appendChild(renderContextMenuList(
            [
                {
                    label: "링크생성",
                    //href: "naver.com",
                    //tag: "a",
                    onClick: function(event){
                        alert("링크생성");
                        contextMenuEvent(event);
                    }
                },
                {
                    label: "상세정보",
                    href: "naver.com",
                    tag: "a",
                    onClick: function(event){
                        contextMenuEvent(event);
                        //alert( event.target.innerText );
                    }
                },
                {
                    label: "이동",
                    tag: "a",
                    onClick: function(event){
                        contextMenuEvent(event);
                        //alert( event.target.innerText );
                    }
                },
                {
                    label: "이름 바꾸기",
                    href: "google.com",
                    tag: "a",
                    onClick: function(event){
                        contextMenuEvent(event);
                        //alert( event.target.innerText );
                    }
                },
                {
                    label: "즐겨찾기 추가",
                    href: "naver.com",
                    tag: "a",
                    onClick: function(event){
                        contextMenuEvent(event);
                        //alert( event.target.innerText );
                    }
                }
            ]
        ));
        
        // 이전 Element 삭제
        const prevCtxMenu = document.getElementById( ctxMenuId );
        if( prevCtxMenu ){
        prevCtxMenu.remove();
        }
        
        // Body에 Context Menu를 추가.
        document.body.appendChild( ctxMenu );
}
    
    // Context Menu 제거
export const handleClearContextMenu = (event) =>{
        const ctxMenu = document.getElementById('dochi_context_menu');
        if( ctxMenu ){
            ctxMenu.remove();
        }
}

export const renderContextMenuList = ( list ) =>{
        // List Element 생성
        const ctxMenuList = document.createElement('ul');
        
        // List Item 생성
        list.forEach(function( item, i ){
            // Item Element 생성
            const ctxMenuItem = document.createElement('li');
            const ctxMenuItem_a = document.createElement('a');
            const ctxMenuItem_a_text = document.createTextNode(item.label);
    
            let href = (item.href !== undefined) ? item.href : '#';
            ctxMenuItem_a.setAttribute('href', href);
            
            
            // 클릭 이벤트 설정
            if( item.onClick ){
                ctxMenuItem.addEventListener( 'click', item.onClick, false );
            }
    
            // Item 추가
            ctxMenuItem_a.appendChild( ctxMenuItem_a_text );
            ctxMenuItem.appendChild( ctxMenuItem_a );
            ctxMenuList.appendChild( ctxMenuItem );
        });
        // 문서잠금 Element 생성
        let ctxMenuItemLockLi = document.createElement('li');
        let ctxMenuItemSwitchDiv = document.createElement('div');
        ctxMenuItemSwitchDiv.classList.add('switch');
        let ctxMenuItemSpan = document.createElement('span');
        let ctxMenuItemSpanText = document.createTextNode('문서 잠금1');
        let ctxMenuItemCheck = document.createElement('input');
        ctxMenuItemCheck.setAttribute('type', 'checkbox');
        ctxMenuItemCheck.setAttribute('id', 'switch');
        let ctxMenuItemLabel = document.createElement('label');
        ctxMenuItemLabel.setAttribute('for', 'switch');
        // 문서잠금 Item 추가
        ctxMenuItemSpan.appendChild( ctxMenuItemSpanText );
        ctxMenuItemSwitchDiv.appendChild( ctxMenuItemSpan );
        ctxMenuItemSwitchDiv.appendChild( ctxMenuItemCheck );
        ctxMenuItemSwitchDiv.appendChild( ctxMenuItemLabel );
        ctxMenuItemLockLi.appendChild( ctxMenuItemSwitchDiv );
        ctxMenuList.appendChild( ctxMenuItemLockLi );
        
        // List Element 반환
        return ctxMenuList;
}
