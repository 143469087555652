
import { copyPaste, bindContextMenu } from '../plugin/contextMenu' ;

export const treeMenuControl = ($router) =>{
    (function($) {
        const titleWidth = () => {
            let titleBox = $('#tree > ul > li');
        }
    })(jQuery);
    // --- Init fancytree during startup ----------------------------------------
    $(function(){
        $("#tree, #folder-tree").fancytree({
            extensions: ["dnd"],
            activate: function(event, data) {
                var node = data.node;
                $("#echoActivated").text(node.title + ", key=" + node.key);
                
            },
            click: function(event, data) { 
                // Close menu on click
                if( $(".contextMenu:visible").length > 0 ){
                    $(".contextMenu").hide();
                    // return false;
                }
            },
            keydown: function(event, data) {
                var node = data.node;
                // Eat keyboard events, when a menu is open
                if( $(".contextMenu:visible").length > 0 )
                    return false;
    
                switch( event.which ) {
    
                // Open context menu on [Space] key (simulate right click)
                case 32: // [Space]
                    $(node.span).trigger("mousedown", {
                        preventDefault: true,
                        button: 2
                        })
                    .trigger("mouseup", {
                        preventDefault: true,
                        pageX: node.span.offsetLeft,
                        pageY: node.span.offsetTop,
                        button: 2
                        });
                    return false;
    
                // Handle Ctrl-C, -X and -V
                case 67:
                    if( event.ctrlKey ) { // Ctrl-C
                        copyPaste("copy", node);
                        return false;
                    }
                    break;
                case 86:
                    if( event.ctrlKey ) { // Ctrl-V
                        copyPaste("paste", node);
                        return false;
                    }
                    break;
                case 88:
                    if( event.ctrlKey ) { // Ctrl-X
                        copyPaste("cut", node);
                        return false;
                    }
                    break;
                }
            },
            /*Bind context menu for every node when its DOM element is created.
                We do it here, so we can also bind to lazy nodes, which do not
                exist at load-time. (abeautifulsite.net menu control does not
                support event delegation)*/
            createNode: function(event, data){
               // bindContextMenu(data.node.span);
                const a = data.node.li.querySelector('a');
                a.onclick = (e) => {
                    e.preventDefault();
                    $router.push(a.getAttribute('href'));
                }
            },
            /*Load lazy content (to show that context menu will work for new items too)*/
            lazyLoad: function(event, data){
                data.result = {url: "sample-data2.json"};
            },
            /* D'n'd, just to show it's compatible with a context menu.
                    See http://code.google.com/p/dynatree/issues/detail?id=174 */
            dnd: {
                preventVoidMoves: true, // Prevent dropping nodes 'before self', etc.
                preventRecursiveMoves: true, // Prevent dropping nodes on own descendants
                autoExpandMS: 400,
                dragStart: function(node, data) {
                    return true;
                },
                dragEnter: function(node, data) {
                    // return true;
                        if(node.parent !== data.otherNode.parent)
                            return false;
                        return ["before", "after"];
                },
                dragDrop: function(node, data) {
                    data.otherNode.moveTo(node, data.hitMode);
                }
            }
        });
    });
};
