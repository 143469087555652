import api from '@/api';

// extensions defined in https://api.onlyoffice.com/editors/config/index.htm
const WORD_EXTENSIONS = '.doc.docm.docx.docxf.dot.dotm.dotx.epub.fodt.fb2.htm.html.mht.odt.oform.ott.oxps.pdf.rtf.txt.djvu.xml.xps';
const CELL_EXTENSIONS = '.csv.fods.ods.ots.xls.xlsb.xlsm.xlsx.xlt.xltm.xltx';
const SLIDE_EXTENSIONS = '.fodp.odp.otp.pot.potm.potx.pps.ppsm.ppsx.ppt.pptm.pptx';

function included(ext, extensions) {
  const idx = extensions.indexOf(ext);
  if (idx < 0) return false;

  return idx + ext.length >= extensions.length || extensions.charAt(idx + ext.length) === '.';
}

function getExtension(path) {
  return path.slice(path.lastIndexOf('.')).toLowerCase();
}

function getDocumentType(ext) {
  if (included(ext, WORD_EXTENSIONS))
    return 'word';

  if (included(ext, CELL_EXTENSIONS))
    return 'cell';

  if (included(ext, SLIDE_EXTENSIONS))
    return 'slide';

  return null;
}

function getEditorConfig(path, type, mode, lang) {
  return api.fetchGet('/api/office/editorConfig', { path, type, mode, lang });
}

export default {
  getEditorConfig,
  getExtension,
  getDocumentType,
}
