<template>
  <template v-if="isMobileDevice">
    <PageLayoutM>
      <FileListM
        ref="fileList"
        :path="$route.query.path"
        :columns="columns"
        :loader="load"
        :defaultSort="defaultSort"
        :emptyMessage="L('Drop files here')"
        :FileHeaderTitle="L('File List')"        
        :FileHeaderDisplay ="'Y'"
        :RecycleYn ="'N'"      
        :shareYn ="'N'"
        :deleteYn ="'N'"
        :etcYn ="'N'"
        :upload ="'Y'"
        :newFolder ="'Y'"
        :newDoc ="'Y'"
        :download ="'Y'"
        @select="handleSelect"
        @open="file => handleCommand('view', file)"         
        @headHandle="command => handleCommand(command, this.selected)"
        @menu="$refs.contextMenu.show($event, this.selected)"    
        @updated="files => this.files = files"
      />      
    </PageLayoutM>
  </template>
  <template v-else>
    <PageLayout>
      <FileList
        ref="fileList"
        :path="$route.query.path"
        :columns="columns"
        :loader="load"
        :defaultSort="defaultSort"
        :emptyMessage="L('Drop files here')"
        :FileHeaderTitle="L('File List')"        
        :FileHeaderDisplay ="'Y'"
        :RecycleYn ="'N'"      
        :shareYn ="'Y'"        
        :deleteYn ="'Y'"        
        :etcYn ="'Y'"
        :upload ="'Y'"
        :newFolder ="'Y'"
        :newDoc ="'Y'"   
        :download ="'Y'"             
        @select="handleSelect"
        @open="file => handleCommand('view', file)"         
        @headHandle="command => handleCommand(command, this.selected)"
        @menu="$refs.contextMenu.show($event, this.selected)"    
        @updated="files => this.files = files"
      />
    </PageLayout>
  </template>

 
  <UploadHandler ref="uploadHandler" :path="$route.query.path" :dragUploadYn=true @upload="files => $refs.fileList.updateList(files)" />


  <ContextMenu
    ref="contextMenu"
    :commands="['view','edit', 'link', 'open', 'download', 'share', 'detail', 'star', 'rename', 'move', 'copy', 'delete', 'detail','lock']"
    :selected="selected"
    @command="handleCommand"
  />
  
  
  
  <FileOperationHandler
    ref="fileOperationHandler"
    :path="$route.query.path"
    :files="files"
    :selected="selected"
    @create="file => $refs.fileList.updateList([file])"
    @move="files => $refs.fileList.removeFiles(files.map(path => ({ path })))"
    @copy="(_files) => {}"
    @rename="file => $refs.fileList.updateList([file])"
    @delete="$refs.fileList.removeFiles(selected)"
    @close="close"
  />

  

</template>

<script>
import { shallowRef } from 'vue';
import api from '../api';
import ContextMenu from '../components/files/utils/ContextMenu.vue';
import DateColumn from '../components/files/utils/DateColumn.vue';
import FileIconColumn from '../components/files/utils/IconColumn.vue';
import FilenameColumn from '../components/files/utils/NameColumn.vue';
import ShareImgColumn from '../components/files/utils/ShareImgColumn.vue';
import SizeColumn from '../components/files/utils/SizeColumn.vue';

import FileOperationHandler from '../components/handlers/FileOperationHandler.vue';
import UploadHandler from '../components/handlers/UploadHandler.vue';

import PageLayout from '../components/PageLayout.vue';
import FileList from '../components/files/FileList.vue';

import { FILE_LIST_SIZE } from '../const';
import { usePreferenceStore } from "../stores/preference.js";
import { alert2, confirm2, parentOf, showToast } from '../utils';

import { isMobile } from 'mobile-device-detect';
import PageLayoutM from '../components/PageLayoutM.vue';
import FileListM from '../components/files/FileListM.vue';

export default {
  name : 'file',
  setup() { 
    const preference = usePreferenceStore();
    return { preference }
  },
  
  components: {
    PageLayout,  FileList, PageLayoutM,  FileListM, UploadHandler, FileOperationHandler, ContextMenu
  },
  data() {
    return {
      folder: null,
      columns: [
        { field: 'chk',          width:'40px',  label: '',                 component: ''},
        // { field: 'starred',      width:'110px',  label: this.L('starred'),  component: '',                           sort: true },
        { field: 'icon',         width:'90px',  label: this.L('Type'),     component: shallowRef(FileIconColumn),   sort: true },
        { field: 'filename',     width:'*',     label: this.L('Name'),     component: shallowRef(FilenameColumn),   sort: true },         
        { field: 'size',         width:'100px', label: this.L('Size'),     component: shallowRef(SizeColumn),       sort: true },
        { field: 'shared',       width:'80px',  label: this.L('Sharing'),  component: shallowRef(ShareImgColumn) }, 
        { field: 'lastModified', width:'280px', label: this.L('Modified'), component: shallowRef(DateColumn),       sort: true },
      ],
      files: [],
      selected: [],
      defaultSort :'lastModified,desc',
      isMobileDevice : isMobile,      
    };
  },

  methods: {
    async load(sort, page) {      
      this.defaultSort = sort;
      let result;
      if (!page) {
        result = await api.getFolderInfo(this.$route.query.path, this.$route.query.parentId, sort, FILE_LIST_SIZE);

        if (!result || !result.folder) return;

        if (!result.folder.isFolder) { // 파일인 경우 상위 폴더로 변경
          let parent = parentOf(result.folder.path);
          if (parent === '/') parent = '';
          this.$router.replace({ path: result.folder.sharedBy ? '/shared' : '/', query: { path: parent } });
          return;
        } else if (result.folder.sharedBy) { // 공유 폴더인 경우 공유 경로로 이동
          this.$router.replace({ path: '/shared', query: { path: result.folder.path } });
          return;
        }

        this.folder = result.folder;

        result = result.children || { content: [] };
      } else {
        result = await api.listChildren(this.$route.query.path, sort, FILE_LIST_SIZE, page);
      }

      document.querySelectorAll('.fancytree-expander')[0].click();
      document.querySelectorAll('.fancytree-node')[0].classList.add('fancytree-active');
      if(document.querySelectorAll('.fancytree-node')[3].parentNode.children[1] != undefined){
        document.querySelectorAll('.fancytree-node')[3].classList.remove('fancytree-expanded');
        document.querySelectorAll('.fancytree-node')[3].parentNode.children[1].style.display = 'none';
      }      

      return result;
    },

    handleSelect(selected) {
      
      // if(!this.isMobileDevice){
      //   this.$refs.contextMenu.hide();
      //   this.selected = selected;
      // }else{
      //   if(selected.length > 0){
      //     this.selected = selected;
      //   }
      // }
      this.selected = selected;

      //console.log("handleSelect >>" , this.selected.length)
      document.querySelectorAll('.dropdown-menu').forEach(function(ul) {
            ul.classList.remove('active');
      });
      
      let detailYn = false;
      document.querySelectorAll('.right-inner-content').forEach(function(ul) {
        ul.classList.forEach(function(c) {
          
          if(c == 'active'){
            detailYn = true;
            return;
          }
        });        
      });

      

      if(detailYn && !isMobile ) this.$refs.fileList.fileDetailInfo(selected);
    },

    async handleCommand(command, item) {

      //console.log("command >>" , command);

      if(document.querySelectorAll('.count-file').length > 0){
        document.querySelectorAll('.count-file')[0].classList.remove('on');
      }

      this.$refs.contextMenu.hide();

      // const map = new Map(); // 맵
      // for(const character of this.selected){
      //   map.set(JSON.stringify(character), character); // name, company가 모두 같은 객체 요소는 제외한 맵 생성
      // }
      // this.selected = [...map.values()];
      //console.log("command >>" , command);
      switch (command) {
        case 'new':
          this.$refs.fileOperationHandler.newFolder();
          break;
        case 'upload-files':
          this.$refs.uploadHandler.openFileInput();
          break;
        case 'upload-folder':
          this.$refs.uploadHandler.openFolderInput();
          break;
        case 'view':
          this.viewFile(this.$route.query.path, item);
          break;
        case 'edit':
          this.editFile(this.$route.query.path, item);
          break;
        case 'download':
          this.$refs.fileOperationHandler.download();
          break;
        case 'star': {
          let _idx2 = item.filter(v => v.starred === false).length; /* 즐겨찾기 처리되어 있는 않은 count */

          if(_idx2 > 0){
            for(var i=0; i <item.length; i++){
              if(!item[i].starred){
                item[i].starred = await api.setStarred(item[i].path, !item[i].starred);
                document.querySelectorAll('.favorites').forEach(function(ul) {
                  if(item[i].id == ul.children[0].id){
                    if(item[i].starred){
                      ul.children[0].checked =true;
                    }else{
                      ul.children[0].checked =false;
                    }
                  }
                });
              }
            }
          }else{
            for(let i=0; i <item.length; i++){
                item[i].starred = await api.setStarred(item[i].path, !item[i].starred);
                
                document.querySelectorAll('.favorites').forEach(function(ul) {
                  if(item[i].id == ul.children[0].id){
                      if(item[i].starred){
                      ul.children[0].checked =true;
                    }else{
                      ul.children[0].checked =false;
                    }
                  }
                });                
            }
          }
          document.querySelectorAll('.checkbox').forEach(function(ul) {
            if(ul.children[0] !== undefined && ul.children[0].checked){
              ul.children[0].click();
            }
          });

          break;
        }
        case 'rename':
          this.$refs.fileOperationHandler.rename();
          break;

        case 'move': {
          const moveFileIds = [];
          if (item == undefined){
            moveFileIds[0] = this.selected[0].id;
          }else{
            for(let i=0; i <item.length; i++){
              moveFileIds[i] = item[i].id;
            }
          }
          const moveResult = await api.checkOfficeLock(moveFileIds);

          if (moveResult.status && moveResult.type =='officeLock'){
            showToast(moveResult.filename+' '+this.L('Someone else is using the file.'), 'error');
          }else{
            this.$refs.fileOperationHandler.move();
          }
          break;
        }

        case 'copy':
          this.$refs.fileOperationHandler.copy();
          break;
        case 'delete': {
          
          const deleteFileIds = [];
          for(let i=0; i<this.selected.length; i++){
            deleteFileIds[i] = this.selected[i].id;
          }
          
          const _delIdx = this.selected.findIndex((v) => {
              return v.sharedOut === true || v.fileLock === true;
          });
          
          const deleteResult = await api.checkOfficeLock(deleteFileIds);
          
          if(deleteResult.status){
            //console.log(deleteResult.result.length )
            if (deleteResult.type =='fileLock' || deleteResult.type =='share'){
 
              if(deleteResult.result.length > 1){ 
                alert2(this.L('Other than {0}, {1} cases contain locked or shared files. When you`re finished working on or sharing a document, delete it.', deleteResult.result[0][2], (deleteResult.result.length -1)), '', 'info');
              }else{
                alert2(this.L('Contains locked or shared files. Please lock or unshare the document and then delete it.'));
              }
              
              this.selected = [];
              return;
            }else{
              alert2(this.L('Someone else is using the file.'));
              this.selected = [];
              return;
            }
          }else{
            const ok = await confirm2(this.L('Move to the recycle bin'), this.L('Delete?'), 'warning');
            if (!ok) return;
          }

          this.$refs.fileOperationHandler.delete();
          break;
        }
        
        case 'detail':
          this.$refs.fileList.fileDetailInfo(item);
          break;
        
        case 'link':
          this.$refs.fileOperationHandler.link();
          break;

        case 'share':
          this.$refs.fileList.share();
          break;  

        case 'lock': {
          const lockFileIds = [];
          for(let i=0; i <item.length; i++){
              lockFileIds[i] = item[i].id;
          }
         
            let fileLockStatus = true;

            let _idx = item.filter(v => v.fileLock === true).length; /* 문서잠금 처리되어 있는 count */
            let _idx2 = item.filter(v => v.fileLock === false).length; /* 문서잠금 처리되어 있는 않은 count */

            if(_idx == 0 && _idx2 > 0) fileLockStatus = true;
            if(_idx > 0 && _idx2 == 0) fileLockStatus = false;

            for(let i=0; i <item.length; i++){

              item[i].fileLock = await api.setFileLock(item[i].id, fileLockStatus);          
              document.getElementById('switch').checked =item[i].fileLock;
                
              if(!item[i].fileLock){
                showToast(this.L('Document Lock Off'), 'success');
                document.querySelectorAll('.switch')[0].children[2].style.backgroundColor= "#FEBE10";
              }else{
                showToast(this.L('Document Lock On'), 'success');
                document.querySelectorAll('.switch')[0].children[2].style.backgroundColor="#8d8c89"
              }
            }
          
                    
          document.querySelectorAll('.checkbox').forEach(function(ul) {
            if(ul.children[0] !== undefined && ul.children[0].checked){
              ul.children[0].click();
            }
          });
          break;
        }

        case 'createWord': {
          const fileDocx = await this.$refs.fileOperationHandler.createFile({type:"docx", name:"문서"});

          if(fileDocx.path === "exists"){
            
            alert2(this.L('The file already exists. Please re-enter the file name.'),'Error').then(() => { 
              this.handleCommand('createWord', item);
            });

          }else{
            if (fileDocx) {
              this.$refs.fileList.updateList([fileDocx]);
              this.editFile(this.folder, fileDocx);
            }
          }

          break;
        }

        case 'createExcel': {
          const fileXlsx = await this.$refs.fileOperationHandler.createFile({type:"xlsx", name:"스프레드시트"});
          if(fileXlsx.path === "중복파일"){
            
            alert2(this.L('The file already exists. Please re-enter the file name.'),'Error').then(() => { 
              this.handleCommand('createExcel', item);
            });

          }else{
            if (fileXlsx) {
              this.$refs.fileList.updateList([fileXlsx]);
              this.editFile(this.folder, fileXlsx);
            }
          }
          break;
        }

        case 'createPpt': {
          const filePptx = await this.$refs.fileOperationHandler.createFile({type:"pptx", name:"프리젠테이션"});
          if(filePptx.path === "중복파일"){
            
            alert2(this.L('The file already exists. Please re-enter the file name.'),'Error').then(() => { 
              this.handleCommand('createPpt', item);
            });

          }else{
            if (filePptx) {
              this.$refs.fileList.updateList([filePptx]);
              this.editFile(this.folder, filePptx);
            }
          }

          break;
        }
      }

      let component = document.querySelectorAll('.dropdown-menu');
      for(let i=0; i<component.length; i++){
        component[i].classList.remove('active');
      }

      /* 오른쪽 마우스 클릭하여 Context Menu호출시 선택하였던 checkbox false 처리 */
      // document.querySelectorAll('.checkbox').forEach(function(ul, j) {
      //   if(ul.children[0] !== undefined){
      //     ul.children[0].checked = false;
      //   }
      // });

      //this.$refs.fileList.selectionBoxReset();
    },

    close(){
      this.$refs.fileList.shareReset();
    }
  },
  computed: {
    THIS() {
       return this;
    }
  },
};
</script>
