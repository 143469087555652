import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Keys } from '../const';
import storage from './persistentStorage';

export const useL10nStore = defineStore('l10n', () => {
  const l10n = ref({});
  
  async function setL10n(data) {
    if (data) {
      l10n.value = data;
      await storage.set(Keys.L10N, JSON.stringify(data));
    }
  }

  return { l10n, setL10n }
})
