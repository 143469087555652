<template>
	<!-- <div>
    <img src="@/assets/img/ico-list.png" @click.prevent="menuClick" alt="mobileMenu" id="mobileMenuImgL">
    <img src="@/assets/img/ico-close.png" @click.prevent="menuClick" alt="mobileMenu" id="mobileMenuImgX" style="display:none">    
  </div> -->

	<div class="suffixM">
    <div class="mobile-menu">
      <img src="@/assets/img/ico-list.png" @click.prevent="menuClick" alt="mobileMenu" id="mobileMenuImgL" class="mobile-menu-show">
      <img src="@/assets/img/ico-close.png" @click.prevent="menuClick" alt="mobileMenu" id="mobileMenuImgX" class="mobile-menu-hide" style="display:none">    
    </div>
    <div class="search-boxM">
      <form role="search" onsubmit="return false">
      <div class="search-boxM">        
        <button type="button" class="btn-search"><i class="icon icon-search"></i></button>
        <input  type="text" name="" :placeholder="L('Please enter your search term.')" @input="search = $event.target.value"
            @keyup.prevent="e => {
              if (e.which === 13) {              
                $router.push({ path:'/search', query: { text: search } });
              }
            }">
		  </div>
      </form>
		</div>
    <!-- <div class="profile-pic">
			<img v-if="result.profileImg === null||result.profileImg === undefined" id="profileImg2" src="@/assets/img/default-profile.svg" :placeholder="L('Logout when click Profile Image')"  alt="profileImg" @click.prevent="toggleImg">
      <img v-else id="profileImg2" :src= result.profileImg  @click.prevent="toggleImg" :placeholder="L('Logout when click Profile Image')" alt="profileImg">      
		</div> -->
    <div class="profile-pic">
      <img id="profileImg2"
        alt="profileImg"
        @click.prevent="toggleImg"
        :placeholder="L('Logout when click Profile Image')"
        data-test-id="profileImg"
      >      
		</div>
    <div id="toggleImg"  class="toggleImg" >
      <ul>
        <li>&nbsp;</li>
        <li><p><b>{{result.userName + ' (' + result.userId + ') '}}</b> 님이</p> 로그인 하셨습니다.</li>
        <li>&nbsp;</li>
        <li>
          <a href="#logout" class="dropdown-item" aria-expanded="false" @click.prevent="handleSignOut">
            <span class="icon-log-out"></span> {{ L('Logout') }}
          </a>
        </li>
      </ul>
    </div>
		<div id="mobileMenu"  class="mobileMenu">
			<PageLeft />
		</div>
	</div> 
</template>

<script>
import { RouterLink } from 'vue-router';

import api from '../api';
import { useSessionStore } from '../stores/session';
import { confirm2 } from '../utils';
import PageLeft from './PageLeft.vue';
import logo from '@/assets/img/logo.png';
import profile from '@/assets/img/default-profile.svg';

export default {
  props: ['siteImg'],
  setup() {
    const session = useSessionStore();
    return { session };
  },
  components: {  RouterLink, PageLeft },
  data() {
    return {
      search: '',
      result: [],
      isShow: false
    };
  },

  mounted() { 
      this.profile();
  },

  computed: {
    webviewHandler() {
      return window.flutter_inappwebview?.callHandler;
    },
  },
  
  methods: {
    async profile(){
      this.result = await api.getPrivateInfo();
      
      if(this.result == undefined){
        this.result = [];
        this.result.userId = '';
        this.result.userName = '';
        return;
      } 

      if(this.result.profileImg !== undefined && this.result.profileImg !== null && this.result.profileImg !== 'null') {
        this.profileImg = this.result.profileImg;
      }else{
        this.profileImg = profile;
      }
      
      document.getElementById('profileImg2').src = this.profileImg; 
    },

    toggleSideMenu() {
      document.querySelector("#page-left").classList.toggle("active");
    },
    async handleSignOut() {
      const ok = await confirm2(this.L('Sign out?'), this.L('Logout'), 'question');
      if (ok) {
        api.signOut();
        this.$router.push("/login");
      }
    },
    async handleSearch() {
      this.$router.push({ path:'/search', query: { text: this.search } });
    },
    menuClick(){
      if(document.getElementById('mobileMenu').style.display == 'none'){
        document.getElementById('mobileMenu').style.display = 'block';
        document.getElementById('mobileMenuImgL').style.display = 'none';
        document.getElementById('mobileMenuImgX').style.display = 'block';

      }else{
        document.getElementById('mobileMenu').style.display= 'none';
        document.getElementById('mobileMenuImgL').style.display = 'block';
        document.getElementById('mobileMenuImgX').style.display = 'none';
      }
      document.querySelector(".toggleImg").style.display = 'none';
    },
    toggleImg(){
      if(document.querySelector(".toggleImg").style.display == 'block'){
        document.querySelector(".toggleImg").style.display = 'none';
      }else{
        document.querySelector(".toggleImg").style.display = 'block';
      }
      document.getElementById('mobileMenu').style.display= 'none';

      // alert("aaaa >> " + document.querySelector(".toggleImg").style.display);  
      // alert("aaaa >> " + document.querySelector(".toggleImg"));  
    }
},
};
</script>

