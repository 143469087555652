<template>
  <span>
    {{ fileSize(file[field]) }}
  </span>
</template>

<script>
import { formatFileSize, fileSize } from '../../../utils';

export default {
  CLASS_NAME: 'size-column',
  props: ['file', 'field'],
  methods: {
    formatFileSize, fileSize,
  },
};
</script>

<style>
.file-table .size-column { width: 12%; min-width: 5em; max-width: 7em; }

@media screen and (max-width: 600px) {
  .file-table .size-column {
    display: none;
  }
}
</style>
