<template>
  <!-- <FolderModal v-if="showFolderModal" :files="selected" :filter="selected.map(f => f.path)" @select="handleMoveCopy" /> -->
  <FolderModal ref="modal"  :files="selected" :filter="selected.map(f => f.path)" @select="handleMoveCopy" @close="close" />
  
  <ShareLinkInput ref="shareLink"  :files="selected" @shareLinkIns="shareLinkIns"/>
</template>

<script>
import { Modal } from 'bootstrap';

import api from '../../api';
import { INVALID_FILENAME_CHARS, MAX_FILENAME_LENGTH, MAX_PATH_LENGTH } from '../../const';
import { confirm2, createSelection, parentOf, prompt2, showToast } from '../../utils';
import FolderModal from '../panel/FolderModal.vue';
import ShareLinkInput from '../panel/shareLinkInput.vue';
import { useSessionStore } from "../../stores/session";

export default {
  components: { FolderModal , ShareLinkInput },
  emits: ['create', 'move', 'copy', 'rename', 'delete', 'restore','close','shareLinkIns'],
  props: {
    path: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFolderModal: null,
      shareSelect:[],
      _versionsList: [],
    };
  },
  methods: {
    // /** 파일/폴더 열기 */
    // async open(folder, file) {
    //   if (file.isFolder)
    //     this.$router.push({ query: { path: (folder || '') + '/' + file.filename } });
    //   else {
    //     if (file.contentType.startsWith('image/') || file.contentType === 'application/pdf') {
    //       const url = await api.getDownloadUrl(folder, [file.filename], true)
    //       window.open(url, '_blank', 'width=1000,height=800,scrollbars=yes,status=yes');
    //     } else {
    //       alert2(this.L('This file type is not supported yet'), 'Open file', 'warning');
    //     }
    //   }
    // },

    /** 새 폴더 만들기 */
    async newFolder() {
      let name = await prompt2('', this.L('New folder'), 'info', {
        inputPlaceholder: this.L('Please enter a folder name'),
        inputValidator: value => {
          value = value.trim();
          if (!value)
            return this.L('Please enter a folder name');
          if (value.search(INVALID_FILENAME_CHARS) >= 0)
            return this.L('not_allowed_characters');
          if (this.files.find(item => item.filename === value))
            return this.L('A file or folder with the same name already exists.');

          if (value.length > MAX_FILENAME_LENGTH)
            return this.L('Name is too long (Filename max: {0})', MAX_FILENAME_LENGTH);

          const parent = this.path;
          if ((parent + '/' + value).length > MAX_PATH_LENGTH)
            return this.L('Resulting path is too long (Path max: {0})', MAX_PATH_LENGTH);
        },
      }) || '';

      name = name.trim();
      if (!name) return;

      const folder = this.path + '/' + name;
      const result = await api.createFolder(folder);
      result.className = 'new';
      
      /* 폴더 생성후 생성된 위치 */
      //this.$router.push({ query: { path: this.path + '/' + name } });

      this.$emit('create', result);
    },

    /** 다운로드 */
    async download() {
      let path = this.selected.map(f => f.path);

      if(useSessionStore().login.userId == 'capri999') alert("this.selected.length:" + this.selected.length+ ">>>"+ path);
      await api.download(path);
    },


    /** 파일 이동 */
    async move() {
      // this.showFolderModal = 'move';
      // this.$nextTick(() => {
      //   Modal.getOrCreateInstance('#folderTreeModal').show()
      // });
      this.showFolderModal = 'move';
      this.$refs.modal.show();
    },

    /** 파일 복사 */
    async copy() {
      this.showFolderModal = 'copy';
      this.$refs.modal.show();
      // this.showFolderModal = 'copy';
      // this.$nextTick(() => {
      //   Modal.getOrCreateInstance('#folderTreeModal').show()
      // });
    },

    async handleMoveCopy(folder, overwrite) { 
      const move = this.showFolderModal === 'move';
      const tp = this.showFolderModal;
      let select = [];
      const map = new Map(); // 맵
      for(const character of this.selected){
          map.set(JSON.stringify(character), character);
      }
      select = [...map.values()];

      const result = await api[this.showFolderModal](select.map(f => f.path), folder.path, overwrite);

      if (result) {
        showToast(this.L('File(s) ' + (move ? 'moved' : 'copied')), 'success');

        this.$emit(tp, result);
        this.showFolderModal = null;
      }
    },
    
    /** 파일명 변경 */
    async rename() {
      const renameFileIds = [];
      renameFileIds[0] = this.selected[0].id;
      const renameResult = await api.checkOfficeLock(renameFileIds);

      if (renameResult.status && renameResult.type =='officeLock'){
        showToast(renameResult.filename+' '+this.L('Someone else is using the file.'), 'error');
        return;
      }
      
      const item = this.selected[0];

      let ext = "";
      let _fileName = "";
      if (item.contentType == 'FOLDER'){
         ext = "";
         _fileName = item.filename;
      }else{
         ext = item.filename.slice(item.filename.lastIndexOf('.')).toLowerCase();
         _fileName = item.filename.substr(0, item.filename.lastIndexOf('.'));
      }

      if (!item) return;

      let newName = await prompt2(this.L('Please enter the new name'), this.L('Rename'), 'info', {
        
        inputValue: _fileName,
        inputValidator: value => {
          value = value.trim();
          if (!value || value === item.filename.substr(0, item.filename.lastIndexOf('.')))
            return this.L('Please enter a new filename');
          if (value.search(INVALID_FILENAME_CHARS) >= 0)
            return this.L('not_allowed_characters');
          if (this.files.find(item => item.filename.substr(0, item.filename.lastIndexOf('.')) === value))
            return this.L('A file or folder with the same name already exists.');

          if (value.length > MAX_FILENAME_LENGTH)
            return this.L('Name is too long (Filename max: {0})', MAX_FILENAME_LENGTH);

          const parent = parentOf(item.path);
          if ((parent + '/' + value).length > MAX_PATH_LENGTH)
            return this.L('Resulting path is too long (Path max: {0})', MAX_PATH_LENGTH);
        },

        didOpen: (dom) => {
          const dotIndex = item.filename.lastIndexOf('.');
          createSelection(dom.querySelector('.swal2-input'), 0, dotIndex > 0 ? dotIndex : item.filename.length);
        },
        
      }) || '';

      newName = newName.trim();
      if (!newName) return;

      newName = newName+ext;

      const parent = parentOf(item.path);
      const result = await api.rename(item.path, parent + '/' + newName);

      if (result && result.length) {
        item.path = parent + newName;
        item.filename = newName;

        this.$emit('rename', item);
      }
    },

    /** 휴지통으로 이동 */
    async delete() {
      const result = await api.delete(this.selected.map(f => f.path));
      if (result) {
        showToast(this.L('File removed'), 'success');
        this.$emit('delete', result);
      }
    },

    /** 휴지통에서 복원 */
    async restore() {
      const result = await api.restoreRecycleBin(this.selected.map(f => f.filename), this.selected.map(f => f.timestamp));

      if (result) {
        showToast(this.L('Restored successfully.'), 'success');
        this.$emit('restore', result);
      }
    },

    /** 완전 삭제 */
    async deletePerm() {
      const ok = await confirm2(this.L('This cannot be undone.'), this.L('Delete permanently?'), 'warning');
      if (!ok) return;

      const result = await api.deleteRecycleBin(this.selected.map(f => f.filename), this.selected.map(f => f.timestamp));

      if (result) {
        showToast(this.L('Deleted successfully.'), 'success');
        
        const _useSize = await api.getUseStorageSize();
        for(let i=0; i< this.selected.length; i++){
            useSessionStore().rootFolder.size = _useSize;
        }
        this.$emit('delete', result);
      }
    },
    close(){
      this.$emit('close');
      this.showFolderModal = null;
    },
    /** 링크 생성 */
    async link() {
      const item = this.selected[0];
      if (!item) return;
      /* 비밀번호, 만료일자 팝업화면 호출 */
      this.$refs.shareLink.show(item);      
    },

    async shareLinkIns(item, password, expiration){
      
      let shareAuth = "Read";

      const result = await api.shareLinkReg(item.userId, item.path, expiration, password, shareAuth);

      if (navigator.clipboard) {
          await navigator.clipboard.writeText(result.url).then(() => { 
            showToast(this.L('Link copied to clipboard'), 'success');
          });
      } else {
          const el = document.createElement('textarea');
          el.value = link;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
      }
      this.$emit('close');
    },
    /**
     * 문서 만들기
     * @param {object} docType 파일 유형 정보
     * @returns 만들어진 파일 정보
     */
    async createFile(docType) {
      const ext = '.' + docType.type;

      let name = await prompt2('', this.L(docType.name), 'question', {
        inputValue: this.L(docType.name) + ext,
        inputValidator: value => {
          value = value.trim();
          //  if (!value)
          //    return this.L('Please enter a new filename');

          //  return this.validFilename(this.path, value);
        },
        didOpen: (dom) => {
          const input = dom.querySelector('.swal2-input');
          const dotIndex = input.value.lastIndexOf('.');
          createSelection(input, 0, dotIndex > 0 ? dotIndex : name.length);
        },
      }) || '';

      name = name.trim();
      if (name) {
        if (!name.toLowerCase().endsWith(ext))
          name += ext;

        const result = await api.createFile({type: docType.type, path: this.path + '/' + name});
        if (result) {
          result.className = 'new';
          return result;
        }
      }

      return null;
    },
  },
  
}
</script>
