<template>
	<FileHeader ref="fileHeader" @selectionBoxReset="selectionBoxReset" @command="headerHandleCommand" @shareReset="shareReset"  @sort="onSort" :page="page"
              :path="$route.query.path" :FileHeaderTitle="FileHeaderTitle" :FileHeaderDisplay="FileHeaderDisplay" 
              :item="this.selected" :starredYn="starredYn" :selectionType="selectionType" :RecycleYn="RecycleYn" @allCheck="allCheck" 
              :shareYn ="shareYn" :deleteYn ="deleteYn" :etcYn ="etcYn" :upload ="upload" :newFolder ="newFolder" :newDoc ="newDoc" :download ="download" 
              :deletedShareYn="deletedShareYn" @shareInfo="$refs.shareUser.show(this.selected[0])" @linkShareInfo="$refs.ShareLinkInfo.show(this.selected[0])" @userInfo="$refs.UserPanel.show(this.selected[0]);" />

  <div class="right-inner-content">
		<div class="inner-content-wrap" @scroll="handleScroll">
			<div class="inner-main">
				<div v-if="preference.layout === 'table'" class="list list-type" ref="container">
					<table class="table">
					<tbody>
					<template v-if="content?.length > 0">
						<!-- <tr v-for="(item, index) in content" :key="item.id" class="itemList"
              @touchend="handleTouchEnd($event, item, index)"
              @touchmove="handleTouchMove($event, item)"
              @touchstart="handleTouchStart($event, item, index)"> -->
              <tr v-for="(item, index) in content" :key="item.id" class="itemList"
                  @click="handleTouchEnd($event, item, index)">
							<td class="item" >
								<!-- <div class="checkbox" style="display:inline-block;padding-right: 0.3rem;" alt="chk" v-if="deletedShareYn !== 'Y'"> -->
                <div class="checkbox mobile-list-checkbox" v-if="deletedShareYn !== 'Y' && deletedShareYn !== 'D'">
                  <input ref="checkAll" type="checkbox" name="idx[]" class="chk" :id="'chk'+(index)" :value="index"/>
                  <label :for="'chk'+(index)"></label>
                </div> 
                <!-- <div style="display:inline-block;"> -->
                <div class="mobile-list-fileinfo">
									<FileIconColumnM :file="item"/>
									<span :tooltip="item.filename" :flow="[index === 0 ? 'down' : 'up']"  id="filename">   {{ contextType(item.filename) }}</span>                  
								</div>
								<!-- <div class="favoritesM"  style="height:2rem"> -->
                  
                <div class="favoritesM">
									<template v-if="deletedShareYn !== 'Y' && deletedShareYn !== 'D'">
                    <!-- <div style="display:inline-block;"> -->
                    <div class="favorites-checkbox">
                      <template v-if="item.starred === true">
                        <input type="checkbox" name="favorites[]" :id="item.id" value="on" checked>
                      </template>
                      <template v-else>
                        <input type="checkbox" name="favorites[]" :id="item.id" value="on">
                      </template>
                      <label :for="'favorites'+(index +1)"></label>
                    </div>
                    <!-- 2024.03.06 공유아이콘 상단으로 이동으로 인하여 주석
                    <div class="favorites-sharing"> 
                      <template v-if ="item.sharedOut == true && columns.find(elem => (elem.field === 'shared' || elem.field ==='shareName')) != undefined">                      
                        <a href="#" class="file-sharing"><img src="@/assets/img/ico-aside-sharing.png" alt="sharing" id="sharing" /></a>
                      </template>
                      <template v-else>
                        <template v-if ="columns.find(elem => (elem.field === 'shared' || elem.field ==='shareName')) != undefined">                      
                          <a href="#" id="sharedBy">{{ item.shareName }}</a> 
                        </template>
                        <template v-else>
                          <img src="@/assets/img/icon-link-building.png" alt="sharing" id="linkShared" />
                        </template> 
                      </template> 
                    </div>
                     -->
                    <div class="favorites-datetime">
                      {{ formatDateTime(item.lastModified) }}
                    </div>
                  </template>
                  <template v-else>                    
                    <template v-if="deletedShareYn == 'D'">
                      <div style="float: left;">
                        <template v-if="item.sharedOut">
                          <template v-if="item.shareWith.length > 1 && item.shareWith != '링크생성'">
                            {{ L('ShareBy') }} 
                            <!-- <a href="#" id="sharedBy">{{ '22' + item.shareWith }}</a>  -->
                          </template>
                          <template v-else>
                            {{ L('SharedLink') }}
                          </template>
                        </template>
                        <template v-else-if="item.sharedBy">
                          {{ L('SharedWith')  }}
                          <!-- <a href="#" id="sharedBy">{{ '11' + item.shareName }}</a>  -->
                        </template>
                      </div>
                      <div style="float: right;">
                        {{ formatDateTime(item.lastModified) }}
                      </div>

                    </template>
                    <template v-else>
                      <div style="display: inline-block;width: 10rem;position: absolute;margin-top: .8rem;">
                        <span style="font-size:.8rem">                  
                          <template v-if="item.sharedOut">
                            <template v-if="item.shareWith.length > 1 && item.shareWith != '링크생성'">
                              <!-- {{ L('ShareBy') + '('+ (item.shareWith) +')' }} -->
                              {{ L('ShareBy') }}
                            </template>
                            <template v-else>
                              {{ L('SharedLink') }}
                            </template>
                          </template>
                          <template v-else-if="item.sharedBy">
                            <!-- {{ L('SharedWith') + '('+ (item.shareName) +')' }} -->
                            {{ L('SharedWith') }}
                          </template>
                        </span>
                      </div>
                      <!-- <div style="display:inline-block;position: absolute;margin-top: 0.8rem;margin-left: 7rem;font-size:.9rem;"> -->
                      <div class="favorites-datetime">
                        {{ formatDateTime(item.lastAccessed) }} 
                      </div>
                    </template>
                    
                  </template>
									<!-- <div style="display:inline-block;margin-left:calc(100vw - 11rem);" v-if="deletedShareYn !== 'Y'"> -->
                  <div class="favorites-more" v-if="deletedShareYn !== 'Y' && deletedShareYn !== 'D'">
										<img v-if="selected.length <= 1" src="@/assets/img/ico-more-vertical.png" @click.prevent="handleContextMenu($event, item)" alt="contextMenu" id="contextMenu">
                    <img v-else-if="selected.length > 1 && selected[selected.length - 1].id === item.id" src="@/assets/img/ico-more-vertical.png" @click.prevent="handleContextMenu($event, item)" alt="contextMenu" id="contextMenu">
									</div>
								</div>
							</td>
						</tr>
					</template>
					<template v-else-if="content">
						<tr><td class="item text-center"> {{ L('File not found') }}</td></tr>
					</template>
					</tbody>
					</table>
				</div>
				<div v-else class="list gallery-type" ref="container">
					<template v-if="content?.length > 0">
            <!-- :class="{ 'gallery-active': selected.includes(item), [item.className]: !!item.className }" -->
						<!-- <div v-for="(item, index) in content" :key="item.id" class="item"                
                  @click="handleTouchEnd($event, item, index)"
                  @touchmove="handleTouchMove($event, item)"
                  @touchstart="handleTouchStart($event, item, index)"> -->
            <div v-for="(item, index) in content" :key="item.id" class="item"
                  @click="handleTouchEnd($event, item, index)">
							<div class="item-status">
                  <div class="checkbox" style="display:inline-block;" alt="chk" v-if="deletedShareYn !== 'Y' && deletedShareYn !== 'D'">
                      <input type="checkbox" name="idx[]" class="chk" :id="'chk'+(index)" :value="index" />
                      <label :for="'chk'+(index)" ></label>
                      <!-- <label :for="'chk'+(index +1)" v-if="this.selected.includes(item)"></label> -->
                </div>
                <template v-if="!item._loadError && item.hasThumbnail">
                  <img
                    :src="`/api/public/thumbnail?token=${item.token}`"
                    :alt="item.filename"
                    :style="{ objectFit: item.contentType.startsWith('image/svg') ? 'contain' : 'cover' }"
                    class="thumbnail"
                    loading="lazy"
                    @error="item._loadError = true"
                    @dragstart.prevent="false"
                  >
                </template>
                <FileIconColumn v-else :file="item"/>
								<!-- <div class="share">
									<template v-if="item.sharedOut == true && deletedShareYn !== 'Y'">
										<a href="#" class="file-sharing"><img src="@/assets/img/ico-sharing.png" alt="sharing" id="sharing"/></a>
									</template>
								</div> -->
								<div class="favorites">
									<template v-if="item.starred === true">
										<input type="checkbox" name="favorites[]" :id="item.id" value="on" checked>
									</template>
									<template v-else>
										<input type="checkbox" name="favorites[]" :id="item.id" value="on">
									</template>
									<label :for="'favorites'+(index +1)"></label>
								</div>
							</div>
							<div class="filename">
                <div class="txt-filename">
                  <span :tooltip="item.filename" flow="up" id="filename">{{ contextType(item.filename) }}</span>
                </div>
                <!-- <div v-if="deletedShareYn !== 'Y'" style="display: inline;width: 3rem;padding-left: 0.5rem;vertical-align: super;"> -->
                <div v-if="deletedShareYn !== 'Y'" class="icon-more">
                  <img v-if="selected.length <= 1" src="@/assets/img/ico-more.png" @click.prevent="handleContextMenu($event, item)" alt="contextMenu" id="contextMenu">
                  <img v-else-if="selected.length > 1 && selected[selected.length - 1].id === item.id" src="@/assets/img/ico-more.png" @click.prevent="handleContextMenu($event, item)" alt="contextMenu" id="contextMenu">
                </div>
							</div>
						</div>
					</template>
					<template v-else-if="content">
						<div class="item">
							<div class="item-status empty"> {{ L('File not found') }}</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
	<ShareUserPanel ref="shareUser"  :files="selected" @select="load(page.page)"/>
	<FileDetail ref="fileDetail"  :files="selected"/>  
  <UserPanel ref="UserPanel"  :files="selected" @select="load()"/>
  <ShareLinkInfo ref="ShareLinkInfo"  :files="selected" @select="load()"/>
</template>

<script>
import { commonLoad } from '../../assets/js/common';
import { FILE_LIST_SIZE, IS_MOBILE, PAGE_COUNT_SIZE } from '../../const';
import { usePreferenceStore } from '../../stores/preference';
import { useSessionStore } from '../../stores/session';
import { alert2, showToast } from '../../utils';

import FileIconColumn from './utils/IconColumn.vue';
import FileIconColumnM from './utils/IconColumnM.vue';

import FileDetail from './FileDetailM.vue';
import FileHeader from './FileHeaderM.vue';

import ShareUserPanel from '../panel/ShareUserPanel.vue';

import { formatDate, formatDateTime } from '../../utils';
import UserPanel from '../panel/UserInfo.vue';
import ShareLinkInfo from '../panel/ShareLinkInfo.vue';

export default {
  components: { FileIconColumn,  FileHeader, FileDetail, ShareUserPanel, FileIconColumnM, UserPanel,ShareLinkInfo},
  emits: ['select', 'open', 'menu', 'updated','headHandle','selectionBoxReset','allCheck','share'],
  setup() {
    const session = useSessionStore();
    const preference = usePreferenceStore();
    return { session, preference }

  },
  props: {
    path: {
      type: String,
    },
    columns: {
      type: Array,
      required: true,
    },
    loader: {
      type: Function,
      required: true,
    },
    defaultSort: {
      type: String,
      required: true,
    },
    emptyMessage: {
      type: String,
      required: true,
    },

    FileHeaderTitle: String,
    FileHeaderDisplay : String,
    RecycleYn : String,        
    shareYn  : String,       
    deleteYn  : String,      
    deletedShareYn : String,
    etcYn  : String,
    upload: String, 
    newFolder: String, 
    newDoc: String, 
    download : String,
    disableFolderSelection: Boolean,
  },

  data() {
    return {

      content: null,
      totalElements: 0,
      sort: this.defaultSort,
      page: {
            sort: localStorage.getItem('sort'),
            total: localStorage.getItem('total'),
            page: localStorage.getItem('page') | 0,
            count: 5
      },
      totalpage: 0,
      preNext : false,
      selected: [],
      selectionBox: [],
      checkarr: [],
      allChecked: false,
      files: [],
      headerHeight: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
      asideWidth: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
      starredYn: true,
      selectionType : null,
      oldCheckValue:null,
      isFlag: false,
      longTouchToggle:false,
      isContextMenu:false,
      isSelectMode:false,
      isTouchMove:false,
      checkedLength:0,
      shared :"shared",
    };
  },
  computed: {    
    IS_MOBILE() {
      return IS_MOBILE;
    },
  },

  watch: {
    
    path() {
      // if(document.getElementById('chk_all').checked){        
      //   document.getElementById('chk_all').click();
      // }
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(0);
    },
    columns() { 
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(this.page.page);
    },
    loader() { 
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(this.page.page);
    },
    // selected() { 
    //   if(this.page.page === null) this.page.page = 0
    //   if (this.loader) this.load(this.page.page);
    // },
  },

  mounted() {

    // if(this.session.login.admin === true){
    //   this.$router.push("/adminShare");
    // }else{
      if(this.page.page === null) this.page.page = 0
      //if(this.page.sort !== null) this.sort = this.page.sort
      if (this.loader) this.load(this.page.page);
    

      const headerElement = document.querySelector('.top-header');
      if (headerElement) {
        this.headerHeight = headerElement.offsetHeight;
      }

      const asideElement = document.querySelector('.left-inner-content');
      if (asideElement) {
        this.asideWidth = asideElement.offsetWidth;
      }

      const tableElement = document.querySelector('.table-thead');
      if (tableElement) {
        this.tableHeight = tableElement.offsetHeight;
      }
    //}
  },

  updated(){
    commonLoad();
  },
  methods: {

    formatDate, formatDateTime,
    onPage(_page, _sort) { 
      localStorage.setItem('page', _page);
      localStorage.setItem('sort', _sort);
    },

    onSort(_command, _sort){ 
        this.sort =  _command +"," + _sort;
        this.load(0);
    },

    async load(page) {
      const result = await this.loader(this.sort, page);
      
      if (!result) return;
     
      this.content = result.content;
      this.content.forEach(item => {
        if (item.contentType.startsWith('image/') && !item.contentType.startsWith('image/svg')) {
          item.hasThumbnail = true;
          return;
        }

        const ext = item.filename.split('.').pop().toLowerCase();
        item.hasThumbnail = ['docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt', 'potx', 'pot', 'pdf', 'txt'].includes(ext);
      });

      this.totalElements = result.totalElements;
      this.totalpage = result.totalPages;
      if(PAGE_COUNT_SIZE < this.totalpage) this.preNext = true;     

      this.page = { 
            sort: this.sort,
            total: result.totalElements,
            page: page,
            count: FILE_LIST_SIZE
      }  
      this.updateCheckAll();

      this.$emit('updated', this.content);

    },

    
    handleTouchStart(e, item, index) {
      
    },

    handleTouchMove(e, item) {
      
      let selected = [...this.content];
      let _idx =selected.findIndex(v => v.id ===  item.id);
      this.isTouchMove = true;
      let _isTouchMove = this.isTouchMove;
      document.querySelectorAll('.chk').forEach(function(ul, j) {
        if(_idx == j && !_isTouchMove){
          if(ul.checked) ul.checked = false;
        }
      });

      const _ctxMenuId = document.querySelectorAll('.custom-context-menu');
      _ctxMenuId[0].style.display = "none";  

    },

    handleClickItem(e, item, index){
      alert2("클릭이벤트");
    },

    handleTouchEnd(e, item, index) {

      if(this.selected.length == 0) this.selected =[item];
      let selected = [...this.selected];
      let content = [...this.content];

      if(e.target.type === "checkbox"){
          if(e.target.checked){
            let _idx =selected.findIndex(v => v.id ===  item.id);
            if(_idx < 0) selected.push(item);
          }else{
            let _idx =selected.findIndex(v => v.id ===  item.id);
            if(_idx > -1) selected.splice(_idx, 1);
          }          
      }else{
        if(e.target.nodeName.toUpperCase() != 'LABEL'){ 
            if(e.target.id === "sharing"){
              this.$refs.shareUser.show(item);
            }else if(e.target.id === "password"){
              if(e.target.title == 'hide'){
                  e.target.title = 'show';
                  e.target.parentNode.children[1].style.display="none";
                  e.target.parentNode.children[2].style.display="";
                  e.target.parentNode.children[0].textContent = item.password;
                }else{
                  e.target.title = 'hide';
                  e.target.parentNode.children[1].style.display="";
                  e.target.parentNode.children[2].style.display="none";
                  e.target.parentNode.children[0].textContent = "******";
                }
            }else if(e.target.id === "linkUrl"){
              navigator.clipboard.writeText(e.target.title).then(() => { 
                showToast(this.L('Link copied to clipboard'), 'success');
              });
            }else if(e.target.id === "sharedBy"){
              /*공유 받은 파일일 경우에는 공유한 사용자 정보 팝업 호출 */
              //if(!item.sharedOut){
                this.$refs.UserPanel.show(item);
              //}

            }else if(e.target.id === "linkShared"){
              this.$refs.ShareLinkInfo.show(item);
            }else if(e.target.id === "filename"){
              //if(!this.isTouchMove){
                  this.$emit('open', item);
              //}
            } else {

              if(e.target.id === "contextMenu") return;

              let _selectedIdx =selected.findIndex(v => v.id ===  item.id);
              if(_selectedIdx < 0){
                selected = [item];
              }

              let _idx =this.content.findIndex(v => v.id ===  item.id);

              document.querySelectorAll('.chk').forEach(function(ul, j) {        
                if( j == _idx){
                  if(!ul.checked) ul.checked = true;
                } else {
                  if(ul.checked) ul.checked = false;
                }
              });
               
            }
        }
      } 

      this.selected = selected;      

      if( document.getElementById('chk_all') != null){ 
        if(!document.getElementById('chk_all').checked){
          if(this.selected.length > 0){
            document.getElementById('chk_all').checked = true;
            
          }else{
            document.getElementById('chk_all').checked = false;
          }
        }
      }
        
      let _idx2 =this.selected.findIndex(v => v.starred === false);
      if(_idx2 < 0){
        this.starredYn =false;
      } else{
        this.starredYn =true;
      }

      this.$emit('select', this.selected);
    },


    clearCheck(event){
      this.selected=[];
      this.selectionBox=[];
      this.isSelectMode = false;

      const itemLists = document.querySelectorAll('.itemList');
      const items = document.querySelectorAll('.item');

      itemLists.forEach((itemList) => {
        itemList.classList.remove('active');
      });

      items.forEach((item) => {
        item.classList.remove('active');
      });

    },

    

    handleContextMenu(event, item) { 

      if(this.selected.length < 2){
        this.selected = [item];
        let _idx =this.content.findIndex(v => v.id ===  item.id);

        document.querySelectorAll('.chk').forEach(function(ul, j) {        
          if( j == _idx){
            if(!ul.checked) ul.checked = true;
          }else{
            if(ul.checked) ul.checked = false;
          }
        });
      }

      this.$emit('select', this.selected);
      this.$emit('menu', event);

    },

    // 상위 컴포넌트에서 파일 목록의 파일을 제거하려는 경우 사용
    removeFiles(files) {
      let component = document.querySelectorAll('.btn-brown-outline');
      for(var i=0 ; i<component.length; i++){
        for(var j=0; j <component[i].classList.length; j++){
          if(component[i].classList[j] === 'checked-on'){
            component[i].classList.remove('checked-on');
          }
        }
      }
      
      if (files.length) {
        files.forEach(f => {
          const idx = this.content.findIndex(item =>
            ('path' in f && item.path === f.path) || (
              'location' in f && item.location === f.location && item.filename === f.filename
            ));
          this.content.splice(idx, 1);
        });
      }

      this.$emit('select', this.selected = []);
      this.$emit('updated', this.content);
      this.load(0);
    },

    // 상위 컴포넌트에서 파일 목록에 추가/갱신할 경우 사용
    async updateList(files) {
      if (!files || !files.length) return;

      this.load(0);
    },

    async handleScroll(e) {
      if (this.content.length >= this.totalElements) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !this.loading) {
        this.loading = true;
        await this.load(this.page.page + 1);
        this.loading = false;
      }
    },

    fileDetailInfo(item) {
      this.$refs.fileDetail.show(item);  
    },

    headerHandleCommand(command) {

      this.$emit('headHandle' ,command);
    },

    pageHandle(idx){ 
     this.load(idx -1);
    },

    selectionBoxReset(){ 
      this.selected = [];
      this.selectionBox = [];
      this.starredYn=true;
      this.selectionType="";
      
      this.isSelectMode = false;

      this.unSelectColor();

    },

    unSelectColor(){
      if(this.preference.layout === "table"){
        document.querySelectorAll('tr').forEach(function(ul, j) {
          ul.style.backgroundColor="";
        });
      }else{
        document.querySelectorAll('.item').forEach(function(ul, j) {
          ul.style.backgroundColor="";
        });
      }
    },



    allCheck(event){
      this.selected=[];
      this.selectionBox=[];

      if(this.content.length == 0) {
        event.target.checked = false;
        return;
      }

      if(event.target.checked){
        for(var i=0; i < this.content.length; i++){
          this.selected.push(this.content[i]);
          this.selectionBox.push(this.content[i]);
        }
      }

      let _idx2 =this.selectionBox.findIndex(v => v.starred === false);

      if(_idx2 < 0){
        this.starredYn =false;
      } else{
        this.starredYn =true;
      }

      this.selectionType = null;
      if(this.selectionBox.length > 0) {
        const result = this.selectionBox.filter((elem) => {
          return (elem.contentType === "FOLDER");
        });
        
        if(result.length ===0){
          this.selectionType = "FILE";
        }else{
          if(result.length == this.selectionBox.length){
            this.selectionType = "FOLDER";
          }else{
            this.selectionType = "MIX";
          }
        }
      } 

      if(this.preference.layout != 'table'){ 
        document.querySelector('.count-file').querySelector('.count').innerHTML = this.content.length;
      }

      this.$emit('select', this.selected); 
    },

    shareReset(){
      this.selected = [];
      let component = document.querySelectorAll('.btn-brown-outline');
      for(var i=0 ; i<component.length; i++){
        for(var j=0; j <component[i].classList.length; j++){
          if(component[i].classList[j] === 'checked-on'){
            component[i].classList.remove('checked-on');
          }
        }
      }
      if(document.querySelectorAll('.count-file').length > 0){
        document.querySelectorAll('.count-file')[0].classList.remove('on');
      }
      /* 오른쪽 마우스 클릭하여 Context Menu호출시 선택하였던 checkbox false 처리 */
      document.querySelectorAll('.checkbox').forEach(function(ul, j) {
        //ul.children[0].checked = false;
      });


      if( this.preference.layout === 'table'){
          document.querySelectorAll('.itemList').forEach(function(ul, j) {
            ul.classList.remove('active');
          });
        } else {
          document.querySelectorAll('.item').forEach(function(ul, j) {
            ul.classList.remove('active');
          });
        }

      this.load(this.page.page);
    },

    share(){
      for(var i=0; i<this.selected.length; i++){
        this.selectionBox.push(this.selected[i]);
      }
      this.$refs.fileHeader.share();
    },


    getByteLength(filename){

      if( filename == null || filename.length == 0 ) {
        return 0;
      }

      let size = 0;

      for( let num = 0; num < filename.length; num++ ) {
        size += this.charByteSize( filename.charAt( num ) );
      }
      
      return size;
    },

    cutByteLength( string, length ) {

      if( string == null || string.length == 0 ) {
          return 0;
      }

      let size = 0;
      let rIndex = string.length;

      const _size = this.getByteLength(string);

      for( let num = 0; num < string.length; num++ ) {

          size += this.charByteSize( string.charAt( num ) );

          if( size == length ) {
              rIndex = num + 1;
              break;
          } else if( size > length ) {
              rIndex = num;
              break;
          }
      }

      if(_size > length) return string.substring( 0, rIndex )+ "...";
      else return string;
    },

    charByteSize(ch) {

      if( ch == null || ch.length == 0 ) {
          return 0;
      }

      let charCode = ch.charCodeAt( 0 );

      if( charCode <= 0x00007F ) {
          return 1;
      } else if( charCode <= 0x0007FF ) {
          return 2;
      } else if( charCode <= 0x00FFFF ) {
          return 3;
      } else {
          return 4;
      }
    },

    updateCheckAll() {
      if (this.$refs.checkAll) {
        const chk = this.$refs.checkAll[0];
        // const contentCount = this.disableFolderSelection ? this.content.filter(item => !item.isFolder).length : this.content.length;
        chk.checked = this.selected.length > 0;//&& this.selected.length === contentCount;
        chk.indeterminate = !chk.checked && this.selected.length;
      }
    },

    contextType(filename){

      const check_num = /[0-9]/;      // 숫자 
      const check_eng = /[a-zA-Z]/;   // 영문
      const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글 
      let _type = 0;
      let _length = 0;
      filename = filename.slice(0,filename.lastIndexOf('.d') == '-1' ? filename.length : filename.lastIndexOf('.d') );

      if( check_num.test(filename.split(".")[0]) ){
          _type += 1; 
      } if( check_eng.test(filename.split(".")[0]) ){      
          _type += 2;
      } if( check_kor.test(filename.split(".")[0]) ){
          _type += 4;
      }       
     
      switch( _type ) {
        case 1:
          if(usePreferenceStore().layout == "table") _length = 20;
          else _length = 8;
          break;
        case 2:
          if(usePreferenceStore().layout == "table") _length = 20;
          else _length = 8;
          break;
        case 3:
          if(usePreferenceStore().layout == "table") _length = 20;
          else _length = 8;
          break;
        case 4:
          if(usePreferenceStore().layout == "table") _length = 40;
          else _length = 8;
          break;
        case 5:
          if(usePreferenceStore().layout == "table") _length = 26;
          else _length = 8;
          break;
        case 6:
          if(usePreferenceStore().layout == "table") _length = 38;
          else _length = 8;
          break;
        case 7:
          if(usePreferenceStore().layout == "table") _length = 32;
          else _length = 8;
          break;
        default:
          if(usePreferenceStore().layout == "table") _length = 30;
          else _length = 8;
          break;
      }

      return this.cutByteLength(filename, _length );
    },

  },

 

}
</script>
<style>

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size:  1em; /* opinion 3 */
  line-height: 2;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 99999; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 15em;
  max-width: 15em;
  white-space: normal;
  overflow: visible;  
  padding: 1ch 1.5ch;
  border-radius: .5ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 99999; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  word-break: break-all;
  transform: translate(-50%, -.5em); 
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

/* UNRELATED to tooltips */
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  background: #ededed;
}
main {
  flex: 1 1 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
aside {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #49b293;
  color: #fff;
  padding: 1em;
}
main div {
  text-align: center;
  color: #353539;
}
main span {
  padding: .5em 1em;
  margin: .5em;
  display: inline-block;
  background: #dedede;
}

aside a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: .4em 1em;
}
</style>