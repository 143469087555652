

      jQuery.ajax({
          type : 'get',           // 타입 (get, post, put 등등)
          url : 'https://lehua.gidata.co.kr/api/public/adminSettings',           // 요청할 서버url
          async : true,            // 비동기화 여부 (default : true)
          dataType :"json",
          success : function(result) { // 결과 성공 콜백함수  

            let theme = document.querySelector(':root');
            theme.style.setProperty('--top-background-color',result[0].colorTop);
            theme.style.setProperty('--search-box-background-color',result[0].colorTop);
            theme.style.setProperty('--aside--background-color',result[0].colorLeft);
            theme.style.setProperty('--title-font',result[0].fontTitle);
            theme.style.setProperty('--content-font',result[0].fontContent);
            theme.style.setProperty('--content--background-color',result[0].colorBottom);
            theme.style.setProperty('--title-font-size',(result[0].fontTitleSize * 0.063)+'rem');
            theme.style.setProperty('--content-font-size',result[0].fontContentSize);
            
          },
          error : function(request, status, error) { // 결과 에러 콜백함수
              
            let theme = document.querySelector(':root');
            theme.style.setProperty('--top-background-color','#FEBE10');
            theme.style.setProperty('--search-box-background-color','#FFDD80');
            theme.style.setProperty('--aside--background-color','#ffffff');
            theme.style.setProperty('--title-font','KB-title');
            theme.style.setProperty('--content-font','KB-content');
            theme.style.setProperty('--content--background-color','#f1f1f1');
            theme.style.setProperty('--title-font-size','1.863rem');
            theme.style.setProperty('--content-font-size','0.9375rem');
            
          }
      });
      //alert(navigator.userAgent);
      //alert(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  