import { Modal } from 'bootstrap';
import { createPinia } from 'pinia';
import Swal from 'sweetalert2';
import { createApp } from 'vue';

import api from './api';
import App from './App.vue';
import officeHandler from './integration/onlyoffice';
import router from './router';
import { L, confirm2 } from './utils';
import vueCookies from "vue-cookies";
import { useSessionStore } from './stores/session';

import { IS_MOBILE } from './const';

import './assets/css/common.css';
import './assets/css/style.scss';
import './assets/css/fonts.css';
import './assets/plugin/jquery-ui-1.13.2/jquery-ui.css';
import './assets/plugin/tree-menu/skin-win8/ui.fancytree.css';
import './assets/plugin/tree-menu/lib/contextmenu-abs/jquery.contextMenu.css';
import './assets/plugin/tree-menu/skin-win8/ui.fancytree.css';


const app = createApp(App)
app.use(vueCookies);
app.$cookies.config("7d");
app.use(createPinia());
app.use(router);

app.config.globalProperties.documentHandlers = [ officeHandler ];
app.config.globalProperties.setPageTitle = pageName => {
  document.title = (pageName ? pageName + ' - ' : '') + L('Lehua Drive');
}
app.config.globalProperties.L = L;
app.config.globalProperties.console = console;

app.config.globalProperties.viewFile = async (folder, file) => {
  if (file.isFolder) {
    router.push(`?path=${encodeURIComponent((folder || '') + '/' + file.filename)}&parentId=${file.id}&userId=${file.userId}`);
    return;
  }

  // if (window.flutter_inappwebview) {
  //   window.flutter_inappwebview.callHandler('viewFile', file);
  //   return;
  // }
 
  const ext = file.filename.slice(file.filename.lastIndexOf('.')).toLowerCase();
  for (let h of app.config.globalProperties.documentHandlers) {
    if (await h.supportsView(ext, file.filename)) {
      const url = `/edit?path=${encodeURIComponent(file.path)}&view=true`;

      if (IS_MOBILE){
        if(navigator.userAgent.toUpperCase().indexOf('ANDROID') > 0 ){
          router.push(url);
        }else{
          location.href = url + '&auth=true';
        }
        
      } else {
        window.open(url + '&auth=true', 'open-' + file.id, 'popup=true');
      }
      return;
    }
  }

  if (file.contentType.startsWith('image/') || file.contentType === 'application/pdf') {
    const url = await api.getDownloadUrl(file.path, [file.filename], true);

    if (IS_MOBILE)
      location.href = url;
    else
      window.open(url, '_blank', 'width=1000,height=800,scrollbars=yes,status=yes');
  } else {
    const ok = await confirm2(L('Download file?'), L('Open file'), 'question');
    if (ok)
      api.download([file.path])
  }
}

app.config.globalProperties.editFile = async (folder, file) => {
  if (file.isFolder) {
    return;
  }
  
  const ext = file.filename.slice(file.filename.lastIndexOf('.')).toLowerCase();
  for (let h of app.config.globalProperties.documentHandlers) {
    if (await h.supportsEdit(ext, file.filename)) {
      const url = `/edit?path=${encodeURIComponent(file.path)}`;
      if (IS_MOBILE)
        router.push(url);
      else {
        window.open(url + '&auth=true', 'edit-' + file.id, 'popup=true');
      }
      return;
    }
  }
}

if (window.flutter_inappwebview) {

  window.addEventListener('flutterInAppWebViewPlatformReady', () => app.mount('#app'));
} else {

  app.mount('#app');
}

// 모바일앱의 뒤로 가기 처리
window._onBackPressed = async function () {
  const BACK = 'BACK';

  const menuOpen = document.querySelector('.dropdown-menu.show');
  if (menuOpen) {
    document.dispatchEvent(new Event('mousedown'));
    document.dispatchEvent(new Event('click'));
    return BACK;
  }
  
  if (Swal.isVisible()) {
    Swal.close();
    return BACK;
  }

  const modal = document.querySelector('.modal.show');
  if (modal) {
    Modal.getOrCreateInstance(modal).hide();
    return BACK;
  }

  const fileSelection = document.querySelector('.file-selection-info');
  if (fileSelection) {
    fileSelection.querySelector('.btn-close').click();
    return BACK;
  }

  if (router.currentRoute.value.path === "/") {
    return 'EXIT';
  }
  
  router.back();

  return 'BACK';
}