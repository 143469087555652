<template>

    <template v-if="file.sharedOut == true">
        <img src="@/assets/img/ico-sharing.png" alt="sharing"  id="sharing"/>
    </template>
 
</template>

<script>
export default {
  CLASS_NAME: 'share-info-column',
  props: ["file"],
};
</script>