<template>
    <Modal ref="baseModal">
        <div class="organization-chart-popup">
            <div class="organization-chart-wrap">
                <h2 class="organization-chart-title">{{L('Sharing')}}</h2>          
                    <div class="tab-wrap">
                        <div class="flex-box organization-chart-content-box">
                            <div class="tabs-wrap">
                                <TreeTab @command="handleClick" ref="treeTab"/>
                                <div class="tabs-content" id="etc">                          
                                <div class="organization-chart">
                                    <ul>
                                        <li v-for="(item, index) in content" :key="item.organizeId"  class="dropdown" @click="treeOpen($event, item)">
                                            <div class="checkbox" style="padding-left: 3.5rem;">                                          
                                                <input type="checkbox" class="_chk" :id="item.organizeId" @click="checkClick($event,item)">
                                                <label class="_chkLabel" :for="item.organizeId"></label>
                                            </div>
                                            <span style="padding-left: 1rem;" @click="organizeClick($event, item)">{{ item.name}}</span>
                                            <ul>
                                                <li v-for="(item2, index) in item.nodes" :key="item2.organizeId"  class="dropdown">                                            
                                                    <div class="checkbox" style="padding-left: 5.5rem;">
                                                        <input type="checkbox" class="_chk" :id="item2.organizeId"  @click="checkClick($event,item2)">
                                                        <label class="_chkLabel" :for="item2.organizeId"></label>
                                                    </div>
                                                    <span style="padding-left: 1rem;" @click="organizeClick($event, item2)">{{ item2.name}}</span>
                                                    <ul>
                                                        <li v-for="(item3, index) in item2.nodes" :key="item3.organizeId">                                                      
                                                            <div class="checkbox" style="padding-left: 7.5rem;">
                                                                <template v-if="item3.organizeId != userId">
                                                                    <template v-if="userAuthChoice.some(v => v.parentId ===  item3.parentId && v.organizeId ===  item3.organizeId) == true">
                                                                        <input type="checkbox" class="_chk" :id="item3.organizeId" disabled>
                                                                        <label class="_chkLabel" :for="item3.organizeId" style="background-color: rgb(51, 46, 46);"></label>
                                                                    </template> 
                                                                    <template v-else>
                                                                        <input type="checkbox" class="_chk" :id="item3.organizeId"  @click="checkClick($event,item3)">
                                                                        <label class="_chkLabel" :for="item3.organizeId"></label>
                                                                    </template>
                                                                </template>                                                        
                                                            </div>
                                                            <template v-if="item3.organizeId != userId">
                                                                <span style="padding-left: 2rem;" @click="organizeClick($event, item3)">
                                                                    {{ item3.name + '(' + item3.organizeId + ')' }}
                                                                    <template v-if="item3.favoriteYn">
                                                                        <img src="@/assets/img/ico-favorites-active.png" style="padding-left: .5rem;">
                                                                    </template>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="padding-left: 3.3rem;">
                                                                    {{ item3.name + '(' + item3.organizeId + ')' }}
                                                                </span>
                                                            </template>                                                      
                                                            <button v-if="item3.favoriteYn  && item3.organizeId != userId"  class="btn" style="margin-right: 2rem;background-color: #CBCCCE;line-height: 2rem;" @click="delFavorites(item3)">{{L('친구해제')}}</button>
                                                            <button v-else-if="!item3.favoriteYn && item3.organizeId != userId" class="btn" style="margin-right: 2rem;background-color: var(--top-background-color);line-height: 2rem;" @click="addFavorites(item3)">{{L('친구등록')}}</button>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tabs-content" id="etc2">
                                <div class="organization-chart">
                                    <ul> 
                                        <div v-if="selectionType ==='Search'" class="flex-box popup-search">
                                            <div class="inner-field">
                                                <input type="text" class="field" id="search"  @keyup.enter="search"/>
                                                <button class="btn btn-orange btn-midium2" @click="search">{{L('Search')}}</button>
                                            </div>
                                        </div>
                                        <template v-if="selectionType ==='Search'">
                                            <template v-if="searchContent.length ==0">
                                                <li style="display: inline-block;">
                                                    <span>{{'검색된 사용자가 없습니다.'}}</span>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li v-for="(item, index) in searchContent" :key="item.organizeId">
                                                    <div class="checkbox" style="padding-left: .5rem;">
                                                        <template v-if="item.organizeId != userId">
                                                            <template v-if="userAuthChoice.some(v => v.parentId ===  item.parentId && v.organizeId ===  item.organizeId) == true">
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" disabled>
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"  style="background-color: rgb(51, 46, 46);"></label>
                                                            </template> 
                                                            <template v-else>
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" @click="checkClick($event,item)">
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"></label>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <template v-if="item.organizeId != userId">
                                                        <span style="padding-left: 1rem;" @click="organizeClick($event, item)">{{item.organizeName + ' < ' + item.name}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="padding-left: 2.4rem;">{{item.organizeName + ' < ' + item.name}}</span>
                                                    </template>                                                
                                                    <button v-if="selectionType ==='Favorites'" @click="delFavorites(item)" class="btn" style="margin-right: 2rem;background-color: var(--top-background-color);line-height: 2rem;" > {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='Y' && item.organizeId != userId" @click="delFavorites(item)" class="btn" style="margin-right: 2rem;background-color: #CBCCCE;line-height: 2rem;"> {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='N' && item.organizeId != userId" @click="addFavorites(item)" class="btn" style="margin-right: 2rem;background-color: var(--top-background-color);line-height: 2rem;">  {{L('친구등록')}}</button>
                                                </li>
                                            </template>
                                        </template>
                                        <template v-else>                                        
                                            <template v-if="content.length ==0 ">
                                                <li style="display: inline-block;">
                                                    <span>{{'등록된 친구목록이 없습니다.'}}</span>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li v-for="(item, index) in content" :key="item.organizeId">
                                                    <div class="checkbox" style="padding-left: .5rem;">
                                                        <template v-if="item.organizeId != userId">
                                                            <template v-if="userAuthChoice.some(v => v.parentId ===  item.parentId && v.organizeId ===  item.organizeId) == true">
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" disabled>
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"  style="background-color: rgb(51, 46, 46);"></label>
                                                            </template> 
                                                            <template v-else>
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" @click="checkClick($event,item)">
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"></label>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <template v-if="item.organizeId != userId">
                                                        <span style="padding-left: 1rem;" @click="organizeClick($event, item)">{{item.organizeName + ' < ' + item.name}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="padding-left: 2.4rem;">{{item.organizeName + ' < ' + item.name}}</span>
                                                    </template>                                                
                                                    <button v-if="selectionType ==='Favorites'" @click="delFavorites(item)" class="btn" style="margin-right: 2rem;background-color: var(--top-background-color);line-height: 2rem;" > {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='Y' && item.organizeId != userId" @click="delFavorites(item)" class="btn" style="margin-right: 2rem;background-color: #CBCCCE;line-height: 2rem;"> {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='N' && item.organizeId != userId" @click="addFavorites(item)" class="btn" style="margin-right: 2rem;background-color: var(--top-background-color);line-height: 2rem;">  {{L('친구등록')}}</button>
                                                </li>
                                            </template>
                                        </template>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="permission">
                            <template  v-for="(auth, index) in authOptionList" :key="auth.authValue">
                                <div class="permission-block">
                                    <div class="permission-btn-wrap">
                                        <button type="button" class="permission-btn add" @click="add(auth.authValue)">{{L('Save')}}</button>
                                        <button type="button" class="permission-btn remove" @click="del(auth.authValue)">{{L('Delete')}}</button>
                                    </div>
                                    <div class="permission-set">
                                        <div class="permission-type">
                                            <span>{{auth.authName}}</span>
                                        </div>
                                        <div class="permission-set-content">
                                            <div class="contents">
                                            <ul>
                                                <template  v-for="(item, index) in userAuthChoice" :key="item.organizeId">
                                                    <li v-if="item.auth == auth.authValue" @click="handleSelect($event, item)">
                                                        {{item.name + '(' +item.organizeId+ ')'  }}
                                                    </li>
                                                </template>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="confirm-buttons">
                    <button class="btn btn-gray btn-round btn-large" @click="close">{{L('Cancel')}}</button>
                    <button class="btn btn-orange btn-round btn-large" @click="confirm">{{L('Ok')}}</button>
                </div>
                <a href="#" class="btn-close">
                    <i class="icon icon-close"  @click="close"></i>
                </a>
            </div>
        </div>
    </Modal>
</template>
  
<script>
import api from '../../../api';
import { ref } from "vue";
  
import Modal from "@/components/files/utils/Modal.vue";
import { alert2, showToast  } from '../../../utils';
import TreeTab from './TreeTab.vue';
import { useSessionStore } from '../../../stores/session';

export default {
    //   name: "ConfirmationModal",
    setup() {
        const session = useSessionStore();
        const userId = session.login == null ? '' : session.login.userId.toLowerCase();
        return { session, userId };
    },

    emits: ['share'], 
    components: {
      Modal, TreeTab
    },
    props: {
      item: {
        type: Array,
        required: true,
      },
    },
  
    data: function () {
        return {
            content: [],
            selected: [],
            allSelected: [],
            examineSelected: [],
            readSelected: [],        
            choice: [],
            shareSelect:[],
            selectionType : null,
            items:[],
            authOptionList: [],
            userAuthChoice:[],
            searchContent: [],
        };
    },

    methods :{
        async load(organizeId) { 
            //   this.allSelected = [];
            //   this.examineSelected = [];
            //   this.readSelected = [];
            //   this.selected = [];
            //   this.shareSelect=[];
          
            this.selectionType = organizeId;
            document.querySelectorAll('.tabs-content').forEach(function(ul, j) {
                ul.classList.remove('active');
            });
  
            if(organizeId === 'Favorites'){
                const result = await api.organizeFavorites();
                this.content = result;
                document.getElementById('etc2').classList.toggle('active');
            } else if(organizeId === 'Search'){
                //this.content = [];
                document.getElementById('etc2').classList.toggle('active');
            }else{
                const result = await api.organize(organizeId);
                this.content = result[0].nodes;
                document.getElementById('etc').classList.toggle('active');
            }
          
            /** 공유권한 리스트 조회 */
            this.authOptionList = await api.getAuthOption();
            this.authOptionList.sort((a, b) => b.authValue.localeCompare(a.authValue));
        },
      
        async search(){
  
            const searchText = document.getElementById('search').value;
    
            if(searchText == "") { this.searchContent =[];  return; }
            const result = await api.organizeSearch( searchText);
            this.searchContent = result;
        },
  
        /** tree icon 클릭 */
        treeOpen(e, item){        
            if(e.target.nodeName.toLowerCase() ==="li"){
                e.target.classList.toggle('active');
                e.preventDefault();
            }
        },
  
        /** checkbox 클릭 */
        checkClick(e, item){
            this.clickItem(e, item);
        },
    
        /** name 클릭 */
        organizeClick(e, item){
            this.clickItem(e, item);        
        },
    
        clickItem(e, item) { 

            let _selected = [];
            _selected = this.selected;
            let _parentNode = e.target.parentNode;
    
            if(item.depth == 3){
                _parentNode.childNodes.forEach(function(_terget, i) {

                    if(_terget.nodeName.toLowerCase() == "div"){
                        _terget.childNodes.forEach(function(_divTerget, i) {
                            if(_divTerget.nodeName.toLowerCase() == "input"){
                                if(!_divTerget.disabled){
                                    if(!_divTerget.checked){
                                        _selected.push(item)
                                        _divTerget.checked = true;
                                    }else{
                                        let _idx =_selected.findIndex(v => v.id ===  item.id);
                                        _selected.splice(_idx, 1);
                                        _divTerget.checked = false;
                                    }
                                }
                            }                        
                        });
                    }else if(_terget.nodeName.toLowerCase() == "input"){  
                        if(_terget.checked){
                            _selected.push(item)
                            _terget.checked = true;
                        }else{
                            let _idx =_selected.findIndex(v => v.id ===  item.id);
                            _selected.splice(_idx, 1);
                            _terget.checked = false;
                        }             
                    }
                }); 
            }else if(item.depth == 2){
                let _checked = _parentNode.children[0].checked;
                if(_parentNode.nodeName.toLowerCase() == 'div'){
                    _parentNode.parentNode.childNodes.forEach(function(_terget, i) {
                        if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, i) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_liTerget, j) { 
                                        if(_liTerget.nodeName.toLowerCase() == "div"){
                                            _liTerget.childNodes.forEach(function(_divTerget2, k) {                                   
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){
                                                            _selected.push(item.nodes[i - 1]);                                                    
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[i - 1].id);
                                                            _selected.splice(_idx, 1);
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                }                        
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }else if(_parentNode.nodeName.toLowerCase() == 'li'){
                    let _checked = false;
                    _parentNode.childNodes.forEach(function(_terget, i) {                     
                        if(_terget.nodeName.toLowerCase() == "div"){     
                                        
                            _terget.childNodes.forEach(function(_divTerget, i) {
    
                                if(_divTerget.nodeName.toLowerCase() == "input"){
                                
                                    if(!_divTerget.disabled){
                                        if(!_divTerget.checked){
                                            _divTerget.checked = true;
                                            _checked = true;
                                        }else{
                                            _divTerget.checked = false;
                                            _checked = false;
                                        }
                                    }
                                }
                            });
                        }else if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, i) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_liTerget, j) {                               
                                        if(_liTerget.nodeName.toLowerCase() == "div"){
                                            _liTerget.childNodes.forEach(function(_divTerget2, k) {
                                                
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){
                                                            _selected.push(item.nodes[i - 1]);                                                    
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[i - 1].id);
                                                            _selected.splice(_idx, 1);
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                }                        
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    }); 
                }
    
            }else if(item.depth == 1){
                let _checked = _parentNode.children[0].checked;
                if(_parentNode.nodeName.toLowerCase() == 'div'){
                    _parentNode.parentNode.childNodes.forEach(function(_terget, i) { 
                        if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, j) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_ulTerget, k) {
                                        if(_ulTerget.nodeName.toLowerCase() == "div"){
                                            _ulTerget.childNodes.forEach(function(_divTerget2, l) {
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){             
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                } 
                                            });
                                        }else if(_ulTerget.nodeName.toLowerCase() == "ul"){
                                            _ulTerget.childNodes.forEach(function(_liTerget, m) { 
                                                if(_liTerget.nodeName.toLowerCase() == "li"){
                                                    _liTerget.childNodes.forEach(function(_divTerget2, n) {          
                                                        if(_divTerget2.nodeName.toLowerCase() == "div"){
                                                            _divTerget2.childNodes.forEach(function(_divTerget3, o) {
                                                                if(_divTerget3.nodeName.toLowerCase() == "input"){
                                                                    if(!_divTerget3.disabled){  
                                                                        if(_checked){
                                                                            _selected.push(item.nodes[j - 1].nodes[m-1]);                                                    
                                                                            _divTerget3.checked = true;
                                                                        }else{
                                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[j - 1].nodes[m-1]);
                                                                            _selected.splice(_idx, 1);
                                                                            _divTerget3.checked = false;
                                                                        }
                                                                    }
                                                                }                        
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }else if(_parentNode.nodeName.toLowerCase() == 'li'){
                    let _checked = false;
                    e.target.parentNode.childNodes.forEach(function(_terget, i) { 
                        if(_terget.nodeName.toLowerCase() == "div"){
                            _terget.childNodes.forEach(function(_divTerget, j) {
                                if(_divTerget.nodeName.toLowerCase() == "input"){ 
                                    if(!_divTerget.disabled){
                                        if(!_divTerget.checked){
                                            _divTerget.checked = true;
                                            _checked = true;
                                        }else{
                                            _divTerget.checked = false;
                                            _checked = false;
                                        }
                                    }
                                }
                            });
                        }else if(_terget.nodeName.toLowerCase() == "ul"){                    
                            _terget.childNodes.forEach(function(_divTerget, k) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_ulTerget, l) {  
                                        if(_ulTerget.nodeName.toLowerCase() == "div"){
                                            _ulTerget.childNodes.forEach(function(_liTerget, m) {
                                                if(_liTerget.nodeName.toLowerCase() == "input"){                            
                                                    if(!_liTerget.disabled){
                                                        if(_checked){
                                                            _liTerget.checked = true;
                                                        }else{
                                                            _liTerget.checked = false;
                                                        }
                                                    }
                                                }
                                            });
                                        }else if(_ulTerget.nodeName.toLowerCase() == "ul"){                                   
                                            _ulTerget.childNodes.forEach(function(_liTerget, n) {
                                                if(_liTerget.nodeName.toLowerCase() == "li"){
                                                    _liTerget.childNodes.forEach(function(_divTerget2, o) {          
                                                        if(_divTerget2.nodeName.toLowerCase() == "div"){
                                                            _divTerget2.childNodes.forEach(function(_divTerget3, p) {
                                                                if(_divTerget3.nodeName.toLowerCase() == "input"){
                                                                    if(!_divTerget3.disabled){ 
                                                                        if(_checked){
                                                                            _selected.push(item.nodes[k - 1].nodes[n-1]);                                                    
                                                                            _divTerget3.checked = true;
                                                                        }else{
                                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[k - 1].nodes[n-1]);
                                                                            _selected.splice(_idx, 1);
                                                                            _divTerget3.checked = false;
                                                                        }
                                                                    }
                                                                }                        
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
            
            this.selected = _selected; 
        },
  
        show(){       
            this.items = this.item;
            this.$refs.baseModal.open();
        },
  

        async confirm(){
            let cont ="";
            if(this.userAuthChoice.length == 0){
                alert2(this.L('There is no one to share with.'), 'Sharing');
                this.close();
                return;
            }
             
            let editOption = false;
            for(var i=0; i <this.items.length; i++){
                for(var j=0; j<this.authOptionList.length; j++){
                    
                    if(this.authOptionList[j].optionId.indexOf('Edit') > 0){
                        editOption = true;
                    }else{
                        editOption = false;
                    }
                    
                    for(var k=0; k<this.userAuthChoice.length; k++){
                        if(this.authOptionList[j].authValue == this.userAuthChoice[k].auth){
                            cont="{\"userId\":\""+this.items[i].userId+"\", \"path\":\""+this.items[i].path+"\", \"type\":\"USER\", \"shareWith\":\""+this.userAuthChoice[k].organizeId +"\",\"shareName\":\""+this.userAuthChoice[k].name+"\", \"shareAuth\":\""+this.authOptionList[j].authId +"\",\"edit\":"+editOption+",\"create\":\"0\",\"delete\":\"0\",\"expiration\":\"\",\"password\":\"\"}" ; 
                            this.shareSelect.push(cont)
                        }
                    }                
                }
            }

            const result = await api.sharerRgistration(this.shareSelect);
            if (result) {
                showToast(this.L('Share registration has been completed.'), 'success');
            }

            this.selected = [];
            this.authOptionList = [];
            this.userAuthChoice = [];

            this.$emit('share');
            this.$refs.baseModal.close();
        },
    
        close(){ 
            this.selected = [];
            this.userAuthChoice = [];
            this.searchContent = [];
            this.$emit('share');
            this.$refs.baseModal.close();
        },
    
        handleClick(command) {
            this.load(command);
        },
    

        /** 공유권한별 저장버튼 클릭 */
        add(t){
            for(var j=0; j<this.selected.length; j++){      
                const _idx = this.userAuthChoice.findIndex(v => v.organizeId ===  this.selected[j].organizeId);
                if(_idx < 0){
                        this.userAuthChoice.push({'auth':t,'id':this.selected[j].id,'name':this.selected[j].name,'parentId':this.selected[j].parentId, 'organizeId':this.selected[j].organizeId});
                }
            }

            this.choice= [];
            this.selected = [];
    
            if(this.selectionType === 'Search' || this.selectionType === 'Favorites'){
                for(var _idx=0; _idx<this.userAuthChoice.length; _idx++){
                    document.getElementById(this.selectionType+'_'+this.userAuthChoice[_idx].organizeId).checked = false;     
                    document.querySelector('label[for="'+  this.selectionType+'_'+this.userAuthChoice[_idx].organizeId+'"]').style.backgroundColor='#332e2e';    
                }
            }else{
                const _chk = document.querySelectorAll('._chk:checked');
                for(var i=0; i <_chk.length; i++){
                    _chk[i].checked = false;
                    _chk[i].disabled =true;
                    document.querySelector('label[for="'+  _chk[i].id+'"]').style.backgroundColor='#332e2e';
                }
            }
        },
        
        /** 공유권한별 삭제버튼 클릭 */
        del(t){

            for(var i =0; i <this.choice.length; i++){  
                let _idx =this.userAuthChoice.findIndex(v => v.id ===  this.choice[i].id);
                if(this.selectionType === 'Search' || this.selectionType === 'Favorites'){            
                    document.getElementById(this.selectionType +'_'+ this.choice[i].organizeId).disabled = false;
                    document.querySelector('label[for='+ this.selectionType +'_'+ this.choice[i].organizeId +']').style.backgroundColor="";
                }else{
                    document.getElementById(this.choice[i].parentId.substr(0, 3)).disabled = false;        
                    document.getElementById(this.choice[i].parentId).disabled = false;
                    document.getElementById(this.choice[i].organizeId).disabled = false;
                    document.querySelector('label[for='+ this.choice[i].parentId.substr(0, 3) +']').style.backgroundColor="";
                    document.querySelector('label[for='+ this.choice[i].parentId +']').style.backgroundColor="";    
                    document.querySelector('label[for='+ this.choice[i].organizeId +']').style.backgroundColor="";
                }
                this.userAuthChoice.splice(_idx, 1);
            }
            this.choice= [];
        },

        handleSelect(e, item){
            const _idx =this.choice.findIndex(v => v.id === item.id);
            if(_idx < 0){
                e.currentTarget.style.backgroundColor='#777070';
                this.choice.push(item);
            } else{
                e.currentTarget.style.backgroundColor='';
                this.choice.splice(_idx, 1);
            }
        },
    
    
        async delFavorites(item){
            const result = await api.delOrganizeFavorites(item.organizeId, item.parentId);         
            this.load(this.selectionType) ;
            if(this.selectionType == "Search"){
                this.search();
            }
        },
        async addFavorites(item){       
            const result = await api.addOrganizeFavorites(item.organizeId, item.parentId);  
            this.load(this.selectionType);
            if(this.selectionType == "Search"){
                this.search();
            }
        },
    
    }
};
</script>
  