<template>
  <!-- <RouterView v-if="initialized" /> -->
  <!-- <router-view v-if="initialized"></router-view> -->

  <RouterView v-if="initialized" />
  <!-- <RouterView v-else name="mobile" /> -->
  <!-- <div id="page-loader" class="page-loader">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading!!!...</span>
    </div>
  </div> -->

</template>

<script>
import { RouterView } from 'vue-router';

import api, { loadL10n } from './api';
import { Keys } from './const';
import { handleMessage } from './messaging';
import storage from './stores/persistentStorage';
import { usePreferenceStore } from './stores/preference';
import { useSessionStore } from './stores/session';
import { useAccessStore } from './stores/access';

import { isMobile } from 'mobile-device-detect';

export default {
  
  components: { RouterView },
  data() {
    return {
      initialized: false
    };
  },
  watch: {
    
    $route() {
      this.setPageTitle();
    },
  },
  
  // async created() {    

  //   const lang = (await storage.get(Keys.LANG)) || window.navigator.language || 'ko';
  //   const layout = (await storage.get(Keys.LAYOUT)) || 'table';
  //   const detailOpen = (await storage.get(Keys.DETAIL_OPEN === 'true')) || false;

  //   loadL10n(lang).then(() => this.setPageTitle());

  //   const token = await storage.get(Keys.AUTH_TOKEN);
  //   if (token)
  //     await api.restoreLogin(token, handleMessage);

  //   const preference = usePreferenceStore();
  //   preference.setLang(lang);
  //   preference.setLayout(layout);
  //   preference.setDetailOpen(detailOpen);

  //   if (!useSessionStore().login && location.pathname !== "/signup"){
  //     this.$router.push('/login');
  //   }

  //   if (this.$route.query.fid) {
  //     const file = await api.getLinkInfo(this.$route.query.fid);
  //     if (file)
  //       this.$router.replace({ path: '/', query: { path: file.path } });
  //   }
    
  //   this.initialized = true; 

  //   document.body.addEventListener('selectstart', e => e.preventDefault());
  // },
  
  async created() {
    
    const lang = (await storage.get(Keys.LANG)) || window.navigator.language || 'ko';
    const layout = (await storage.get(Keys.LAYOUT)) || 'table';
    const detailOpen = (await storage.get(Keys.DETAIL_OPEN === 'true')) || false;

    loadL10n(lang).then(() => this.setPageTitle());

    const token = await storage.get(Keys.AUTH_TOKEN);
    if (token) {
      await api.restoreLogin(token);
      api.addSseListener('global', handleMessage);
    }

    const preference = usePreferenceStore();
    preference.setLang(lang);
    preference.setLayout(layout);
    preference.setDetailOpen(detailOpen);

    // 사용자가 모바일 기기에서 접근하는지 판별합니다
    const isMobileDevice = isMobile;

    if (!useSessionStore().login && location.pathname !== "/signup")
      var params = location.search.substr(location.search.indexOf("?") + 1);
    
      useAccessStore().setisDevice = false;

      // 웹 라우팅 처리
      // '/login' 또는 다른 웹 전용 경로로 리다이렉트합니다
      // 실제 웹 라우트로 교체하세요

      if (params) {
        var svalEmail = "";
        var svalBtn = "";
        var svalFid = "";
        params = params.split("&");
        for (var i = 0; i < params.length; i++) {
          var temp = params[i].split("=");
          if ([temp[0]] == "email") { 
            svalEmail = temp[1]; 
          }else if ([temp[0]] == "changeButton"){
            svalBtn = temp[1]; 
          }else if ([temp[0]] == "key"){
            svalFid = temp[1]; 
          }
        }
        if (svalEmail && svalBtn){
          this.$router.replace({ path: '/login', query: { email: svalEmail, changeButton: svalBtn } });
        }else if (svalFid){
          this.$router.replace({ path: '/link', query: { key: svalFid } });
        }else{
          this.$router.replace({ path: '/login'});
        }
      }


    if (this.$route.query.fid) {
      const file = await api.getLinkInfo(this.$route.query.fid);
      if (file)
        this.$router.replace({ path: '/', query: { path: file.path } });
    }

    this.initialized = true;

    document.body.addEventListener('selectstart', e => e.preventDefault());
  },
}
</script>