<template>
  <span class="date"> 
    {{ field =='lastAccessed'? formatDate(file[field]) : formatDateTime(file[field]) }}
  </span>
</template>

<script>
import { formatDate, formatDateTime } from '../../../utils';

export default {
  CLASS_NAME: 'date-column',
  props: ['file', 'field'],
  methods: {
    formatDate, formatDateTime,
  },
};
</script>
