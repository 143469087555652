<template>
  
  <div id="dochi_context_menu" class="custom-context-menu" style="display: none;" data-test-id="contextMenu">
    <ul ref="container" @mousedown.stop @contextmenu.prevent @click="menuEvent = null">
      <li v-if="userId === 'capri999'">{{  selected.length }}</li> 
      <li v-if="this.commands.indexOf('view') >= 0 && count =='1' && folderYn == 'Y' && extFlag"><a href="#view" @click.prevent="$emit('command', 'view', selected[0])"><img src="@/assets/img/file-icon/icon-preview.png">&nbsp;&nbsp;{{L('View')}}</a></li>      
      <li v-if="this.commands.indexOf('edit') >= 0 && count =='1' && folderYn == 'Y' && officeFlag"><a href="#edit" @click.prevent="$emit('command', 'edit', selected[0])"><img src="@/assets/img/file-icon/icon-edit.png">&nbsp;&nbsp;{{L('Edit')}}</a></li>      
      <li v-if="this.commands.indexOf('download') >= 0 && downloadYn"><a href="#detail" @click.prevent="$emit('command', 'download', selected)"><img src="@/assets/img/file-icon/icon-download.png">&nbsp;&nbsp;{{L('Download')}}</a></li>
      <li v-if="this.commands.indexOf('share') >= 0"><a href="#detail" @click.prevent="$emit('command', 'share', selected[0])"><img src="@/assets/img/file-icon/icon-share.png">&nbsp;&nbsp;{{L('Sharing')}}</a></li>
      <li v-if="this.commands.indexOf('link') >= 0 && count =='1' && this.contentType =='Y'"><a href="#link" @click.prevent="$emit('command', 'link', selected[0])"><img src="@/assets/img/file-icon/icon-link.png">&nbsp;&nbsp;{{L('Create Link')}}</a></li>      
      <li v-if="this.commands.indexOf('lock') >= 0">
          <div class="switch" @click.prevent="$emit('command', 'lock', selected)">
            <img src="@/assets/img/file-icon/icon-lock.png">&nbsp;&nbsp;<span>{{L('Document Lock')}}</span>
            <input type="checkbox" id="switch"><label for="switch"></label>
          </div>
      </li>
      <li v-if="this.commands.indexOf('star') >= 0 && isMobileDevice" id="starOn"><a href="#star" @click.prevent="$emit('command', 'star', selected)"><img src="@/assets/img/file-icon/icon-star.png">&nbsp;&nbsp;{{L('Add to starred')  + content.length }}</a></li>
      <li v-if="this.commands.indexOf('star') >= 0 && isMobileDevice" id="starOff" style="display:none"><a href="#star" @click.prevent="$emit('command', 'star', selected)"><img src="@/assets/img/file-icon/icon-star.png">&nbsp;&nbsp;{{L('Remove from starred')}}</a></li>
      
      <li v-if="this.commands.indexOf('detail') >= 0 && count =='1' && this.contentType =='Y'"><a href="#detail" @click.prevent="$emit('command', 'detail', selected[0])"><img src="@/assets/img/file-icon/icon-info.png">&nbsp;&nbsp;{{L('View details')}}</a></li>
      
      <div v-if="this.commands.indexOf('copy') >= 0 || this.commands.indexOf('move') >= 0 || this.commands.indexOf('rename') >= 0" style="border-bottom:2px solid; padding:1px;"></div>
     
      <li v-if="this.commands.indexOf('copy') >= 0"><a href="#copy" @click.prevent="$emit('command', 'copy')"><img src="@/assets/img/file-icon/icon-copy.png">&nbsp;&nbsp;{{L('Copy')}}</a></li>
      <li v-if="this.commands.indexOf('move') >= 0"><a href="#move" @click.prevent="$emit('command', 'move')"><img src="@/assets/img/file-icon/icon-move.png">&nbsp;&nbsp;{{L('Move')}}</a></li>
      <li v-if="this.commands.indexOf('rename') >= 0 && count =='1' && this.contentType =='Y'"><a href="#rename" @click.prevent="$emit('command', 'rename')"><img src="@/assets/img/file-icon/icon-rename.png">&nbsp;&nbsp;{{L('Rename')}}</a></li>
      
      <div v-if="this.commands.indexOf('delete') >= 0" style="border-bottom:2px solid; padding:1px;"></div>
     
      <li v-if="this.commands.indexOf('delete') >= 0"><a href="#detail" @click.prevent="deleteConfirm"><img src="@/assets/img/file-icon/icon-recycle-bin.png">&nbsp;&nbsp;{{L('Delete')}}</a></li>
      
      <li v-if="this.commands.indexOf('delete-perm') >= 0"><a href="#move" @click.prevent="$emit('command', 'delete-perm', selected)"><img src="@/assets/img/file-icon/icon-recycle-bin.png">&nbsp;&nbsp;{{L('Delete permanently')}}</a></li>
      <li v-if="this.commands.indexOf('restore') >= 0"><a href="#restore" @click.prevent="$emit('command', 'restore', selected)"><img src="@/assets/img/file-icon/icon-restore.png">&nbsp;&nbsp;{{L('Restore')}}</a></li>
    </ul>
  </div>
</template>

<script>

import api from '../../../api';
import { usePreferenceStore } from '../../../stores/preference';
import { useSessionStore } from '../../../stores/session';
import { parentOf, alert2, confirm2 } from '../../../utils';

import utils from '../../../integration/onlyoffice/utils'; 
import { isMobile } from 'mobile-device-detect';

export default {
  setup() {
    const session = useSessionStore();
    const preference = usePreferenceStore();
    const userId = session.login == null ? '' : session.login.userId.toLowerCase();
    return { session, userId, preference };
  },

  emits: ['command'],
  props:{
    commands: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      count:null,
      contentType:null,
      downloadYn:true,
      folderYn:null,
      extFlag:true,
      officeFlag:true,
      isMobileDevice : isMobile,
      content:[],
    };
  },
  methods: {
    async show(event, item) {
      if(this.userId === 'capri999'){
        alert(this.selected.length + " >> " + item.length);
        this.content = this.selected;
      }

      await this.show2(event, item);

      const _ctxMenuId = document.querySelectorAll('.custom-context-menu');
      const _innerHeight = window.innerHeight;
      const _innerWidth = window.innerWidth;
      let contextHeight = _ctxMenuId[0].clientHeight;
      let _topPosion = '0px';
      let _bottomPosion = '0px';
      let _leftPosion = '0px';
    
        if(_innerHeight > (contextHeight+ event.clientY)){
          _topPosion = (contextHeight+ event.pageY )+'px';
          _bottomPosion = _innerHeight - (contextHeight+ event.clientY)+'px';
        }else{
          _topPosion = event.pageY+'px';
          _bottomPosion = (_innerHeight - event.clientY)+'px';
        }

        if(_innerWidth < (event.pageX + 210)){
          _leftPosion = (_innerWidth - 210 )+'px';
        }else{
          _leftPosion = event.pageX+'px';
        }
     
      _ctxMenuId[0].style.bottom =_bottomPosion;
      _ctxMenuId[0].style.left =_leftPosion;

      if(this.userId === 'capri999'){
        alert(this.selected.length + " >> " + item.length);
        this.content = this.selected;
      }

    },
       
    async show2(event, item) {

      /* 중복된 리스트가 존재할 경우 삭제 */
      // let _item = item.filter((element, index) => {
      //   return (
      //       //1차원 배열에서는 indexOf를 사용했지만 다차원 배열에서는 안먹힘
      //       item.findIndex(
      //         (item) => item.id === element.id
      //       ) === index
      //   );
      // });

      let component = document.querySelectorAll('.btn-brown-outline');
      for(var i=0 ; i<component.length; i++){
        for(var j=0; j <component[i].classList.length; j++){
          if(component[i].classList[j] === 'checked-on'){
            //component[i].classList.remove('checked-on');
          }
        }
      }
      if(document.querySelectorAll('.count-file').length > 0){
        //document.querySelectorAll('.count-file')[0].classList.remove('on');
      }

      //event.preventDefault();
      
      let _starredCount = item.filter((elem) => elem.starred === true).length;
      let _fileLockCount = item.filter((elem) => elem.fileLock === true).length;
      let _fileUnLockCount = item.filter((elem) => elem.fileLock === false).length;

      if(document.getElementById('starOn') != null){
        if(_starredCount == item.length){
          document.getElementById('starOn').style.display="none";
          document.getElementById('starOff').style.display="";
        }else{
          document.getElementById('starOn').style.display="";
          document.getElementById('starOff').style.display="none";
        }
      }

      if(document.querySelectorAll('.switch').length > 0){
        if(_fileLockCount == item.length){
          /* 문서잠금이 되어 있는 경우 */
          document.querySelectorAll('.switch')[0].children[3].style.backgroundColor="#8d8c89";
          document.querySelectorAll('.switch')[0].children[1].innerHTML = this.L('Document UnLock');
        }else{
          /* 문서잠금이 안되어 있는 경우 */
          document.querySelectorAll('.switch')[0].children[3].style.backgroundColor= "#FEBE10";
          document.querySelectorAll('.switch')[0].children[1].innerHTML =this.L('Document Lock');
        }
      }

      this.count = item.length;
      const contentType="";
      let _count = item.filter((elem) => elem.contentType === "FOLDER").length;
      let _count2 = item.filter((elem) => elem.shareAuth  == "Download").length;      
      let _count3 = item.filter((elem) => elem.shareAuth  == "Read").length;

      if(_count > 0 && _count != item.length) this.contentType = "N";
      else this.contentType ="Y";
      
      
      if(_count > 0) this.folderYn = "N";
      else this.folderYn ="Y";

      this.downloadYn = true;
      
      for(let i=0; i< item.length; i++){
        /*공유 받은 파일에 대한 공유권한 설정 다운로드 유무 체크 */
        if(item[i].download === 'N'){
          this.downloadYn = false;
          break;
        }
      }

      this.extFlag = true;
      this.officeFlag = true;
 
     
      for(let i=0; i< item.length; i++){
        /* OnlyOffice 연동 파일 확장자 체크하여 편집 context menu hidden 처리 */
        const FILE_EXTENSIONS = 'wordcellslide';        
        let _fileType = utils.getDocumentType(utils.getExtension(item[0].path)); 
        if(FILE_EXTENSIONS.indexOf(_fileType) < 0){
           this.officeFlag = false;
           break;
        };
      }
 
      for(let i=0; i< item.length; i++){        
        if (!item[i].contentType.startsWith('image/') && item[i].contentType !== 'application/pdf' && !this.officeFlag) {
          this.extFlag = false;
          break;
        }
      }

      const _ctxMenuId = document.querySelectorAll('.custom-context-menu');
      _ctxMenuId[0].style.display = "";   
    },

    hide() { 

      /* 선택후 우클릭 닫을 경우 선택했던 리스트 unchecked 
         document.querySelectorAll('.itemList')[2].children[0].children[0].children[0].checked
         document.querySelectorAll('.item')[1]    .children[0].children[1].children[0].checked
      */
      if(document.querySelectorAll('.custom-context-menu')[0].style.display ==''){
        if(this.preference.layout === 'table'){
          document.querySelectorAll('.itemList').forEach(function(ul, j) {
         //   ul.children[0].children[0].children[0].checked = false;
          });
        }else{
          document.querySelectorAll('.item').forEach(function(ul, j) {
          //  ul.children[0].children[1].children[0].checked = false;
          });
        }
      }
      const _ctxMenuId = document.querySelectorAll('.custom-context-menu');
      _ctxMenuId[0].style.display = "none";
    
    },

    async deleteConfirm(){ // 삭제 팝업
      this.$emit('command', 'delete');
    },
  },
}
</script>