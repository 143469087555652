<template>
  <div class="tabcontent" id="share">		
    <template v-if="shares?.length > 0">
				<ul class="activity-contents">
					<li v-for="item in shares" :key="item.id">
            <div class="profile">
              <div class="photo">
                <img v-if="item.profileImg === null||item.profileImg === ''||item.profileImg === undefined" style="width:10rem;" src="@/assets/img/default-profile.svg">
                <img v-else style="width:5rem;" :src= item.profileImg>
              </div>
              <!-- <span class="sharer"></span> -->
            </div>
            <div class="text">
              <h4 class="activity-details">
                <template v-if="item.shareName === null||item.shareName === ''">{{ L('{0} shareLink you', item.shareName) }}</template>
                <template v-else>{{ L('{0} shared', item.shareName) }}</template>
              </h4>
              <span class="reg-date">{{ formatDateTime(item.createdAt) }}</span>
            </div>
          </li>
				</ul>
      </template>
      <template v-else>
        <ul class="activity-contents">
					<li>
						공유정보가 없습니다.
					</li>          
				</ul>
      </template>
	</div> 
</template>

<script>
import { Tooltip } from 'bootstrap';
import debounce from 'lodash/debounce';

import api from '../../api';
import { useSessionStore } from '../../stores/session';
import { formatDate, formatDateTime, L, showToast } from '../../utils';
import { isMobile } from 'mobile-device-detect';

export default {
  name: "SharingPanel",
  components: {  },
  props: {

    item: {
        type: Object,
        required: true
    }
  },
  setup() {
    const session = useSessionStore();
    return { session };
  },
  data() {
    return {
      searchTerm: '',
      isSearched: false,
      sharees: [],
      shares: [],
    };
  },

  mounted() { 
    //if(isMobile){   
      if(this.item.id != undefined)   
         this.sharingInfo(this.item)
    //}
  },
  
  watch: {
    item() {  
      this.sharingInfo(this.item);
    }
  },
  methods: {
    formatDate,
    formatDateTime,
    init() {
      this.searchTerm = '';
      this.isSearched = false;
      this.sharees = [];
      this.shares = [];
    },
    async sharingInfo(item) {
          
      let _userId = item.userId;
      if(item.sharedBy != undefined){
        _userId = item.sharedBy;
      }
      
      this.shares = await api.listShares(_userId, item.path);

      this.$nextTick(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
      });
    },

    
  },
}
</script>