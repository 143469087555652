import { Keys } from './const';
import { useL10nStore } from './stores/l10n';
import storage from './stores/persistentStorage';
import { usePreferenceStore } from './stores/preference';
import { useSessionStore } from './stores/session';
import { alert2, L, formatYmd, showError } from './utils';
import router from './router';

import { isMobile } from 'mobile-device-detect';

export const isMobileDevice = isMobile;

export const HOST = import.meta.env.MODE === 'development'
  ? location.origin.slice(0, location.origin.lastIndexOf(':')) + ':8080'  : '';

const CLIENT_ID = crypto.randomUUID();

const setAuth = (headers) => {
  const sessionStore = useSessionStore();

  if (sessionStore.authToken) {
    headers['X-ACCESS-TOKEN'] = sessionStore.authToken;
  }
};

const callFetch = async (url, method, body, headers) => {
  
  if (!headers) headers = {};
  
  if (body && !body.append && !headers['Content-Type']) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }
  headers['x-client-id'] = CLIENT_ID;
  
  setAuth(headers);

  let resp;
  try {
    if(sessionStorage.login === 'true'){
      resp = await fetch(url, { method, body, headers });
    }else{
      router.push({ path: '/login' }); // 웹 접근시 login.vue로 이동
      //location.href= location.origin +"/login"       
    }
  } catch (e) {
    showError(e);
    return;
  }

  if(sessionStorage.login === 'true'){
    if (resp.ok) {
      const token = resp.headers.get('X-ACCESS-TOKEN');
      
      if (token)
        useSessionStore().setAuthToken(token);      
      
      const text = await resp.text();
      return text ? JSON.parse(text) : null;
    } else {
      //sessionStorage.login = false;
      showError(resp);
    }
  }
};

const encodeParams = (params) => {
  return params
    ? Object.keys(params)
        .filter((key) => params[key] !== undefined && params[key] !== null)
        .map(key => {
          const values = Array.isArray(params[key]) ? params[key] : [params[key]];

          return values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
        })
        .join('&')
    : '';
}

const buildUrl = (url, params) => {
  let q = encodeParams(params);

  if (q) {
    q = (url.indexOf('?') > 0 ? '&' : '?') + q;
  }

  return `${url}${q}`;
}


const fetchGet = (url, params) => callFetch(buildUrl(url, params), 'GET');

const fetchDelete = (url, params) => callFetch(buildUrl(url, params), 'DELETE');

const fetchPost = (url, data, headers) => {
  const body = data && data.append ? data : encodeParams(data);
  return callFetch(url, 'POST', body, headers);
};

const fetchPostJson = (url, data, headers) => {
  if (!headers) headers = {};
  headers['Content-Type'] = 'application/json;charset=UTF-8';

  return callFetch(url, 'POST', JSON.stringify(data), headers);
};


export const loadL10n = async (lang) => {
  
  const l10n = useL10nStore();
  const preference = usePreferenceStore()

  if (!lang)
    lang = preference.lang;
  else
    preference.setLang(lang);

  const texts = await exports.getL10nTexts(lang);
  l10n.setL10n(texts);
}

const eventListeners = {};

const openEventSource = (userId, otp) => {
  const eventSource = new EventSource(`${HOST}/api/public/events?user=${userId}&otp=${otp}`);
  eventSource.onmessage = (e) => {
    const m = JSON.parse(e.data);
    if (!m.eventType.startsWith('LIVE:') || m.clientId !== CLIENT_ID)
      Object.keys(eventListeners).forEach(key => eventListeners[key](m));
  }
  // 서버쪽 오류가 발생하면 재시도하지 말고 종료하도록
  eventSource.onerror = () => eventSource.close();
}

const addSseListener = (key, listener) => eventListeners[key] = listener;
const removeSseListener = (key) => delete eventListeners[key];

let uploadRequest;

const exports = {

  fetchGet, fetchDelete, fetchPost, fetchPostJson,

  addSseListener, removeSseListener,

  signIn: async (loginName, password) => {
    sessionStorage.setItem("login",true);
    const result = await fetchPost('/api/public/login', { loginName, password });
 
    if (result.status == "") {
      result.user.lastLogin = await storage.get(Keys.LAST_LOGIN);
      storage.set(Keys.LAST_LOGIN, formatYmd(new Date()));

      const session = useSessionStore();
      session.setLogin(result.user);
      session.setRootFolder(result.root);
      
      if (result.preference.lang) {
        loadL10n(result.preference.lang);
      }

      openEventSource(result.user.userId, result.preference.otp);
      return result;
    }else if (result.status == "Owner") {      
      alert2(L('This is an account whose ownership has been transferred to  {0}.', result.user), '', 'info');

      sessionStorage.setItem("login",false);
      return false;
    }else if (result.status == "loginFailed") {
      alert2(L('ID and password do not match'), 'Login', 'warning');
      sessionStorage.setItem("login",false);
      return false;
    }    
  },

  signOut: async () => {
    sessionStorage.setItem("login",false);
    const result = await fetchPost('/api/logout');

    const session = useSessionStore();
    session.setAuthToken(null);
    session.setLogin(null);

    return result;
  },

  getL10nTexts: (lang) => fetchGet(`/api/public/l10nTexts?lang=${lang}`),

  restoreLogin: async (token, skipEventSource) => {
    const session = useSessionStore();
    session.setAuthToken(token);

    const result = await fetchGet('/api/login');
    if (result) {
      session.setLogin(result.user);
      session.setRootFolder(result.root);

      if (result.preference.lang)
        loadL10n(result.preference.lang);

      if (!skipEventSource)
        openEventSource(result.user.userId, result.preference.otp);
    } else {
      session.setAuthToken(null);
    }
  },

  /** 개인 설정을 저장한다 */
  setPreference: (preference) => fetchPostJson('/api/preference', preference),

  /** user_id에 대한 정보를 조회한다. */
  getUserInfo: (userId) => fetchGet('/api/getUserInfo', { userId}),

  /** 사용자 스토리지 정보를 구한다 */
  getRootInfo: () => fetchGet('/api/root'),

  /** 폴더 정보와 자식 항목을 나열한다 */
  getFolderInfo: (path, parentId, sort, size) => fetchGet('/api/folder', { path, parentId, sort: `${sort}`, size }),
  
  /** 자식 항목을 나열한다 */
  listChildren: (path, sort, size, page) => fetchGet('/api/files', { path, sort: `${sort}`, size, page }),
  
  /** 자식 폴더를 나열한다 */
  listFolders: (path) => fetchGet('/api/folders', { path }),

   /** 자식 폴더를 나열한다 */
   listFolders2: (path) => fetchGet('/api/tree/folders', { path }),

  /** (공유한) 파일 링크를 경로 정보로 구한다 */
  getLinkInfo: (fid) => fetchGet('/api/link', { fid }),
  
  /** 파일을 업로드한다 */
  upload: (data, progressListener, headers) => {
    return new Promise((resolve) => {
      const request = uploadRequest = new XMLHttpRequest();
      const handleError = () => {
        uploadRequest = null;
        showError(request);
        resolve();
      };

      request.open('POST', '/api/files/upload');
      request.setRequestHeader('X-ACCESS-TOKEN', useSessionStore().authToken);
      if (headers)
        Object.keys(headers).forEach(key => request.setRequestHeader(key, headers[key]));

      request.upload.onprogress = progressListener;
      request.upload.onerror = handleError;
      request.upload.onabort = handleError;

      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          if (request.status >= 200 && request.status < 400) {
            uploadRequest = null;
            resolve(JSON.parse(request.responseText));
            return;
          }
        
          handleError();
        }
      };
      request.onerror = handleError;
      request.onabort = handleError;

      request.send(data);
    });
  },

  getPartialUpload: () => fetchGet('/api/files/upload'),

  abortUpload: () => uploadRequest?.abort(),
  
  /** 파일 다운로드 URL을 받는다 */
  getDownloadUrl: async (paths, inline, filename) => {
    const result = await fetchPost('/api/files/download', { path: paths });

    return `/api/public/download?key=${result.key}&inline=${inline ? 'true' : 'false'}&filename=${encodeURIComponent(filename || '')}`
  },
  
  /** 파일을 다운로드한다 */
  download: async (paths, inline, filename) => {
    const url = await exports.getDownloadUrl(paths, inline, filename);
    const setting = await exports.getAdminSettings();
    const userInfo = await exports.getUserInfo(useSessionStore().login.userId);

    if(isMobile && setting[0].mobileDownloadYn && userInfo.userGroup > setting[0].mobileDownloadAuth ){
      alert2(L('Can`t download on mobile'), 'Download', 'warning'); 
    }else{
      if (window.flutter_inappwebview?.callHandler){
        if(userInfo.userId =='capri999')  alert("download 1111111111111111");

        let _osCheck = navigator.userAgent;
        if(_osCheck.includes('Mac')) {
            let _a = document.createElement('a');
            _a.target = '_blank';
            _a.href = location.origin + url;
            document.body.appendChild(_a);
            _a.click();
        }else{
          window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
        }

      }else{
        if(userInfo.userId =='capri999') alert("download 22222222222");
        location.href = url;
      }
    }

    
  },
  
  /** 폴더를 만든다 */
  createFolder: (folder) => fetchPost('/api/files/create', { folder }),

  /** 파일을 삭제한다 */
  /* 2023.07.20 */
  delete: (paths) => fetchDelete('/api/files', { path: paths }),  
  //delete: (userIds, paths) => fetchDelete('/api/files', { userId: userIds, path: paths }),

  /** 최근 파일을 나열한다 */
  //listRecent: (size, page) => fetchGet(`/api/files/recent`, { size, page }),
  listRecent: ( sort, size, page) => fetchGet('/api/files/recent', { sort: `${sort}`, size, page }),

  /** 별 붙인 파일을 나열한다 */
  listStarred: (sort, size, page) => fetchGet(`/api/files/starred`, { sort: `${sort}`, size, page }),

  /** 별을 설정한다 */
  /* 2023.07.20 */
  setStarred: (path, starred) => fetchPost(`/api/files/star`, { path, starred }),  
  //setStarred: (userId, path, starred) => fetchPost(`/api/files/star`, {userId, path, starred }),

  /** 문서잠금을 설정한다 */
  setFileLock: (id, lock) => fetchPost(`/api/files/lock`, {id, lock }),

  /** 내가 공유한 파일을 나열한다 */
  //listSharedByMe: (sort) => fetchGet(`/api/files/shared-out`, { sort }),
  listSharedByMe: (sort, size, page) => fetchGet('/api/files/shared-out', { sort: `${sort}`, size, page }),

  /** 공유한 폴더 정보와 자식 항목을 구한다 */
  getSharedByMe: ( path, sort, size) => fetchGet('/api/folder/shared-out', {path, sort, size  }),

  /** 내게 공유된 파일을 나열한다 */
  listSharedWithMe: (path, sort, size, page) => fetchGet(`/api/files/shared-in`, { path, sort, size, page }),

  /** 공유된 폴더 정보와 자식 항목을 구한다 */
  getSharedWithMe: (path, sort, size) => fetchGet('/api/folder/shared-in', { path, sort, size }),


  /** 소유권 이전된 파일을 나열한다 */
  listSharedTransfer: (path, sort, size, page) => fetchGet(`/api/files/sharedTransfer`, { path, sort, size, page }),

  /** 소유권 이전된 폴더 정보와 자식 항목을 구한다 */
  getSharedTransfer: (path, sort, size) => fetchGet('/api/folder/sharedTransfer', { path, sort, size }),

  /** 휴지통 폴더 정보 및 자식항목을 구한다. */
  getRecycleBin: (path, sort, size, page) => fetchGet('/api/folder/recyclebin', { sort: `${sort}`, size, page }),

  /** 휴지통 파일을 나열한다 */
  listRecycleBin: (sort, size) => fetchGet('/api/files/recyclebin', { sort: `${sort}`, size }),
  
  /** 휴지통 파일을 복원한다 */
  /* 2023.07.20 */
  restoreRecycleBin: (filenames, timestamps) => fetchPost(`/api/recyclebin`, { filename: filenames, timestamp: timestamps }),  
  //restoreRecycleBin: (userIds, filenames, timestamps) => fetchPost(`/api/recyclebin`, { userId: userIds, filename: filenames, timestamp: timestamps }),

  /** 휴지통 파일을 지운한다 */
  /* 2023.07.20 */
  deleteRecycleBin: (filenames, timestamps) => fetchDelete(`/api/recyclebin`, { filename: filenames, timestamp: timestamps }),  
  //deleteRecycleBin: (userIds, filenames, timestamps) => fetchDelete(`/api/recyclebin`, { userId: userIds, filename: filenames, timestamp: timestamps }),

  /** 파일 정보를 구한다 */
  getFile: (path) => fetchGet(`/api/file`, { path }),

  /** 파일 활동정보를 구한다 */
  getFileActivities: (objectId) => fetchGet(`/api/activities`, { objectId }),

  /** 파일을 사전 검색한다 */
  presearchFilesByText: (searchtext) => fetchGet('/api/files/presearch', { q: searchtext }),
  
  /** 파일을 검색한다 */
  searchFilesByText: (searchtext) => fetchGet('/api/files/search', { q: searchtext }),

  /** 파일 이름을 변경한다 */
  /* 2023.07.20 */
  rename: (source, target) => fetchPost(`/api/files/rename`, { source, target }),    
  //rename: (userId, source, target) => fetchPost(`/api/files/rename`, { userId, source, target }),

  /** 파일을 이동한다 */
  move: (sources, target, overwrite) => {
    const targets = sources.map(source => target + '/' + source.slice(source.lastIndexOf('/') + 1));
    return fetchPost(`/api/files/rename`, { source: sources, target: targets, overwrite });
  },

  /** 파일을 복사한다 */
  copy: (sources, target, overwrite) => {
    const targets = sources.map(source => target + '/' + source.slice(source.lastIndexOf('/') + 1));
    return fetchPost(`/api/files/copy`, {  source: sources, target: targets, overwrite });
  },

  /** 파일 존재 여부를 구한다 */
  //exist: (paths) => fetchGet('/api/files/exist', { path: `${paths}`  }),
  exist: (paths) => fetchGet('/api/files/exist', { path: paths  }),

  /** 버전 파일을 나열한다 */
  /* 2023.07.20
    listVersions: (path) => fetchGet(`/api/versions`, { path }),
  */ 
  listVersions: (userId, path) => fetchGet(`/api/versions`, {userId, path }),

  /** 버전 파일을 복원한다 */
  restoreVersion: (path, timestamp) => fetchPost(`/api/versions`, { path, timestamp }),

  /** 버전 파일을 삭제한다 */
  deleteVersion: (path, timestamp) => fetchDelete(`/api/versions`, { path, timestamp }),

  /** 버전 파일을 다운로드한다 */
  /* 2023.07.20
    downloadVersion: async (folder, filename, timestamp) => {
      const result = await fetchPost(`/api/files/download?folder=${encodeURIComponent(folder || '')}&filename=${filename}.v${timestamp}`);

      const url = `/api/public/version?key=${result.key}&filename=${encodeURIComponent(filename || '')}`
      if (window.flutter_inappwebview?.callHandler)
        window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
      else
        location.href = url;
    },
  */   
  // downloadVersion: async (userId, path, filename, timestamp) => {
  //   //const result = await fetchPost(`/api/files/download?path=${encodeURIComponent(path)}.v${timestamp}`);
  //   const result = await fetchPost(`/api/files/download?path=${encodeURIComponent(path+'/' || '')}${filename}.v${timestamp}&userId=${userId}`);

  //   const url = `/api/public/version?key=${result.key}&filename=${encodeURIComponent(filename || '')}`
  //   // if (window.flutter_inappwebview?.callHandler)
  //   //   window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
  //   // else
  //     location.href = url;
  // },


  downloadVersion: async (userId, path, filename, timestamp) => {
    //const result = await fetchPost(`/api/files/download?path=${encodeURIComponent(path)}.v${timestamp}`);
    //const result = await fetchPost(`/api/files/download?path=${encodeURIComponent(path+'/' || '')}${filename}.v${timestamp}&userId=${userId}`);
    
    const result = await fetchPost(`/api/files/download?path=${encodeURIComponent(path + '/' + timestamp)}`);
    const url = `/api/public/version?key=${result.key}&filename=${encodeURIComponent(filename || '')}`;
 
    // if (window.flutter_inappwebview?.callHandler)
    //   window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
    // else 
    if (window.flutter_inappwebview?.callHandler){   
      //alert("downloadVersion 11111111111111");
      window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
    }else{
      //alert("downloadVersion 2222222222222");
      location.href = location.origin + url;      
    }
  },
  
  /** 공유 대상자를 검색한다 */
  searchSharees: (searchterm) => fetchGet('/api/shares/sharees', { query: searchterm }),

  /** 공유 정보를 나열한다 */
  /* 2023.07.20
    listShares: (path) => fetchGet(`/api/shares`, { path }),
  */  
  listShares: (userId, path) => fetchGet(`/api/shares`, { userId, path }),

  /** 공유 거절 정보를 나열한다 */
  listDeclinedShares: () => fetchGet(`/api/shares/declined`),
  
  /** 공유 해제/삭제된 정보를 나열한다. */
  listDisarmedShare: (sort, size, page) => fetchGet(`/api/shares/disarmed`, { sort, size, page }),

  listProgressShare: (sort, size, page) => fetchGet(`/api/shares/progress`, { sort, size, page }),

  
  listLinkShare: (sort, size, page) => fetchGet(`/api/shares/link`, { sort, size, page }),

  /** 공유 정보를 저장한다 */
  share: (share) => fetchPost(`/api/shares`, share),
  
  /** 공유를 해제한다, 피공유자가 공유를 비활성화한다(마운트 제거) 
  unshare: (share) => fetchDelete(`/api/shares`, { share }),*/
  unshare: (share) => fetchDelete(`/api/unshare`, { share }),
  

  /** 피공유자가 공유를 활성화한다 */
  shareSelf: (fileId) => fetchPost(`/api/shares/declined`, { fileId }),

  /** 파일에 딸린 댓글을 조회한다  */
  listComments: (objectId) => fetchGet(`/api/comments?objectId=${objectId}`),
  
  /** 댓글을 저장한다 */
  saveComment: (comment) => fetchPost(`/api/comments`, comment),
  
  /** 댓글을 삭제한다 */
  deleteComment: (id) => fetchDelete(`/api/comments?id=${id}`),
  
  /** 파일의 태그를 구한다 */
  getTags: (objectId, objectType) => fetchGet('/api/tags/object', { objectId, objectType }),

  /** 태그를 검색한다 */
  searchTags: (tag) => fetchGet('/api/tags/search', { search: tag }),

  /** 태그를 부여한다 */
  assignTag: (objectId, objectType, tag) => fetchPost('/api/tags/object', { objectId, objectType, tag }),

  /** 태그를 제거한다 */
  unassignTag: (objectId, tag) => fetchDelete('/api/tags/object', { objectId, tag }),

  /** 태그를 삭제한다 */
  deleteTag: (tag) => fetchDelete(`/api/tags`, { tag }),

  /** 파일 설명을 저장한다 */
  saveDescription: (path, description) => fetchPost('/api/files/description', { path, description }),

  /** 번역 캐시를 지운다 */
  clearL10nCache: (lang) => fetchDelete('/api/public/l10nTexts', { lang }),

  /** 시스템 구성 정보 */
  getConfig: () => fetchGet('/api/config'),

  /** 시스템 구성 정보를 저장한다 */
  saveConfig: (key, value) => fetchPost('/api/config', { key, value }),

  getHistory: (path) => fetchGet('/api/office/history', { path }),

  saveDocumentAs: (path, as) => fetchPost('/api/office/save', { path, as }),

  /** 오피스 문서를 저장한다 */
  forceSave: async (key) => {
    await fetchPost('/api/office/forceSave', { key });
  },

  /** 개인 설정 정보를 나열한다 */
  getPrivateInfo: () => fetchGet('/api/getPrivateInfo'),

  /** 개인 설정 정보를 저장한다 */
  setPrivateInfo: (value) => fetchPost('/api/setPrivateInfo', value ),

  getEmailCheck:(email) => fetchGet('/api/getEmailCheck', { email }),

  /** 프로필 사진을 저장한다 */
  saveProFileImg: (profileImg) => fetchPost('/api/saveProFileImg',  { profileImg }  ),

  /** 비밀번호 찾기위해 메일을 보낸다. */
  findPassword: async (email) => {
    sessionStorage.login = 'true';
    const result = await fetchGet('/api/public/findPassword', { email });
    return result;
  },

  /** 비밀번호를 저장한다 */                        
  savePassword: (email, newPw) => fetchPost('/api/public/savePassword',  { email, newPw }  ),
  
  /** 비밀번호를 저장한다
  savePasswordByPw: (currPw, newPw) => {
    const passwordResult = fetchPost('/api/savePasswordByPw',  { currPw, newPw }  );
    return passwordResult;
  },
 */

   /** 비밀번호를 저장한다 */
   saveChangePassword: (pwd) => {
    const passwordResult = fetchPost('/api/saveChangePassword',  { pwd }  );
    return passwordResult;
  },

   /** 현재 등록된 비밀번호 확인 */
  beforePasswordCheck: (pwd) => {
    return fetchPost('/api/beforePasswordCheck',  { pwd }  );
  },

/** 현재 등록된 비밀번호와 입력한 비밀번호 비교 */
  afterPasswordCheck: (pwd) => {
    return fetchPost('/api/afterPasswordCheck',  { pwd }  );
  },
  
  
  /** 비밀번호를 확인한다 */
  checkPassword: (newPw) => fetchPost('/api/checkPassword',  { newPw }  ),
  
  /** 사용자 history정보를 나열한다 */
  getUserHistory: () => fetchGet('/api/getUserHistory' ),

  /** 소유권을 이전한다 */
  fnOwnershipTransfer: (receiveId) => fetchPost('/api/moveOwner',  { receiveId }  ),

  /** 로그인 화면 분기처리 */
  // getChangeBtn: () => fetchGet('/api/public/getChangeBtn'),

  
  /** 관리자 권한 설정 정보를 나열한다 */
  getAuthOption: () => fetchGet('/api/getAuthOption'),

  /** 관리자 권한 정보를 업데이트한다 */
  updateAuthOption: (authId, authName, authValue, optionId) => fetchPost('/api/updateAuthOption', {authId, authName, authValue, optionId} ),

  /** 관리자 권한 정보를 저장한다 */
  setAuthOption: (authId, authName, authValue, optionId) => fetchPost('/api/setAuthOption', { authId, authName, authValue, optionId} ),

  /** 관리자 권한 정보를 삭제한다 */
  delAuthOption: (authId) => fetchPost('/api/delAuthOption', {authId} ),

  /** 관리자 공유권한 정보를 수정 */
  setShareAuthOption: (value) => fetchPost('/api/updateShareAuthOption', value),


  /** 관리자 세팅정보를 가져온다 */
  getAdminSettings: () => fetchGet('/api/getAdminSettings'),

  /** 저장공간 정보를 저장한다 */
  setStorageSize: (storageSize) => fetchPost('/api/setStorageSize', {storageSize} ),


  getUseStorageSize:() => fetchGet('/api/files/props/useSize'),

  /** 2단계 인증 여부를 저장한다 */
  setAuthenticationYN: (authenticationYN) => fetchPost('/api/setAuthenticationYN', {authenticationYN} ),

  /** 서버측 암호화 여부를 저장한다 */
  setEncryptionYN: (encryptionYN) => fetchPost('/api/setEncryptionYN', {encryptionYN} ),

  /** 로그인시 방식을 저장한다 */
  setLoginTypeYN: (loginTypeYN) => fetchPost('/api/setLoginTypeYN', {loginTypeYN} ),

  /** 타이틀 정보를 저장한다 */
  setSiteTitle: (siteTitle) => fetchPost('/api/setSiteTitle', {siteTitle} ),

  /** 타이틀 노출 여부를 저장한다(체크박스) */
  setSiteTitleYN: (siteTitleYN) => fetchPost('/api/setSiteTitleYN', {siteTitleYN} ),

  /** 헤더 노출 여부를 저장한다(체크박스) */
  setHeaderHideYN: (headerHideYN) => fetchPost('/api/setHeaderHideYN', {headerHideYN} ),

  /** 사이트 이미지 정보를 저장한다 */
  setSiteImg: (siteImg) => fetchPost('/api/setSiteImg', {siteImg} ),

  /** 상단(Header) BG-Color 정보를 저장한다 */
  setColorTop: (colorTop) => fetchPost('/api/setColorTop', {colorTop} ),

  /** 하단(Footer) BG-Color 정보를 저장한다 */
  setColorBottom: (colorBottom) => fetchPost('/api/setColorBottom', {colorBottom} ),

  /** 좌측(PageLeft) BG-Color 정보를 저장한다 */
  setColorLeft: (colorLeft) => fetchPost('/api/setColorLeft', {colorLeft} ),

  /** FontTitle 정보를 저장한다 */
  setFontTitle: (fontTitle) => fetchPost('/api/setFontTitle', {fontTitle} ),
  
  /** FontTitleSize 정보를 저장한다 */
  setFontTitleSize: (fontTitleSize) => fetchPost('/api/setFontTitleSize', {fontTitleSize} ),

  /** FontContent 정보를 저장한다 */
  setFontContent: (fontContent) => fetchPost('/api/setFontContent', {fontContent} ),

  /** FontContentSize 정보를 저장한다 */
  setFontContentSize: (fontContentSize) => fetchPost('/api/setFontContentSize', {fontContentSize} ),
  
  /** Font Color 정보를 저장한다 */
  setFontColor: (fontColor) => fetchPost('/api/setFontColor', {fontColor} ),

  /** Sub-Font Color 정보를 저장한다 */
  setFontSubColor: (fontSubColor) => fetchPost('/api/setFontSubColor', {fontSubColor} ),

  /** 강조 Font Color 정보를 저장한다 */
  setFontBoldColor: (fontBoldColor) => fetchPost('/api/setFontBoldColor', {fontBoldColor} ),

  /** Footer Copy 정보를 저장한다 */
  setFooterCopy: (footerCopy) => fetchPost('/api/setFooterCopy', {footerCopy} ),

  /** Footer URL 정보를 저장한다 */
  setFooterUrl: (footerUrl) => fetchPost('/api/setFooterUrl', {footerUrl} ),

  /** 조직도 트리 구조 화면 */
  organize: (organizeId) => fetchPost('/api/organize', {organizeId}),

  organizeFavorites: () => fetchPost('/api/organize/favorite'),

  organizeSearch: (searchTxt) => fetchPost('/api/organize/search', {searchTxt}),
  

  /** 조직도 트리 구조 화면 */
  organizeTab : () =>  fetchGet('/api/organizeTab'),


   /** 공유 정보를 저장한다 
    * const result = await api.sharerRgistration(this.item, this.allSelected, this.examineSelected, this.readSelected);
   */
  sharerRgistration: (share) => fetchPost(`/api/shares/registration`,  { share }),
  
  /* 조직도 즐겨찾기 등록 */
  addOrganizeFavorites: (userId, organizeId) => fetchGet(`/api/shares/addFavorites`,  { organizeId, userId }),  

  /* 조직도 즐겨찾기 해제 */
  delOrganizeFavorites: (userId, organizeId) => fetchGet(`/api/shares/delFavorites`,  { organizeId, userId }),

  /** 공유(링크) 정보를 저장한다 */
  shareLinkReg: (userId, path, expiration, password, shareAuth) => {

    const result = fetchPost(`/api/shares/linkReg`, { userId, path, expiration, password, shareAuth });
    return result;
  },

  /** 링크 비밀번호를 체크한다. */
  chkLinkPassword: async (shareId, passwordShare) => {
    sessionStorage.login = 'true';
    const result = await fetchGet(`/api/public/chkLinkPw`, { shareId, passwordShare });
    
    return result;
  },

  /** 링크파일을 다운로드한다 */
  downloadLink: async (userIds, paths, inline, filename) => {
    const url = await exports.getDownloadUrlLink(userIds, paths, inline, filename);

    // if (window.flutter_inappwebview?.callHandler)
    //   window.flutter_inappwebview?.callHandler('download', location.origin + url, filename);
    // else
      location.href = url;
  },

  /** 링크파일 다운로드 URL을 받는다 */
  getDownloadUrlLink: async (userIds, paths, inline, filename) => {    
    const result = await fetchPost(`/api/public/link/download`, { userId:userIds, path:paths });
    return `/api/public/download?key=${result.key}&inline=${inline ? 'true' : 'false'}&filename=${encodeURIComponent(filename || '')}`
  },

  /** 신규문서를 생성한다. */
  createFile: async (value) => {
    const result = await fetchPost('/api/office/create', value);

    return result;
  },

  /** 스마트오피스를 사용해 문서를 연다. */
  editorConfig: async (value) => {
    const config = await fetchGet('/api/office/editorConfig', value);
    return config;
  },

  /** 스마트오피스를 사용해 문서를 연다. */
  editorConfigVersion: async (value) => {
    const config = await fetchGet('/api/office/editorConfigVersion', value);
    return config;
  },

  /** 스마트오피스를 오픈할때 권한을 가져온다. */
  getDocumentMode: async (fileId) => {
    const result = await fetchPost('/api/files/getDocumentMode', { fileId });
    return result.mode;
  },
  
  /** 이동 삭제 공유 할때 스마트오피스로 열려있는 파일인지 확인한다. */
  checkOfficeLock: async (fileIds) => {
    const result = await fetchPost('/api/files/checkOfficeLock', { fileId:fileIds });
    return result;
  },

  /** 소유권을 이전한다 */
  fnOwnershipTransfer_N: (receiveId) => fetchPost('/api/moveOwner_N',  { receiveId }  ),

   /* 그룹별 통계 */
   listGroupStatistics: (searchType, searchValue) => fetchGet(`/api/groupStatistics`,  { searchType, searchValue }),

   /* 부서별 통계 */
   listDepartmentStatistics: (groupId, searchType, searchValue, page) => fetchGet(`/api/departmentStatistics`,  { groupId, searchType, searchValue, page }),

   /* 사용자별 통계 */
   listUserStatistics: (groupId, departmentId, searchType, searchValue, page) => fetchGet(`/api/userStatistics`,  { groupId, departmentId, searchType, searchValue, page }),

   
   /* 그룹 리스트 */
   listOrganizeGroup: () => fetchGet(`/api/organizeGroup`),

   /* 그룹별 부서 리스트 */
   listOrganizeDepartment: (groupId) => fetchGet(`/api/organizeDepartment`,  { groupId }),


  /** 파일연계옵션을 저장한다 */
  setEditOption: (seq, type, checkYn, auth) => fetchPost('/api/setEditOption', {seq, type, checkYn, auth} ),

  /** 관리자가 비밀번호 초기화 */
  resetPassword: (userId) => fetchPost('/api/resetPassword',  { userId }  ),



  listVersion: ( sort, size, page) => fetchGet('/api/files/version', { sort: `${sort}`, size, page }),


   /** 코드정보 조회 */
   getCode: (type) => fetchGet('/api/getCode',  { type }  ),

};

export default exports;
