import api from '../../api.js';
import utils from './utils';

const name = 'Office document'

function create(folder, type, template, name) {
  return api.fetchPost('/api/office/create', { type, path: folder + '/' + name, template })
}

const creates = [
  { type: 'docx', name: 'Document' },
  { type: 'xlsx', name: 'Spreadsheet' },
  { type: 'pptx', name: 'Presentation' },
];

creates.forEach(item => {
  item.create = (folder, template, name) => create(folder, item.type, template, name);
});

async function supportsEdit(ext) {
  const setting = await api.getAdminSettings();

  return setting[0].onlyOfficeYn && !!utils.getDocumentType(ext) && ext !== '.xlsm';
}

async function supportsView(ext) {
  const setting = await api.getAdminSettings();

  return setting[0].onlyOfficeYn && !!utils.getDocumentType(ext);
}

export default {
  name, creates, supportsEdit, supportsView, EditorView: () => import('./EditorView.vue')
}
