<template>
  <slot />

  <div
    ref="selectionBox"
    class="drag-selection-box"
    :style="selectionBox?.inProgress ? {
      left: Math.min(selectionBox.x0, selectionBox.x1) + 'px',
      top: Math.min(selectionBox.y0, selectionBox.y1) + 'px',
      width: Math.abs(selectionBox.x0 - selectionBox.x1) + 'px',
      height: Math.abs(selectionBox.y0 - selectionBox.y1) + 'px',
      opacity: 1, // 스크롤 컨테이너를 찾으려면 display: none으로 할 수 없다
    } : { width: 0, height: 0, opacity: 0 }"
  ></div>
</template>

<script>
import { IS_MOBILE } from '../../../const';
import PageHeader from '../../PageHeader.vue';

export default {
  emits: ['dragselect'],
  data() {
    return {
      selectionBox: null,
      headerHeight: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
      asideWidth: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
    };
  },
  mounted() {
    if (!IS_MOBILE) {
      document.documentElement.addEventListener('mousedown', this.handleMouseDown);
      document.documentElement.addEventListener('mousemove', this.handleMouseMove);
      document.documentElement.addEventListener('mouseup', this.handleMouseUp);
    }
    
    const headerElement = document.querySelector('.top-header');
    if (headerElement) {
    this.headerHeight = headerElement.offsetHeight;
    }

    const asideElement = document.querySelector('.left-inner-content');
    if (asideElement) {
    this.asideWidth = asideElement.offsetWidth;
    }
  },
  
  unmounted() {
    document.documentElement.removeEventListener('mousedown', this.handleMouseDown);
    document.documentElement.removeEventListener('mousemove', this.handleMouseMove);
    document.documentElement.removeEventListener('mouseup', this.handleMouseUp);
  },
  methods: {
    findScrollContainer(c) {
      let parent = c;
      while (parent) {
        if (parent.scrollHeight > parent.offsetHeight) return parent;
        parent = parent.parentElement;
      }
      return c.parentElement;
    },
    
    handleMouseDown(e) {
      this.scrollContainer = this.findScrollContainer(this.$refs.selectionBox);
      if (!this.scrollContainer.contains(e.target)) return;

      if (e.which !== 1) return; // left button only

        // x0, y0는 처음 클릭한 점, x1, y1은 현재 마우스 위치
        let x0 = e.clientX - this.scrollContainer.offsetLeft + this.scrollContainer.scrollLeft;
        let y0 = e.clientY - this.scrollContainer.offsetTop + this.scrollContainer.scrollTop;

        // Header 와 Aside 의 길이만큼 처음 클릭한 위치(x0, y0) 재설정
        x0 += this.asideWidth;
        y0 -= this.headerHeight;

        this.selectionBox = {
          x0,
          y0,
          x1: x0,
          y1: y0,
        inProgress: false,
      };
    },

    handleMouseMove(e) {
      const box = this.selectionBox;
      if (!box) return;

      let x1 = e.clientX - this.scrollContainer.offsetLeft + this.scrollContainer.scrollLeft + this.asideWidth;
      let y1 = e.clientY - this.scrollContainer.offsetTop + this.scrollContainer.scrollTop - this.headerHeight;


      const minX = this.asideWidth;
      const maxX = document.querySelector('.right-inner-content').offsetWidth + this.asideWidth ;

      // x1 의 범위 설정 min : asideWidth , max : .right-inner-content 의 width + aisdeWidth
      x1 = Math.max(minX, Math.min(x1, maxX));

      box.x1 = Math.max(0, x1);
      box.y1 = Math.max(0, Math.min(y1, this.scrollContainer.scrollHeight));

      if (!box.inProgress) {
        box.inProgress = true;
      }

      if (box.y1 > this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight - 30) {
        this.scrollContainer.scrollTop += 10;
      } else if (box.y1 < this.scrollContainer.scrollTop + 30) {
        this.scrollContainer.scrollTop -= 10;
      }

      const x0 = Math.min(box.x0, box.x1);
      const y0 = Math.min(box.y0, box.y1);
      const updatedX1 = Math.max(box.x0, x1);
      const updatedY1 = Math.max(box.y0, box.y1);

      this.$emit('dragselect', { x0, y0, x1: updatedX1, y1: updatedY1 });
    },

    handleMouseUp(e) {
      if (!e.ctrlKey && !e.shiftKey)
        this.handleMouseMove(e);
      this.selectionBox = null;
    },

  }
}
</script>

<style>

.drag-selection-box {  
  position: absolute; 
  z-index: 100;
  border: 1px dashed #000;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}
</style>
