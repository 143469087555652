import api from '../../api';
export const cropperMain = function () {
        'use strict';
      
        var Cropper = window.Cropper;
        var URL = window.URL || window.webkitURL;
        var container = document.querySelector('.img-container');
        var image = container.getElementsByTagName('img').item(0);
        var actions = document.getElementById('actions');
        //var download = document.getElementById('download');
        // var dataX = document.getElementById('dataX');
        // var dataY = document.getElementById('dataY');
        // var dataHeight = document.getElementById('dataHeight');
        // var dataWidth = document.getElementById('dataWidth');
        // var dataRotate = document.getElementById('dataRotate');
        // var dataScaleX = document.getElementById('dataScaleX');
        // var dataScaleY = document.getElementById('dataScaleY');
        var options = {
          aspectRatio: 1 / 1,
          preview: '.img-preview'
        };
        var cropper = new Cropper(image, options);
        var originalImageURL = image.src;
        var uploadedImageType = 'image/jpeg';
        var uploadedImageName = 'cropped.jpg';
        var uploadedImageURL;
      
      
        // Methods
        actions.querySelector('.docs-buttons').onclick = function (event) {
          var e = event || window.event;
          var target = e.target || e.srcElement;
          var cropped;
          var result;
          var input;
          var data;
      
          if (!cropper) {
            return;
          }
      
          while (target !== this) {
            if (target.getAttribute('data-method')) {
              break;
            }
      
            target = target.parentNode;
          }
      
          if (target.disabled || target.className.indexOf('disabled') > -1) {
            return;
          }
      
          data = {
            method: target.getAttribute('data-method'),
            target: target.getAttribute('data-target'),
            option: target.getAttribute('data-option') || undefined,
            secondOption: target.getAttribute('data-second-option') || undefined
          };
      
          cropped = cropper.cropped;
      
          if (data.method) {

            switch (data.method) {
              case 'getCroppedCanvas':
                try {
                  data.option = JSON.parse(data.option);
                } catch (e) {
                  console.log(e.message);
                }
      
                if (uploadedImageType === 'image/jpeg') {
                  if (!data.option) {
                    data.option = {};
                  }      
                  data.option.fillColor = '#fff';
                }      
                break;
            }
      
            result = cropper[data.method](data.option, data.secondOption);
      
            switch (data.method) {
              case 'getCroppedCanvas':
                if (result) {
                  document.getElementById('profileImg').src =  result.toDataURL(uploadedImageType);
                  if(document.getElementById('profileImg2') !== null){
                    document.getElementById('profileImg2').src = result.toDataURL(uploadedImageType);
                  }
                  document.querySelectorAll('.relativePosition')[0].style.display = "none";

                  const profileImg = result.toDataURL(uploadedImageType);
                  api.saveProFileImg(profileImg);
                }

                break;
            }
      
            if (typeof result === 'object' && result !== cropper && input) {
              try {
                input.value = JSON.stringify(result);
              } catch (e) {
                console.log(e.message);
              }
            }
          }
        };
      
        // Import image
        var inputImage = document.getElementById('inputImage');
      
        if (URL) {
          inputImage.onchange = function () {
            var files = this.files;
            var file;
      
            if (files && files.length) {
              file = files[0];
      
              if (/^image\/\w+/.test(file.type)) {
                uploadedImageType = file.type;
                uploadedImageName = file.name;
      
                if (uploadedImageURL) {
                  URL.revokeObjectURL(uploadedImageURL);
                }
      
                image.src = uploadedImageURL = URL.createObjectURL(file);
      
                if (cropper) {
                  cropper.destroy();
                }
      
                cropper = new Cropper(image, options);
                inputImage.value = null;
              } else {
                window.alert('Please choose an image file.');
              }
            }
          };
        } else {
          inputImage.disabled = true;
          inputImage.parentNode.className += ' disabled';
        }
      };