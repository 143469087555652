<template>
     <div class="tabcontent active" id="activity">
      <template v-if="activities?.length > 0">
				<ul class="activity-contents">
					<li v-for="activity in activities" :key="activity.id">
						<div class="profile">
							<div class="photo"><!--{{ item.affectedUserId }}-->
                <img v-if="activity.profileImg === null||activity.profileImg === ''" style="width:3rem;" src="@/assets/img/default-profile.svg">
                <img v-else style="width:3rem;" :src= activity.profileImg>
							</div>
						</div>
						<div class="text">
							<h4 class="activity-details">{{ getActivityText(activity) }}</h4>
							<span class="reg-date">{{ formatDateTime(activity.createdAt) }}</span>
						</div>
					</li>          
				</ul>
      </template>
      <template v-else>

        <ul class="activity-contents">
					<li>
						활동영역이 없습니다.
					</li>          
				</ul>
      </template>

			</div>
</template>

<script>
import { useSessionStore } from '../../stores/session';
import { formatDate, formatDateTime } from '../../utils';
import api from '../../api';
import { isMobile } from 'mobile-device-detect';

export default {
  name: "ActivitiesPanel",
  props: {
    // currTab: {
    //   type: String,
    //   required: true
    // },
    item:{
      type: Object,
      required: true 
    }
  },
  setup() {
    const session = useSessionStore();
    return { session };
  },
  data() {
    return {
      activities: [],
      // ActivityIcon: {
      //   FILE: {
      //     'FileActivityType.CREATE': 'icon-plus',
      //     'FileActivityType.UPDATE': 'icon-refresh-cw',
      //     'FileActivityType.RENAME': 'icon-edit-3',
      //     'FileActivityType.MOVE': 'icon-corner-down-right',
      //     'FileActivityType.DELETE': 'icon-trash-2',
      //     'ShareActivityType.SHARE': 'icon-user-plus',
      //     'ShareActivityType.UNSHARE': 'icon-user-minus',
      //     'CommentActivityType.CREATE': 'icon-message-square',
      //     'TrashbinActivityType.TRASHBIN': 'icon-trash-2',
      //     'TrashbinActivityType.RESTORE': 'icon-rotate-cw',
      //     'TagActivityType.TAG': 'icon-tag',
      //     'TagActivityType.UNTAG': 'icon-minus'
      //   }
      // },
      Activity: {
        FILE: {
          'FileActivityType.COPY': 'copy',
          'FileActivityType.CREATE': 'created',
          'FileActivityType.UPDATE': 'updated',
          'FileActivityType.RENAME': 'renamed',
          'FileActivityType.MOVE': 'moved',
          'FileActivityType.DELETE': 'deleted',
          'FileActivityType.OWNERMOVE' : 'owner move',
          'ShareActivityType.SHARE': 'shared with',
          'ShareActivityType.SHARELINK': 'shareLink',
          'ShareActivityType.UNSHARE': 'Removed share for',
          'CommentActivityType.CREATE': 'commented',
          'TrashbinActivityType.TRASHBIN': 'deleted',
          'TrashbinActivityType.RESTORE': 'restored',
          'TagActivityType.TAG': 'assigned tag',
          'TagActivityType.UNTAG': 'unassigned tag',
        }
      }
    }
  },
  mounted() { /* 상세보기 진입 시 최초로 노출되는 탭이므로 mounted 사용 */
    //if(isMobile){    
      if(this.item.id != undefined)   
        this.activityInfo(this.item)
    //}
  },
  watch: {
    item() {
      this.activityInfo(this.item);
    }
  },
  methods: {
    formatDate,
    formatDateTime,

    async activityInfo(item) {

      this.activities = [];   
      this.activities = await api.getFileActivities(item.id); 
      this.activities.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    },

    async getUserInfo(userId){
      const _profileImg = await api.getUserInfo(userId);
      return _profileImg;
    },

    getActivityText(object) {
      const activityType = object.activity.substring(0, object.activity.indexOf('ActivityType'));
      const subject = object.username;
      //const subject = (this.session.login.userId == object.userId) ? 'You' : object.username;
      const action = this.Activity[object.objectType]?.[object.activity];

      let text;
      switch(activityType) {
        case 'File':
          if(action == "copy"){
            text = this.L(`{0} ${action} {1}`, object.data.path, this.item.filename);
          }else{ 
            if(object.data.targetPath == undefined){
              text = this.L(`{0} ${action} {1}`, subject, this.item.filename);
            }else{
              text = this.L(`{0} ${action} {1}`, subject, object.data.targetPath.split('/').pop());
            }
            
          }
          break;
        case 'Trashbin':

          if(subject == 'You') 
            text = this.L(`${subject} ${action} {0}`, this.item.filename);
          else
            text = this.L(`{0} ${action} {1}`, subject, this.item.filename);
          break;
        case 'Share':
          if(this.session.login.userId == object.affectedUserId){
            text = this.L(`{0} ${action} you`, object.username);
          }else if (subject === 'You'){
            text = this.L(`You ${action} {0}`, object.affectedUsername);
          }else{
            text = this.L(`{0} ${action} {1}`, object.username, object.affectedUsername);
          }
          break;
          
        case 'Comment':
        case 'Tag':
          if(subject == 'You') 
            text = this.L(`${subject} ${action}`, subject);
          else
            text = this.L(`{0} ${action}`, subject);
          break;
      }
      
      return text;
    }
  }
}
</script>