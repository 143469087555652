import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Keys } from '../const';
import storage from './persistentStorage';

export const useSessionStore = defineStore('session', () => {
  // 인증 토큰
  const authToken = ref();
  storage.get(Keys.AUTH_TOKEN).then(v => setAuthToken(v));

  async function setAuthToken(token) {
    authToken.value = token || '';
    await storage.set(Keys.AUTH_TOKEN, token || '');
  }

  // 로그인 정보
  const login = ref(null);
  function setLogin(user) {
    login.value = user;
  }

  // 사용자 루트 폴더
  const rootFolder = ref(null);
  function setRootFolder(folder) {
    rootFolder.value = folder;
  }

  return { authToken, setAuthToken, login, setLogin, rootFolder, setRootFolder }
})
