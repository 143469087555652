<template>
	
	<header class="top-header" style="height:3rem; justify-content:flex-start">
		 <PageHeaderM :siteImg="siteImg" /> 
	</header>
	<div id="mainContent" class="mainContent">
		<div id="mainContentHolder">
			<div class="main-right-M" :class="{ 'detail-open': preference.detailOpen }"><slot /></div>
		</div>
	</div>	
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from '../api';
import { reactive } from 'vue';

import { usePreferenceStore } from '../stores/preference';
import PageHeaderM from './PageHeaderM.vue';

export default {

  setup() {
    const preference = usePreferenceStore();
    const siteImg = reactive({ value: null });

//     api.getAdminSettings().then(adminSettings => { 

// 		let theme = document.querySelector(':root');

// 		if (adminSettings[0].colorTop !== null) {		
// 			theme.style.setProperty('--top-background-color',adminSettings[0].colorTop);
// 			theme.style.setProperty('--search-box-background-color',adminSettings[0].colorTop);
// 		}

// 		if (adminSettings[0].colorLeft !== null) {
// 			theme.style.setProperty('--aside--background-color',adminSettings[0].colorLeft);		
// 		}

// 		if (adminSettings[0].colorBottom !== null) {
// 			theme.style.setProperty('--content--background-color',adminSettings[0].colorBottom);	
// 		}

// 		if (adminSettings[0].fontColor !== null) {
// 			theme.style.setProperty('--title-font-color',adminSettings[0].fontColor);
// 		}

// 		if (adminSettings[0].fontSubColor !== null) { 
// 			theme.style.setProperty('--content-font-color',adminSettings[0].fontSubColor);
// 		}

// 		if (adminSettings[0].fontTitle !== null) {
// 			theme.style.setProperty('--title-font',adminSettings[0].fontTitle);
// 		}else{
// 			theme.style.setProperty('--title-font','KB-title');
// 		}

// 		if (adminSettings[0].fontTitleSize !== null) {
// 			theme.style.setProperty('--title-font-size',(adminSettings[0].fontTitleSize *0.063)+'rem');
// 		}else{
// 			theme.style.setProperty('--title-font-size','1.875rem');
// 		}
		
// 		if (adminSettings[0].fontContent !== null) {
// 			theme.style.setProperty('--content-font',adminSettings[0].fontContent);
// 		}else{
// 			theme.style.setProperty('--content-font','KB-content');;
// 		}
		
// 		if (adminSettings[0].fontContentSize !== null) {
// 			theme.style.setProperty('--content-font-size',(adminSettings[0].fontContentSize *0.063)+'rem');
// 		}else{
// 			theme.style.setProperty('--content-font-size','0.9375rem');
// 		}

// 		// if (adminSettings[0].siteTitle !== null) {
// 		// 	// 타이틀
// 		// 	const title = document.querySelector('.siteTitle');
// 		// 	title.textContent = adminSettings[0].siteTitle;
// 		// }

// 		// if (adminSettings[0].siteTitleYN !== null) {
// 		// 	// 타이틀 노출 여부
// 		// 	const title = document.querySelector('.siteTitle');
// 		// 	title.style.display = adminSettings[0].siteTitleYN === 'Y' ? 'block' : 'none';
// 		// }

// 		// if(adminSettings[0].footerCopy != null && adminSettings[0].footerCopy != ''){
// 		// 	document.querySelectorAll('.copy')[0].innerHTML = adminSettings[0].footerCopy;
// 		// }
// 		// if(adminSettings[0].footerUrl != null && adminSettings[0].footerUrl != ''){
// 		// 	document.querySelectorAll('.url')[0].innerHTML = adminSettings[0].footerUrl;
// 		// }

// 	})

    return { preference, siteImg  };

  },
  components: {  PageHeaderM,  },

//   data() {
// 	return {
// 		siteImg: null, // Set an initial value or null
// 	};
// 	},
 
  mounted() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  },  
  
  methods: {
	startDrag(event) {		
		calculatepercent(event.target.offsetLeft);
	},
	onDrop(event){
		calculatepercent(event.clientX - event.target.getBoundingClientRect().left);


	},
	drag(event){
		calculatepercent(event.target.offsetLeft + event.clientX - event.target.getBoundingClientRect().left);
	},
	menuClick(){
		if(document.getElementById('mobileMenu').style.display == 'none'){
			document.getElementById('mobileMenu').style.display = 'block';
		}else{
			document.getElementById('mobileMenu').style.display= 'none';
		}
	}
  }
};
</script>