<template>
        <teleport to="body">
          <div class="backdrop" v-if="isVisible">
            <div class="modal-container">
              <slot></slot>
            </div>
          </div>
        </teleport>
      </template>
      
      <script>
      import { ref } from "vue";
      export default {
        name: "Modal",
        setup() {
          const isVisible = ref(false);
      
          const open = () => { 
            isVisible.value = true;
          };
      
          const close = () => {
            isVisible.value = false;
          };
          return {
            isVisible,
            open,
            close,
          };
        },
      };
      </script>