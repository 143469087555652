import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAccessStore = defineStore('access', () => {

  // 접속 기기 정보
  const isDevice = ref(null);
  function setisDevice(isMobile){
    if(isMobile){
      isDevice.value = true;
    } else{
      isDevice.value = false;
    }
  }

  return { isDevice, setisDevice };
});
