<template>
    <template v-if="file.filename.lastIndexOf('.d') == '-1'">
      {{ file.filename }}&nbsp;&nbsp;
    </template>
    <template v-else>
      <!-- <template v-if="file.filename.split('.').pop().toLowerCase().length > 5">
        {{ file.filename.substring(0,file.filename.lastIndexOf('.d')) }}
      </template> -->
      <template v-if="file.filename.split('.').pop().toLowerCase().length > 5">
        {{ file.filename }}&nbsp;&nbsp;
      </template>
      <template v-else>
        {{ file.filename }}&nbsp;&nbsp;
      </template>
    </template>
    <div class="favorites" v-if="file.starred === true">
      <input type="checkbox" name="favorites[]" :id="file.id" value="on" checked>
      <label :for="'favorites'"></label>
    </div>
</template>

<script>
import { IS_MOBILE } from '../../../const';

export default {
  emits: ["open"],
  props: ["file", "field"],
  computed: {
    IS_MOBILE() { return IS_MOBILE; },
  },
};
</script>
