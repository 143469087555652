<template>
    <Modal ref="baseModal">
      <div class="organization-chart-popup">
        <div class="organization-chart-wrap">
          <h2 class="organization-chart-title">{{L('Sharing')}}</h2>          
            <div class="tab-wrap">
                <div class="flex-box organization-chart-content-box">
                    <div class="tabs-wrap">
                      <TreeTab @command="handleClick" ref="treeTab"/>
                        <div class="tabs-content" id="etc">
                            <div class="organization-chart">
                                    <ul>
                                        <li v-for="(item, index) in content" :key="item.organizeId"  class="dropdown" @click="treeOpen($event, item)">
                                            <div class="checkbox" style="padding-left: 2.8rem;">                                          
                                                <input type="checkbox" class="_chk" :id="item.organizeId" @click="checkClick($event,item)">
                                                <label class="_chkLabel" :for="item.organizeId"></label>
                                            </div>
                                            <span style="padding-left: 0rem;" @click="organizeClick($event, item)">{{ item.name}}</span>
                                            <ul>
                                                <li v-for="(item2, index) in item.nodes" :key="item2.organizeId"  class="dropdown">                                            
                                                    <div class="checkbox" style="padding-left: 4.7rem;">
                                                        <input type="checkbox" class="_chk" :id="item2.organizeId"  @click="checkClick($event,item2)">
                                                        <label class="_chkLabel" :for="item2.organizeId"></label>
                                                    </div>
                                                    <span style="padding-left: 0rem;" @click="organizeClick($event, item2)">{{ item2.name}}</span>
                                                    <ul>
                                                        <li v-for="(item3, index) in item2.nodes" :key="item3.organizeId">                                                      
                                                            <div class="checkbox" style="padding-left: 6rem;">
                                                                <template v-if="item3.organizeId != userId">
                                                                    <template v-if="userAuthChoice.some(v => v.parentId ===  item3.parentId && v.organizeId ===  item3.organizeId) == true">
                                                                        <input type="checkbox" class="_chk" :id="item3.organizeId" disabled>
                                                                        <label class="_chkLabel" :for="item3.organizeId" style="background-color: rgb(51, 46, 46);"></label>
                                                                    </template> 
                                                                    <template v-else>
                                                                        <input type="checkbox" class="_chk" :id="item3.organizeId"  @click="checkClick($event,item3)">
                                                                        <label class="_chkLabel" :for="item3.organizeId"></label>
                                                                    </template>
                                                                </template>                                                        
                                                            </div>
                                                            <template v-if="item3.organizeId != userId">
                                                                <span style="padding-left: 0rem;" @click="organizeClick($event, item3)">
                                                                    {{ item3.name + '(' + item3.organizeId + ')' }}
                                                                    <template v-if="item3.favoriteYn">
                                                                        <img src="@/assets/img/ico-favorites-active.png" style="padding-left: .5rem;">
                                                                    </template>
                                                                </span>
                                                            </template>
                                                            <template v-else>
                                                                <span style="padding-left: 1.2rem;">
                                                                    {{ item3.name + '(' + item3.organizeId + ')' }}
                                                                </span>
                                                            </template>                                                      
                                                            <button v-if="item3.favoriteYn  && item3.organizeId != userId"  class="btn" style="margin-right: 1.2rem;background-color: #CBCCCE;line-height: 2rem;" @click="delFavorites(item3)">{{L('친구해제')}}</button>
                                                            <button v-else-if="!item3.favoriteYn && item3.organizeId != userId" class="btn" style="margin-right: 1.2rem;background-color: var(--top-background-color);line-height: 2rem;" @click="addFavorites(item3)">{{L('친구등록')}}</button>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                        <div class="tabs-content" id="etc2">
                            <div class="organization-chart">
                                    <ul> 
                                        <div v-if="selectionType ==='Search'" class="flex-box popup-search">
                                            <div class="inner-field">
                                                <input type="text" class="field" id="search"  @keyup.enter="search"/>
                                                <button class="btn btn-orange btn-midium2" @click="search">{{L('Search')}}</button>
                                            </div>
                                        </div>
                                        <template v-if="selectionType ==='Search'">
                                            <template v-if="searchContent.length ==0">
                                                <li style="display: inline-block;">
                                                    <span>{{'검색된 사용자가 없습니다.'}}</span>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li v-for="(item, index) in searchContent" :key="item.organizeId">
                                                    <div class="checkbox">
                                                        <template v-if="item.organizeId != userId">
                                                            <template v-if="userAuthChoice.some(v => v.parentId ===  item.parentId && v.organizeId ===  item.organizeId) == true">
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" disabled>
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"  style="background-color: rgb(51, 46, 46);"></label>
                                                            </template> 
                                                            <template v-else>
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" @click="checkClick($event,item)">
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"></label>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <template v-if="item.organizeId != userId">
                                                        <span style="padding-left: .1rem;" @click="organizeClick($event, item)">{{ item.organizeName != undefined ? item.organizeName.split('<')[1] +'<' +item.organizeName.split('<')[2] :''}} {{item.name}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="padding-left: 1.4rem;">{{ item.organizeName != undefined ? item.organizeName.split('<')[1] +'<' +item.organizeName.split('<')[2] :''}} {{item.name}}</span>
                                                    </template>                                                
                                                    <button v-if="selectionType ==='Favorites'" @click="delFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: var(--top-background-color);line-height: 2rem;" > {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='Y' && item.organizeId != userId" @click="delFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: #CBCCCE;line-height: 2rem;"> {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='N' && item.organizeId != userId" @click="addFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: var(--top-background-color);line-height: 2rem;">  {{L('친구등록')}}</button>
                                                </li>
                                            </template>
                                        </template>
                                        <template v-else>                                        
                                            <template v-if="content.length ==0 ">
                                                <li style="display: inline-block;">
                                                    <span>{{'등록된 친구목록이 없습니다.'}}</span>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li v-for="(item, index) in content" :key="item.organizeId">
                                                    <div class="checkbox">
                                                        <template v-if="item.organizeId != userId">
                                                            <template v-if="userAuthChoice.some(v => v.parentId ===  item.parentId && v.organizeId ===  item.organizeId) == true">
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" disabled>
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"  style="background-color: rgb(51, 46, 46);"></label>
                                                            </template> 
                                                            <template v-else>
                                                                <input type="checkbox" class="_chk" :id="this.selectionType +'_'+item.organizeId" @click="checkClick($event,item)">
                                                                <label class="_chkLabel" :for="this.selectionType +'_'+item.organizeId"></label>
                                                            </template>
                                                        </template>
                                                    </div>
                                                    <template v-if="item.organizeId != userId">
                                                        <span style="padding-left: .1rem;" @click="organizeClick($event, item)">{{ item.organizeName != undefined ? item.organizeName.split('<')[1] +'<' +item.organizeName.split('<')[2] :''}} {{item.name}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="padding-left: 1.4rem;">{{ item.organizeName != undefined ? item.organizeName.split('<')[1] +'<' +item.organizeName.split('<')[2] :''}}</span>
                                                    </template>                                                
                                                    <button v-if="selectionType ==='Favorites'" @click="delFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: var(--top-background-color);line-height: 2rem;" > {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='Y' && item.organizeId != userId" @click="delFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: #CBCCCE;line-height: 2rem;"> {{L('친구해제')}}</button>
                                                    <button v-if="selectionType ==='Search' && item.favoriteYn =='N' && item.organizeId != userId" @click="addFavorites(item)" class="btn" style="margin-right: 1.2rem;background-color: var(--top-background-color);line-height: 2rem;">  {{L('친구등록')}}</button>
                                                </li>
                                            </template>
                                        </template>

                                    </ul>
                                </div>
                        </div>
  
                        <div class="tabs-content" id="etc3">
                          <ul>
                              <li v-for="(item, index) in selected" :key="item.id">
                                  <div class="flex-box">                                
                                          <div class="inner-field">    
                                              <div style="float: left;font-size: 1rem;padding : 1%;">
                                                  {{ item.selectPathName }} {{ item.name}}
                                              </div>
                                              <div style="float: left;padding : 1%;">
                                                  <select id="authId" class="field non-border" style="width: 6rem;" v-model="item.authId">
                                                      <option value="All">전체</option>
                                                      <option value="Read">읽기</option>
                                                      <option value="Check">검토</option>
                                                  </select>
                                              </div>    
                                              <div style="float: left;margin-left: 4rem;margin-top: -0.8rem;">
                                                  <button class="btn btn-gray btn-midium2" @click="del(item)">{{L('Delete')}}</button>
                                              </div>
                                          </div>
                                  </div>
                              </li>
                          </ul>
                        </div>
  
                    </div>
                </div>
            </div>
            <div class="confirm-buttons">
                <button id="cancelBtn" style="padding-left: 2.25rem;padding-right: 2.25rem;display:"     class="btn btn-gray btn-round btn-large" @click="close">{{L('Cancel')}}</button>
                <button id="beforeBtn" style="padding-left: 2.25rem;padding-right: 2.25rem;display:none" class="btn btn-orange btn-round btn-large" @click="before">{{L('Before')}}</button>
                <button id="selectBtn" style="padding-left: 2.25rem;padding-right: 2.25rem;display:"     class="btn btn-orange btn-round btn-large" @click="select">{{L('Select')}}</button>
                <button id="sharedBtn" style="padding-left: 2.25rem;padding-right: 2.25rem;display:none" class="btn btn-orange btn-round btn-large" @click="confirm">{{L('Sharing')}}</button>
            </div>
            <a href="#" class="btn-close">
                <i class="icon icon-close"  @click="close"></i>
            </a>
        </div>
    </div>
    </Modal>
  </template>
  
  <script>
  import api from '../../../api';
  import { ref } from "vue";
  
  import Modal from "@/components/files/utils/Modal.vue";
  import { parentOf, alert2, confirm2, L, showToast } from '../../../utils';
  import TreeTab from './TreeTab.vue';
  import { useSessionStore } from '../../../stores/session';

  export default {
  //   name: "ConfirmationModal",
    setup() {
        const session = useSessionStore();
        const userId = session.login == null ? '' : session.login.userId.toLowerCase();
        return { session, userId };
    },

    emits: ['share'], 
    components: {
      Modal, TreeTab
    },
    props: {
      item: {
        type: Array,
        required: true,
      },
    },
  
    watch: {
      item() {    
        this.content = [];
        this.selected = [];
      }
    },
  
    data: function () {
      return {
          content: [],
          selected: [],
          shareSelect:[],
          selectionType : null,
          items:[],
            userAuthChoice:[],
            searchContent: [],
      };
    },
    methods :{
        async load(organizeId) {
            // this.allSelected = [];
            // this.examineSelected = [];
            // this.readSelected = [];
            // this.selected = [];
            // this.shareSelect=[];
    
            this.selectionType = organizeId;
            document.querySelectorAll('.tabs-content').forEach(function(ul, j) {
                ul.classList.remove('active');
            });
    
            if(organizeId === 'Favorites'){
                const result = await api.organizeFavorites();
                this.content = result;
                document.getElementById('etc2').classList.toggle('active');
            } else if(organizeId === 'Search'){
                
                document.getElementById('etc2').classList.toggle('active');
            }else{
                const result = await api.organize(organizeId);
                
                this.content = result[0].nodes;
                document.getElementById('etc').classList.toggle('active');
            }
        },
    
        organizeClick(e){
            e.currentTarget.parentNode.classList.toggle('active');    
            e.preventDefault();
        },
    
    
        show(){
            this.items = this.item;
            this.$refs.baseModal.open();
        },
    
        async before(){        
    
            document.querySelectorAll('.popup-search')[0].style.display='';
            document.querySelectorAll('.tabs1')[0].style.display='';
            document.getElementById('etc').classList.toggle('active');
            document.getElementById('etc2').classList.remove('active');
            document.getElementById('etc3').classList.remove('active');        
            document.getElementById('beforeBtn').style.display="none";
            document.getElementById('selectBtn').style.display="";
            document.getElementById('sharedBtn').style.display="none";
            document.querySelectorAll('.organization-chart-content-box')[0].style.height="calc(100vh - 28rem)";
    
            document.getElementById('etc').click();
    
            this.selected = [];
    
        },
    
        async select(){
    
            if(this.selectionType === 'Search' || this.selectionType === 'Favorites'){
                for(var _idx=0; _idx<this.selected.length; _idx++){
                    this.selected[_idx].selectPathName = this.selected[_idx].organizeName;
                    this.selected[_idx].authId = "Read";       
                }
            }else{
                for(let i=0; i<this.selected.length; i++){    
                    let parentId = this.selected[i].parentId;
                    let depth1Name = this.$refs.treeTab.tabMenuArr.find(v => v.organizeId ===  parentId.substring(0,1)).name;
                    let depth2Name = this.content.find(v => v.organizeId ===  parentId.substring(0,3)).name;
                    let depth3Name = this.content.find(v => v.organizeId ===  parentId.substring(0,3)).nodes.find(v => v.organizeId ===  parentId).name;
                    this.selected[i].selectPathName = depth1Name + " < " + depth2Name + " < " + depth3Name;
                    this.selected[i].authId = "Read";
                }
            }
            
            document.querySelectorAll('.tabs1')[0].style.display='none';        
            document.getElementById('etc').classList.remove('active');
            document.getElementById('etc2').classList.remove('active');
            document.getElementById('etc3').classList.toggle('active');
    
            document.getElementById('beforeBtn').style.display="none";
            document.getElementById('selectBtn').style.display="none";
            document.getElementById('sharedBtn').style.display="";
            document.getElementById('cancelBtn').style.display="";
            document.querySelectorAll('.organization-chart-content-box')[0].style.height="calc(100vh - 25rem)";
            document.querySelectorAll('.tabs-content')[2].style.height ="calc(100vh - 27.5rem)";
            
        },
    
        async confirm(){
            let cont ="";
            this.shareSelect=[];
            if(this.selected.length == 0){
                alert2(this.L('There is no one to share with.'), 'Sharing');
                this.close();
                return;
            }
    
            for(var i=0; i <this.items.length; i++){
                for(var j=0; j<this.selected.length; j++){
                    cont="{\"userId\":\""+this.items[i].userId+"\", \"path\":\""+this.items[i].path+"\", \"type\":\"USER\", \"shareWith\":\""+this.selected[j].organizeId +"\",\"shareName\":\""+this.selected[j].name+"\", \"shareAuth\":\""+this.selected[j].authId+"\",\"edit\":\"1\",\"create\":\"1\",\"delete\":\"1\",\"expiration\":\"\",\"password\":\"\"}" ; 
                    this.shareSelect.push(cont)
                }            
            }
    
            const result = await api.sharerRgistration(this.shareSelect);
            if (result) {             
                showToast(this.L('Share registration has been completed.'), 'success');
            }
            this.$emit('share');
            this.$refs.baseModal.close();
        },
  
        del(item){
            let _idx =this.selected.findIndex(v => v.id ===  item.id);
            if(this.selectionType === 'Search' || this.selectionType === 'Favorites'){            
                document.getElementById(this.selectionType +'_'+ item.organizeId).disabled = false;
                document.querySelector('label[for='+ this.selectionType +'_'+ item.organizeId +']').style.backgroundColor="";
            }else{            
                document.getElementById(item.organizeId).disabled = false;
                document.querySelector('label[for='+ item.organizeId +']').style.backgroundColor="";
            }
            this.selected.splice(_idx, 1);
        },
    
        close(){ 
            this.selected = [];
            this.userAuthChoice = [];            
            this.searchContent = [];
            this.$emit('share');
            this.$refs.baseModal.close();
         },
  
        handleClick(command) {
            this.load(command);
        },
  
        async search(){
  
            const searchText = document.getElementById('search').value;

            if(searchText == "") { this.searchContent =[];  return; }
            const result = await api.organizeSearch( searchText);
            this.searchContent = result;
        },

        /** tree icon 클릭 */
        treeOpen(e, item){ 
            if(e.target.nodeName.toLowerCase() ==="li"){
                e.target.classList.toggle('active');
                e.preventDefault();
            }else if(e.target.nodeName.toLowerCase() ==="div"){
                e.target.parentNode.classList.toggle('active')
                e.preventDefault();
            }
        },
  
        /** checkbox 클릭 */
        checkClick(e, item){
            this.clickItem(e, item);
        },
    
        /** name 클릭 */
        organizeClick(e, item){
            this.clickItem(e, item);        
        },

        clickItem(e, item) { 

            let _selected = [];
            _selected = this.selected;
            let _parentNode = e.target.parentNode;

            if(item.depth == 3){

                if(item.organizeId === this.userId) return;

                _parentNode.childNodes.forEach(function(_terget, i) {

                    if(_terget.nodeName.toLowerCase() == "div"){
                        _terget.childNodes.forEach(function(_divTerget, i) {
                            if(_divTerget.nodeName.toLowerCase() == "input"){
                                if(!_divTerget.disabled){
                                    if(!_divTerget.checked){
                                        _selected.push(item)
                                        _divTerget.checked = true;
                                    }else{
                                        let _idx =_selected.findIndex(v => v.id ===  item.id);
                                        _selected.splice(_idx, 1);
                                        _divTerget.checked = false;
                                    }
                                }
                            }                        
                        });
                    }else if(_terget.nodeName.toLowerCase() == "input"){  
                        if(_terget.checked){
                            _selected.push(item)
                            _terget.checked = true;
                        }else{
                            let _idx =_selected.findIndex(v => v.id ===  item.id);
                            _selected.splice(_idx, 1);
                            _terget.checked = false;
                        }             
                    }
                }); 
            }else if(item.depth == 2){
                let _checked = _parentNode.children[0].checked;
                if(_parentNode.nodeName.toLowerCase() == 'div'){
                    _parentNode.parentNode.childNodes.forEach(function(_terget, i) {
                        if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, i) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_liTerget, j) { 
                                        if(_liTerget.nodeName.toLowerCase() == "div"){
                                            _liTerget.childNodes.forEach(function(_divTerget2, k) {                                   
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){
                                                            _selected.push(item.nodes[i - 1]);                                                    
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[i - 1].id);
                                                            _selected.splice(_idx, 1);
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                }                        
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }else if(_parentNode.nodeName.toLowerCase() == 'li'){
                    let _checked = false;
                    _parentNode.childNodes.forEach(function(_terget, i) {                     
                        if(_terget.nodeName.toLowerCase() == "div"){     
                                        
                            _terget.childNodes.forEach(function(_divTerget, i) {

                                if(_divTerget.nodeName.toLowerCase() == "input"){
                                
                                    if(!_divTerget.disabled){
                                        if(!_divTerget.checked){
                                            _divTerget.checked = true;
                                            _checked = true;
                                        }else{
                                            _divTerget.checked = false;
                                            _checked = false;
                                        }
                                    }
                                }
                            });
                        }else if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, i) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_liTerget, j) {                               
                                        if(_liTerget.nodeName.toLowerCase() == "div"){
                                            _liTerget.childNodes.forEach(function(_divTerget2, k) {
                                                
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){
                                                            _selected.push(item.nodes[i - 1]);                                                    
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[i - 1].id);
                                                            _selected.splice(_idx, 1);
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                }                        
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    }); 
                }

            }else if(item.depth == 1){
                let _checked = _parentNode.children[0].checked;
                if(_parentNode.nodeName.toLowerCase() == 'div'){
                    _parentNode.parentNode.childNodes.forEach(function(_terget, i) { 
                        if(_terget.nodeName.toLowerCase() == "ul"){
                            _terget.childNodes.forEach(function(_divTerget, j) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_ulTerget, k) {
                                        if(_ulTerget.nodeName.toLowerCase() == "div"){
                                            _ulTerget.childNodes.forEach(function(_divTerget2, l) {
                                                if(_divTerget2.nodeName.toLowerCase() == "input"){
                                                    if(!_divTerget2.disabled){
                                                        if(_checked){             
                                                            _divTerget2.checked = true;
                                                        }else{
                                                            _divTerget2.checked = false;
                                                        }
                                                    }
                                                } 
                                            });
                                        }else if(_ulTerget.nodeName.toLowerCase() == "ul"){
                                            _ulTerget.childNodes.forEach(function(_liTerget, m) { 
                                                if(_liTerget.nodeName.toLowerCase() == "li"){
                                                    _liTerget.childNodes.forEach(function(_divTerget2, n) {          
                                                        if(_divTerget2.nodeName.toLowerCase() == "div"){
                                                            _divTerget2.childNodes.forEach(function(_divTerget3, o) {
                                                                if(_divTerget3.nodeName.toLowerCase() == "input"){
                                                                    if(!_divTerget3.disabled){  
                                                                        if(_checked){
                                                                            _selected.push(item.nodes[j - 1].nodes[m-1]);                                                    
                                                                            _divTerget3.checked = true;
                                                                        }else{
                                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[j - 1].nodes[m-1]);
                                                                            _selected.splice(_idx, 1);
                                                                            _divTerget3.checked = false;
                                                                        }
                                                                    }
                                                                }                        
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }else if(_parentNode.nodeName.toLowerCase() == 'li'){
                    let _checked = false;
                    e.target.parentNode.childNodes.forEach(function(_terget, i) { 
                        if(_terget.nodeName.toLowerCase() == "div"){
                            _terget.childNodes.forEach(function(_divTerget, j) {
                                if(_divTerget.nodeName.toLowerCase() == "input"){ 
                                    if(!_divTerget.disabled){
                                        if(!_divTerget.checked){
                                            _divTerget.checked = true;
                                            _checked = true;
                                        }else{
                                            _divTerget.checked = false;
                                            _checked = false;
                                        }
                                    }
                                }
                            });
                        }else if(_terget.nodeName.toLowerCase() == "ul"){                    
                            _terget.childNodes.forEach(function(_divTerget, k) {
                                if(_divTerget.nodeName.toLowerCase() == "li"){
                                    _divTerget.childNodes.forEach(function(_ulTerget, l) {  
                                        if(_ulTerget.nodeName.toLowerCase() == "div"){
                                            _ulTerget.childNodes.forEach(function(_liTerget, m) {
                                                if(_liTerget.nodeName.toLowerCase() == "input"){                            
                                                    if(!_liTerget.disabled){
                                                        if(_checked){
                                                            _liTerget.checked = true;
                                                        }else{
                                                            _liTerget.checked = false;
                                                        }
                                                    }
                                                }
                                            });
                                        }else if(_ulTerget.nodeName.toLowerCase() == "ul"){                                   
                                            _ulTerget.childNodes.forEach(function(_liTerget, n) {
                                                if(_liTerget.nodeName.toLowerCase() == "li"){
                                                    _liTerget.childNodes.forEach(function(_divTerget2, o) {          
                                                        if(_divTerget2.nodeName.toLowerCase() == "div"){
                                                            _divTerget2.childNodes.forEach(function(_divTerget3, p) {
                                                                if(_divTerget3.nodeName.toLowerCase() == "input"){
                                                                    if(!_divTerget3.disabled){ 
                                                                        if(_checked){
                                                                            _selected.push(item.nodes[k - 1].nodes[n-1]);                                                    
                                                                            _divTerget3.checked = true;
                                                                        }else{
                                                                            let _idx =_selected.findIndex(v => v.id ===  item.nodes[k - 1].nodes[n-1]);
                                                                            _selected.splice(_idx, 1);
                                                                            _divTerget3.checked = false;
                                                                        }
                                                                    }
                                                                }                        
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }

            this.selected = _selected; 
        },

        async delFavorites(item){
            const result = await api.delOrganizeFavorites(item.organizeId, item.parentId);         
            this.load(this.selectionType) ;
            if(this.selectionType == "Search"){
                this.search();
            }
        },
        async addFavorites(item){       
            const result = await api.addOrganizeFavorites(item.organizeId, item.parentId);  
            this.load(this.selectionType);
            if(this.selectionType == "Search"){
                this.search();
            }
        },

    }
  };
  </script>
