<template>
  <div class="tabcontent" id="version">
		  <template v-if="versionsList?.length > 0">
				<ul class="activity-contents">
					<li v-for="(version, index) in versionsList" :key="version.id">
            <div class="text">
              <img src="@/assets/img/file-icon/icon-download.png">&nbsp;&nbsp;{{ 'v.'+ (versionsList.length - index) }}
              <button type="button" class="btn btn-midium3 btn-orange" style="padding-left: 1.25rem;padding-right: 1.25rem;"  @click.prevent="handleDownload(version)">{{L('Download')}}</button>
              <span class="reg-date">{{ formatFileSize(version.size) }}</span>
              <span class="reg-date">{{ formatDateTime(version.timestamp) }}</span>
            </div>
          </li> 
				</ul>
      </template>
      <template v-else>
        <ul class="activity-contents">
					<li>
						버전정보가 없습니다.
					</li>          
				</ul>
      </template>

	</div>
</template>

<script>
import api from '../../api';
import { useSessionStore } from '../../stores/session';
import { confirm2, formatDate, formatDateTime, formatFileSize, parentOf, showToast } from '../../utils';
import { isMobile } from 'mobile-device-detect';

export default {
  name: "VersionsPanel",
  props: {

    item:{
      type: Object,
      required: true
    }
  },
  setup() {
    const session = useSessionStore();
    return { session };
  },
  data() {
    return {
      versionsList: [],
      extFlag : true,
    }
  },
   
  mounted() { /* 상세보기 진입 시 최초로 노출되는 탭이므로 mounted 사용 */
    //if(isMobile){
      if(this.item.id != undefined)
         this.versionInfo(this.item)
    //}
  },

  watch: {
    item() {
      this.versionInfo(this.item);
    }
  },
  methods: {
    formatDate,
    formatDateTime,
    formatFileSize,

    async versionInfo(item) {
      this.versionsList = await api.listVersions(item.userId, item.path);
      this.versionsList.sort((a, b) => b.timestamp - a.timestamp);
      
    },

    async handleDownload(version) {
      const folder = parentOf(this.item.path);      
      //await api.downloadVersion(this.item.userId, folder, version.filename, version.timestamp);
      await api.downloadVersion(this.item.userId, this.item.path, this.item.filename, version.timestamp);
    },

    async handleView(version) {
      const folder = parentOf(this.item.path);
      //console.log(version.filename, version.timestamp);

      //window.open(`/edit?path=${encodeURIComponent(file.path)}&mode=${mode}`);
      //await api.downloadVersion(this.item.userId, folder, version.filename, version.timestamp);
    },

    async handleRestore(version) {
      const ok = await confirm2(this.L('Restore this version?'), version.filename, 'question');
      if (!ok) return;

      const file = await api.restoreVersion(this.item.path, version.timestamp);
      if (file) {
        this.$emit('restored', file);
        showToast(this.L('Version restored'), 'success');
      }
    },
    async handleDelete(version) {
      const ok = await confirm2(this.L('Delete this version? This cannot be undone.'), version.filename, 'question');
      if (!ok) return;

      const result = await api.deleteVersion(this.item.path, version.timestamp);
      if (result) {
        this.versionsList.splice(this.versionsList.indexOf(version), 1);
      }
    }
  }
}
</script>
