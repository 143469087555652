<template>
  <div>
    <div v-if="userId === 'admin'" id="tree">
      <ul>
        <li class="admin" id="all">
          <span>
            <router-link to="/adminShare">{{ preference.lang === 'ko' ? '관리자' : 'Admin' }}</router-link>
          </span>
          <ul>
            <li id="id1" class="not-icon" title="">
              <span>
                <router-link to="/adminShare">{{ preference.lang === 'ko' ? '설정' : 'Settings' }}</router-link>
              </span>
            </li>
            <li id="id2" class="not-icon" title="">
              <span>
                <router-link to="/adminSecurity">{{ preference.lang === 'ko' ? '보안' : 'Security' }}</router-link>
              </span>
            </li>
            <li id="id3" class="not-icon" title="">
              <span>
                <router-link to="/adminTheme">{{ preference.lang === 'ko' ? '테마' : 'Theme' }}</router-link>
              </span>
            </li>
            <li id="id4" class="not-icon" title="">
              <span>
                <router-link to="/organize">{{ preference.lang === 'ko' ? '조직도' : 'Organization Chart' }}</router-link>
              </span>
            </li>
            <li id="id5" class="not-icon" title="">
              <span>
                <router-link to="/groupStatistics">{{ preference.lang === 'ko' ? '통계' : 'Statistics' }}</router-link>
              </span>
              <ul>
                <li id="share1" class="not-icon" title="">
                  <span>
                    <router-link to="/groupStatistics">{{ preference.lang === 'ko' ? '그룹별 통계' : 'Statistics by group' }}</router-link>
                  </span>
                </li>
                <li id="share2" class="not-icon" title="">
                  <span>
                    <router-link to="/departmentStatistics">{{ preference.lang === 'ko' ? '부서별 통계' : 'Departmental Statistics' }}</router-link>
                  </span>
                </li>
                <li id="share3" class="not-icon" title="">
                  <span>
                    <router-link to="/userStatistics">{{ preference.lang === 'ko' ? '사용자별 통계' : 'Statistics by user' }}</router-link>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div v-else>
      <div id="tree">
        <ul>
          <li class="folder" id="fileList">
            <span> 
              <router-link to="/" class="button">{{ L('File List') }}</router-link>
            </span>
          </li>
          <li class="recent" id="recent">
            <span>
              <router-link to="/recent">{{ L('Recent files') }}</router-link>
            </span>
          </li>
          <li class="favorites" id="favorites">
            <span>
              <router-link to="/starred">{{ L('Starred files') }}</router-link>
            </span>
          </li>
          <li class="sharing" id="sharing">
            <span>
              <router-link to="/sharedOut">{{ L('Sharing') }}</router-link>
            </span>
            <ul>
              <li id="share1" class="not-icon" title="">
                <span>
                  <router-link to="/sharedOut">{{ L('Shared by me') }}</router-link>
                </span>
              </li>
              <li id="share2" class="not-icon" title="">
                <span>
                  <router-link to="/sharedIn">{{ L('Shared with me') }}</router-link>
                </span>
              </li>
              <li id="share3" class="not-icon" title="">
                <span>
                  <router-link to="/sharedDisarmed">{{ L('Shared Deleted') }}</router-link>
                </span>
              </li>
              <li id="share3" class="not-icon" title="">
                <span>
                  <router-link to="/SharedTransFer">{{ L('Transfer Of Ownership') }}</router-link>
                </span>
              </li>
              <li id="share4" class="not-icon" title="">
                <span>
                  <router-link to="/sharedProgress">{{ L('SharedIng') }}</router-link>
                </span>
              </li>
              <li id="share5" class="not-icon" title="">
                <span>
                  <router-link to="/listLinkShare">{{ L('SharedLink') }}</router-link>
                </span>
              </li>
            </ul>
          </li>
          <li class="trash" id="trash">
            <span>
              <router-link to="/recycle">{{ L('Recycle bin') }}</router-link>
            </span>
          </li>
          <li class="preference" id="settings">
            <span>
              <router-link :to="'/settings'">{{ L('Settings') }}</router-link>
            </span>
            <ul>
              <li id="personalSettings" class="not-icon" title="">
                <span>
                  <router-link to="/settings">{{ L('Personal settings') }}</router-link>
                </span>
              </li>
              <li id="security" class="not-icon" title="">
                <span>
                  <router-link to="/security">{{ L('Security') }}</router-link>
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div v-if="userId !== 'admin'" class="storage-info">
    <div class="space-state">
      <span class="text">
        {{ L('Storage') }} / {{ L('File List') }}
      </span>
      <router-link to="/recycle"><i class="icon icon-trash"></i></router-link>
    </div>
    <progress id="file" max="100" class="progress" :value="used" Style="background-color:red"></progress>
    <div class="space-size">
      <span class="current">{{ capacityText }}</span>
    </div>
  </div>
</template>

<script>
// import { RouterLink } from 'vue-router';

import api from '../api';
import { treeMenuControl } from '../assets/js/treeMenuControl';
import { usePreferenceStore } from '../stores/preference';
import { useSessionStore } from '../stores/session';
import { formatFileSize } from '../utils';

export default {
  setup() {
    const session = useSessionStore();
    const preference = usePreferenceStore();
    const userId = session.login == null ? '' : session.login.userId.toLowerCase();

    return { session, userId, preference };
  },
  // components: { RouterLink },
  data() {
    return {
      search: '',
      host: document.location.hostname,
      lang: this.preference.lang,
      _router: this.$router,
    };
  },
 
  created() {
    this.getAdminSettings();
  },
  mounted() {
    treeMenuControl(this.$router);
  },

  computed: {
    // webviewHandler() {
    //   return window.flutter_inappwebview?.callHandler;
    // },

    used() {
      const max = useSessionStore().rootFolder?.quota;
      if (!max) return 0;
      return Math.round(((useSessionStore().rootFolder?.size || 0) / max) * 100);
    },

    capacityText() {
      const used = formatFileSize(useSessionStore().rootFolder?.size);
      const quota = formatFileSize(useSessionStore().rootFolder?.quota);
      return used ? used + " / " + quota : "0B / " + quota;
    }
  },

  methods: {

    async getAdminSettings() {
      let storage = "";
      let quota = 0;
      api.getAdminSettings().then(result => {
          if(result == undefined) return;
          if (result[0].storageSize.indexOf("MB") > 0) {
            storage = result[0].storageSize.substring(0, result[0].storageSize.indexOf("MB"));
            quota = storage * 1024 * 1024;
          } else if (result[0].storageSize.indexOf("GB") > 0) {
            storage = result[0].storageSize.substring(0, result[0].storageSize.indexOf("GB"));
            quota = storage * 1024 * 1024 * 1024;
          } else if (result[0].storageSize.indexOf("TB") > 0) {
            storage = result[0].storageSize.substring(0, result[0].storageSize.indexOf("TB"));
            quota = storage * 1024 * 1024 * 1024 * 1024;
          }
          useSessionStore().rootFolder.quota = quota;
      });
    },
    appStart(url){
      location.href = window.location.origin + url;
      alert("클릭 이벤트 >>"+window.location.href); 
    }
  },
};
</script>

