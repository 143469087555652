import api from './api';
import { useSessionStore } from "./stores/session";
import { L, showToast } from "./utils";
import router from './router';

let rootReloadHandler = null;
let _index =0;
export const handleMessage = (m) => {

  if (m.eventType === 'Share') {

    if(m.data.shareType === 'SHARE'){
      showToast(L('{0} shared {1} with you', m.data.sharedBy, m.data.filename), 'info');
    }else{
      showToast(L('{0} unShared {1} with you', m.data.sharedBy, m.data.filename), 'info');
    }

    const token = useSessionStore().authToken;
    if (token)
      api.restoreLogin(token, handleMessage);

  } else if (m.eventType.startsWith('File')) {

    clearTimeout(rootReloadHandler);
    
    rootReloadHandler = setTimeout(async () => {
      const root = await api.getRootInfo();
      useSessionStore().setRootFolder(root);
    }, 10000);

  } else if(m.eventType === 'DeskTop') {
    router.go(router.currentRoute);
  }
}