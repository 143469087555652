<template>
	<Modal ref="baseModal">
		<div class="share-link-ins">
      <div class="share-link-ins-wrap">
        <h2 class="title">{{L('Create Link')}}</h2>
        <div class="flex-box" style="margin-top: 0.5625rem;">
          <span class="label-text">{{ L('Password') }}</span>
          <div class="inner-field">
            <input type="password" class="field" v-model="password" style="max-width:14.5rem">
          </div>
        </div>
        <div class="flex-box" style="margin-top: 0.5625rem;">
          <span class="label-text">{{ L('Expiration') }}</span>
          <div class="inner-field">
            <div>
              <VueDatePicker v-model="date" :enable-time-picker="false" :format="format" :min-date="new Date()"></VueDatePicker>
            </div>
          </div>
        </div>
        <div class="confirm-buttons">
            <button class="btn btn-gray btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="close">{{L('Cancel')}}</button>
            <button class="btn btn-orange btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="createLink">{{L('Create Link')}}</button>
        </div>
        <a href="#" class="btn-close" @click="close">
          <i class="icon icon-close"></i>
        </a>
      </div>
    </div>
	</Modal>
</template>


<script>
import api from '../../api';
import { ref } from "vue";

import Modal from "@/components/files/utils/Modal.vue";
import { showToast, formatDate, formatDateTime, overwriteConfirm, alert2 } from "../../utils";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "ShareLinkInput",
  emits: ['shareLinkIns'],
  components: {
    Modal, VueDatePicker,
  },
  props:{
    files: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      content:[],
      selected: [],
      shareSelect:[],
      pwd:String,
      expiration:String,
      date: null,
      password: null,
    };
  },

  methods :{
    formatDate, 
    formatDateTime,
    
    show(item){ 
        this.pwd = "";
        this.expiration = "";
        this.$refs.baseModal.open();
    },

    close(){
        this.$refs.baseModal.close();        
        
    },
    
    /* 공유 해제시 호출 스크립트 */  
    async createLink() {
      if(this.password == null || this.date == null){
        alert2(this.L('Password and expiration date are required fields.'), 'Create Link');
        return;
      }

      this.$emit('shareLinkIns', this.files[0], this.password, this.format(this.date));
      this.close();
    },

    format(date){
      
      const year = date.getFullYear();
      let month = "";
      if((date.getMonth() + 1).toString().length == 1) { 
        month = '0'+(date.getMonth() + 1).toString(); 
      }else{ 
        month = (date.getMonth() + 1).toString();
      };

      let day = "";
      if(date.getDate().toString().length == 1) { 
        day = '0'+date.getDate().toString(); 
      }else{ 
        day = date.getDate().toString();
      };

      return `${year}-${month}-${day}`;
    },

  }
};
</script>



