
<template>
	<div class="info-wrap">
		<div class="filename-box">
			<h3 class="filename">{{ this.fileInfo.filename }}</h3>
			<a href="#" class="info-close"><i class="icon icon-close"></i></a>
		</div>
		<div class="image-box">
			<FileIconColumn v-if="fileInfo.id != null" :file="fileInfo" />
		</div>
		<dl class="file-info">
			<dt>{{L('Modified date')}}</dt>
			<dd>{{ formatDate(this.contents.lastModified) }}</dd>
			<dt class="tit">{{L('Location')}}</dt>
			<dd class="cont">{{ this.filePath }}</dd>
		</dl>
		
		<div class="tabs">
			<ul>
				<li class="active"><a href="#activity" class="activity"><i class="icon"></i><span>{{L('Activity')}}</span></a></li>
				<li><a href="#version" class="version"><i class="icon"></i><span>{{L('Versions')}}</span></a></li>
				<li><a href="#share" class="share"><i class="icon"></i><span>{{L('Sharing')}}</span></a></li>
			</ul>
		</div>


		<div class="tabs-content2">
			
			<!-- 활동영역 -->
			<ActivitiesPanel ref="activity" :item="contents" />
			<!-- 활동영역 -->

			<!-- 버전영역 -->
			<VersionsPanel ref="version" :item="contents" />
			<!-- 버전영역 -->

			<!-- 공유영역 -->
			<SharingPanel ref="sharing" :item="contents" />
			<!-- 공유영역 -->

		</div>
	</div>		
</template>

<script>

import api from '../../api';

import { shallowRef } from 'vue';
import { usePreferenceStore } from '../../stores/preference';
import { formatDate, formatDateTime, formatFileSize, showToast } from '../../utils';
import FileIconColumn from '../../components/files/utils/IconColumn.vue';

import ActivitiesPanel from "../../components/panel/ActivitiesPanel.vue";
import VersionsPanel from "../../components/panel/VersionsPanel.vue";
import SharingPanel from "../../components/panel/SharingPanel.vue";


export default {
  setup() {
    const preference = usePreferenceStore();
    return { preference };
  },
  components: { FileIconColumn, ActivitiesPanel, VersionsPanel, SharingPanel},
  data() {
    return {      
      contents: [],
      filePath:null,
      path:null,
      fileInfo: {},
    };
  },

  methods: {
    formatDate,
    show(item) {
	let items =[];
	if(item.length === undefined){
		items = item;
	}else{
		items = item[0];
	}
      	
	this.contents = items;
	this.fileInfo = items;
	const filePathArr = items.path.split("/");      
      	this.path = "";

      	for(var i=0; i < filePathArr.length -1; i++ ){
		if(filePathArr[i] == ''){	
	    		this.path += "/";
		}else{	
	    		this.path += filePathArr[i];
		}
      	}	
	if(this.path === "/") this.path = "";
      	this.filePath = this.path;
     
      

      	document.querySelector('.right-inner-content').classList.add('active');

	//this.$refs.activity.activityInfo(items);
	//this.$refs.version.versionInfo(items);
	//this.$refs.sharing.sharingInfo(items);
	
    },
    
    hide() {

      const _ctxMenuId = document.querySelectorAll('.custom-context-menu');
      _ctxMenuId[0].style.display = "none";
    },

  },
}
</script>