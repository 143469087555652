<template>
	<div class="prefix">
		<!-- <RouterLink  :to="session.login ? '/' : '/login'"><img src="@/assets/img/logo.png" alt=""></RouterLink> -->
     <RouterLink :to="session.login ? '/' : '/login'">
      <!-- <img v-if="!siteImg.value||siteImg.value == null||siteImg.value == ''||siteImg.value == 'null'" id="siteImg" src="@/assets/img/logo.png"  alt=""> -->
      <img id="siteImg" alt="" class="siteLogo"> <!-- edit : 2023.11.10 -->
      <span class="siteTitle"></span>
    </RouterLink>
    
  </div>

	<div class="suffix">
   
		<div class="search-box">
      <form role="search" onsubmit="return false">
      <div class="search-box">
        
			<button type="button" class="btn-search"><i class="icon icon-search"></i></button>
      <input  type="search" name="" :placeholder="L('Please enter your search term.')" @input="search = $event.target.value"
          @keyup.prevent="e => {
            if (e.which === 13) {              
              $router.push({ path:'/search', query: { text: search } });
            }
          }">
		</div></form>
		</div>
		<div class="alarm">
			<!-- <i class="icon icon-bell"></i>
			<span class="count-label" v-if="isShow"></span> 알람 갯수 -->
		</div>
		<div class="profile-pic">
      <img id="profileImg2"
        alt="profileImg"
        @click.prevent="toggleImg"
        :placeholder="L('Logout when click Profile Image')"
        data-test-id="profileImg"
      >      
		</div>
    <div id="toggleImg"  class="toggleImg" >
      <ul>
        <li>&nbsp;</li>
        <li><p><b>{{result.userName + ' (' + result.userId + ') '}}</b> 님이</p> 로그인 하셨습니다.</li>
        <li>&nbsp;</li>
        <li>
          <a href="#logout" class="dropdown-item" aria-expanded="false" @click.prevent="handleSignOut">
            <span class="icon-log-out"></span> {{ L('Logout') }}
          </a>
        </li>
      </ul>
    </div>
	</div> 
</template>

<script>
import { RouterLink } from 'vue-router';

import api from '../api';
import { useSessionStore } from '../stores/session';
import { confirm2 } from '../utils';
import logo from '@/assets/img/logo.png';
import profile from '@/assets/img/default-profile.svg';

export default {
  
  setup() {
    const session = useSessionStore();
    return { session };
  },
  created() {
    
    jQuery.ajax({
          type : 'get',           // 타입 (get, post, put 등등)
          url : '/api/public/adminSettings',           // 요청할 서버url
          async : true,            // 비동기화 여부 (default : true)
          dataType :"json",
          success : function(result) { // 결과 성공 콜백함수 
        
            if(result[0].siteImg !== null && result[0].siteImg !== 'null') {
              this.siteImg = result[0].siteImg;
            }else{
              this.siteImg = logo;
            }
            document.getElementById('siteImg').src = this.siteImg;
          }
    });
    
  },
  data() {
    return {
      search: '',
      result: [],
      isShow: false,
      siteImg:String,
      profileImg:String
    };
  },
  mounted() { 
      this.profile();

  },
  computed: {
    webviewHandler() {
      return window.flutter_inappwebview?.callHandler;
    },
  },
  
  methods: {
    async profile(){
      this.result = await api.getPrivateInfo();
      
      if(this.result.profileImg !== undefined && this.result.profileImg !== null && this.result.profileImg !== 'null') {
        this.profileImg = this.result.profileImg;
      }else{
        this.profileImg = profile;
      }
      
      document.getElementById('profileImg2').src = this.profileImg; 
    },

    toggleSideMenu() {
      document.querySelector("#page-left").classList.toggle("active");
    },
    async handleSignOut() {
      const ok = await confirm2(this.L('Sign out?'), this.L('Logout'), 'question');
      if (ok) {
        api.signOut();
        this.$router.push("/login");
      }
    },
    async handleSearch() {
      this.$router.push({ path:'/search', query: { text: this.search } });
    },
    toggleImg(){
      if(document.querySelector(".toggleImg").style.display == 'flow'){
        document.querySelector(".toggleImg").style.display = 'none';
      }else{
        document.querySelector(".toggleImg").style.display = 'flow';
      }      
    }
},
  components: { RouterLink }
};
</script>

