<template>
    <div id="folder-tree" class="ui-draggable-handle"></div>
    <!-- button type="button" id="button" @click="test">Delete selected node</button -->
</template>

<script>
import api from '../../api';
import { usePreferenceStore } from '../../stores/preference';

import folderOpenIcon from '@/assets/img/ico-aside-folder-open.png';
import folderIcon from '@/assets/img/ico-aside-folder.png';

export default {
  name: 'FileTree',
  emits: ['select'],
  props: {
    folder: {
      String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: null,
    },
    filter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      folderIcon,
      folderOpenIcon,
      itemSelected: this.selected,
    };
  },
  watch: {
    selected(value) {
      this.itemSelected = value;
    },
    items(){
      const sampleSource = this.items[0];
      this.treeMenuControl1(sampleSource);
    }
  },
  setup() {
    const preference = usePreferenceStore();
    return { preference }
  },
  mounted(){   
   //const sampleSource = this.items[0];
   //this.treeMenuControl1(sampleSource);
  },

  methods: {
    invalidPaths(item) {
      return !item.id || !!this.filter.find(path => {
        return item.path === path
          || item.path.startsWith(path + '/') // target is a child of source
          || (path.startsWith(item.path + '/') && path.indexOf('/', item.path.length + 1) < 0) // source is already a child of target
      })
    },
    async handleOpen(item) {
      if (!item.path) return;

      item.open = !item.open
      if (item.loaded) return;

      if (item.open) {
        item.children = await api.listFolders(item.path || '');
        item.loaded = true;
      }
    },
    handleSelect(item) {
      if (!item.open)
        this.handleOpen(item);

      this.itemSelected = item;
      this.$emit('select', item);
    },
    treeMenuControl1(sampleSource){
      $(function(){
       
      
          $("#folder-tree").fancytree({
          checkbox: false,
            source: sampleSource,
            
            lazyLoad: function(event, data) {
              data.result = { url: "sample1.json" };
            },
              activate: function(event, data){}
          });
      });
      
    },
  },
}
</script>
 