import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Keys } from '../const';
import storage from './persistentStorage';
import api from '../api';

export const usePreferenceStore = defineStore('preference', () => {
  // 언어 설정
  const lang = ref('ko');

  async function setLang(newLang) {
    lang.value = newLang;
    await storage.set(Keys.LANG, newLang);
  }

  // 레이아웃 설정
  const layout = ref('table');

  async function setLayout(v) {
    layout.value = v;
    await storage.set(Keys.LAYOUT, v);
  }

  // 상세 패널 열림 여부
  const detailOpen = ref(false);

  async function setDetailOpen(v) {
    detailOpen.value = v;
    await storage.set(Keys.DETAIL_OPEN, v);
  }

  // 현재 열린 파일 상세 탭
  const detailTab = ref('');

  function setDetailTab(v) {
    detailTab.value = v;
  }

  return { lang, setLang, layout, setLayout, detailOpen, setDetailOpen, detailTab, setDetailTab }
})
