<template>
  <div ref="fileDropPad" class="file-drop-pad fs-4" @drop.prevent="handleDrop" @dragleave.prevent="handleDragLeave">
    <div>
      <strong>{{ L('Drag files here to upload') }}</strong>
    </div>
  </div>

  <!-- <div v-if="uploadPercentage" class="modal progress-modal fade show">  -->
  <div v-if="uploadPercentage" class="modal progress-modal fade show" style="background-color:#fff;">
    <div class="modal-bg"></div>
    <div class="modal-dialog upload">
      <div class="modal-content">
        <div class="modal-body">

          <div style="display:flex;margin:1rem;">
            <div class="progress progress-popup" style="flex:1">
              <span class="progress-txt"> {{ `${this.uploadPercentage}% / 100%` }}</span>
              <div class="progress-bar progress-bar-info progress-bar-striped active"
                  :aria-valuemax="100"
                  :aria-valuemin="0"
                  :aria-valuenow="uploadPercentage"
                  :style="`width:${this.uploadPercentage}%;`">                
              </div>
            </div>
            <button type="button" style="margin-left:1rem;height:2.5rem;padding:0 1rem;" class="btn btn-warning btn-sm ms-2" :disabled="uploadLeft < 100 * 1024" @click="abortUpload">{{ L('Abort') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input type="file" ref="fileInput" class="d-none" multiple
    accept=".docx,.doc,.xlsx,.xls,.pptx,.ppt,.hwp,.pdf,.png,.jpg,.gif,.zip,.7z"
    @change="uploadFiles($event.target.files)">
  <input type="file" ref="folderInput" class="d-none" multiple webkitdirectory
    @change="uploadFiles($event.target.files)">

  <div
    v-if="prevUploads?.files?.length"
    id="prevUploads"
    class="prev-uploads accordion shadow"
  >
    <div class="queued-item">
      <h3 class="queued-header">
          {{ L('Queued uploads') }}
          <button id="hiddenUploads" style="width:3.3rem;margin-left: 7rem;font-size:1.1rem;border-radius:.425em;" @click="hiddenUpload(hiddenYn)">{{ L('Hide') }}</button>
      </h3>
      
      <div id="prev-uploads-body" class="queued-contents" data-bs-parent="#prevUploads">
        <div class="queued-body">
          <ul style="flex:1">
            <li v-for="(f, idx) in prevUploads.files" :key="idx">
              {{ f.name }}
            </li>
          </ul>
          <div class="btn-right">
            <button type="button" class="btn" style="font-size: 1.2rem;" @click="resumeUpload">{{ L('Resume') }}</button>
            <button type="button" class="btn" style="font-size: 1.2rem;" @click="clearUpload">{{ L('Clear') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="exchange-confirm" v-show="isShow"> 
    <div class="exchange-confirm-wrap">
      <i class="icon icon-exclamation-mark"></i>
        <span>{{ L('A file or folder with the same name already exists.') }}</span>
        <table class="table th-gray exchange-content-table">
          <tbody>
            <colgroup>
              <col width="40%">
              <col width="60%">
            </colgroup>
            <tr>
                <th>{{ L('Duplicate item') }}</th>
                <td>{{ L('Including {0}, {1} items are duplicated', filename, trueCount) }}</td>
            </tr>
            <tr>
                <th>{{ L('Update CurrentTime') }}</th>
              <td>{{ getCurrentTime() }}</td>
            </tr>
            <tr>
                <th>{{ L('Existing') }}</th>
              <td>{{ lastModifiedDate }}</td>
            </tr>
          </tbody>
        </table>
      <div class="confirm-buttons">
          <button class="btn btn-gray btn-round btn-large" @click="isShow = false">{{ L('Cancel') }}</button>
          <button class="btn btn-orange-outline btn-round btn-large" @click="handleKeep">{{ L('Skip') }}</button>
          <button class="btn btn-orange btn-round btn-large" @click="handleOverwrite">{{ L('Overwrite') }}</button>
      </div>
      <a href="#" class="close-confirm">
        <i class="icon icon-close" @click="isShow = false"></i>
      </a>
    </div>
  </div>

</template>

<script>
import api from '../../api';
import { MAX_FILENAME_LENGTH, MAX_PATH_LENGTH } from '../../const';
import { useSessionStore } from "../../stores/session";
import { alert2, confirm2, formatFileSize, hideLoader, showLoader, showToast } from '../../utils';

export default {
  emits: ['upload'],
  props: {
    path: {
      type: String,
      default: '',
    },
    dragUploadYn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { session: useSessionStore() }
  },
  data() {
    return {
      uploadPercentage: 0,
      uploadLeft: 0,
      prevUploads: null,
      isShow: false,
      answer: '',
      resolveAnswer: null,
      filename: '',
      trueCount: '',
      lastModifiedDate: '',
      hiddenYn:false,
    }
  },
  async mounted() {
    document.documentElement.addEventListener('dragover', this.handleDragOver);

    const dbInit = await this.dbInit();

    if (dbInit) {
      this.prevUploads = await this.dbTx('get', 'uploads');
    }

    api.addSseListener('uploadHandler', (m) => this.handleBrokenUpload(m));
  },
  unmounted() {
    document.documentElement.removeEventListener('dragover', this.handleDragOver);
    api.removeSseListener('uploadHandler');
  },
  methods: {
    handleDragOver(e) {
      e.preventDefault();

      let files;
      if (e.dataTransfer.items) {
        files = [...e.dataTransfer.items]
          .filter(item => item.kind === 'file');
      } else {
        files = e.dataTransfer.files;
      }

      if (files.length)
        this.$refs.fileDropPad.style.display = 'flex';
    },

    handleDragLeave(e) {
      e.preventDefault();
      this.$refs.fileDropPad.style.display = 'none';
    },

    async handleDrop(e) {
      this.$refs.fileDropPad.style.display = 'none';

      let files;
      if (e.dataTransfer.items) {
        files = [...e.dataTransfer.items]
          .filter(item => item.kind === 'file')
          .map(item => item.webkitGetAsEntry() || item.getAsFile());
      } else {
        files = [...e.dataTransfer.files];
      }

      if (!files.length) return;

      const list = []
      await this.gatherFiles('', files, list);

      await this.uploadFiles(list);
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    openFolderInput() {
      this.$refs.folderInput.click();
    },

    async gatherFiles(parent, files, list) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].isFile) {
          const f = await new Promise(resolve => files[i].file(resolve));
          f.path = parent + '/' + f.name;
          list.push(f);
        } else if (files[i].isDirectory) {
          await new Promise(resolve =>
            files[i].createReader().readEntries(async (entries) => {
              await this.gatherFiles(parent + '/' + files[i].name, entries, list);
              resolve();
            })
          );
        }
      }
    },

    async uploadFiles(files, partial) {
      if (!files.length)
        return;

      const rootInfo = await api.getRootInfo();
      if (rootInfo.size >= rootInfo.quota) {
        alert2(this.L('You have reached your storage quota'), this.L('Storage full'), 'warning');
        return;
      }

      files = [...files];
      for (let i = 0; i < files.length; ++i) {
        if (files[i].path) { // 경로 정보를 구한 경우
          files[i].path = this.path + files[i].path;
        } else {
          const path = files[i].webkitRelativePath || files[i].name;
          files[i].path = this.path + (path.startsWith('/') ? path : '/' + path);
        }

        if (files[i].path.length > MAX_PATH_LENGTH || files[i].name.length > MAX_FILENAME_LENGTH) {
          alert2(this.L('Upload path too long. (Path max: {0}, filename max: {1})', MAX_PATH_LENGTH, MAX_FILENAME_LENGTH));
          return;
        }

        files[i].mtime = files[i].lastModified;
      }

      // 파일 데이터를 모두 꺼낸 후 input 초기화
      this.$refs.fileInput.value = '';
      this.$refs.folderInput.value = '';

      // 덮어쓰기 여부 확인
      // let filename = "";
      // let count = await api.exist(files.map(f => f.path)).then((res) => {
      //   res.forEach((t, idx) => { if(t === true && filename === "") filename = files[idx].name; });
      //   return res.filter(t => t === true).length;
      // });

      let lastModifiedDate = "";  
      let filename = "";

      let count = await api.exist(files.map(f => f.path)).then((res) => {
          res.forEach((t, idx) => { 
            if(t === true && filename === "") {
              filename = files[idx].name; 
              lastModifiedDate = this.formatDate(files[idx].lastModified);
            }
          });
            
          const trueCount = res.filter(t => t === true).length;

          this.filename = filename;
          this.trueCount = trueCount;
          this.lastModifiedDate = lastModifiedDate;
          
          return trueCount;
        });

      let overwrite = true;
      if (count > 0) {

        this.isShow = true;

        const answer = await new Promise((resolve) => {
          this.resolveAnswer = resolve;
        });

        overwrite = answer === 'replace';
      }
      
      const data = new FormData();
      data.append('overwrite', overwrite);
      files.forEach((f, i) => {
        data.append('file', i == 0 && partial?.size ? f.slice(partial.size) : f, f.path);
        data.append('path', f.path);
        data.append('dlp', 'true');
      });

      this.uploads = {
        files: [...files],
        paths: files.map(f => f.path),
        overwrite
      };

      // 업로드 처리 시작
      this.isShow = false;
      this.uploadPercentage = 0.1;
       
      const result = await api.upload(data, (e) => {
        const partialSize = partial?.size || 0;
        const total = e.total + partialSize;
        const percent = (e.loaded + partialSize) / total * 100;
        this.uploadPercentage = Math.round(percent);
        this.uploadLeft = e.total - e.loaded;
      }, partial ? { 'x-partial-upload-size': partial.size, 'x-partial-upload-hash': partial.hash } : null);

      if (result) {
        const failMessage = result.find(f => f.error)?.message;

        showToast(this.L(failMessage ? '하나 이상 파일 업로드 실패: ' + failMessage : 'File uploaded'), failMessage ? 'error' : 'success');
        this.$emit('upload', result.filter(f => !f.error), overwrite);
        this.dbTx('delete', 'uploads');
        this.prevUploads = {};
      }

      this.uploadPercentage = 0;
      
    },

    async handleBrokenUpload(m) {
      if (m.eventType === 'PARTIAL_UPLOAD' && this.uploads?.files?.length) {
        this.$emit('upload', m.data.uploaded, this.uploads.overwrite);

        let serverPartial = await api.getPartialUpload();

        if (serverPartial?.size && serverPartial?.hash) {
          const count = m.data.uploaded.length;
          const hash = await this.getFileHash(this.uploads.files[count], serverPartial.size);

          if (hash === serverPartial.hash) {
            this.uploads.files = this.uploads.files.slice(count);
            this.uploads.paths = this.uploads.paths.slice(count);
          }
        }

        this.prevUploads = this.uploads;
        this.dbTx('put', this.uploads, 'uploads');
      }
    },

    async dbInit() {
      if (!window.indexedDB) return false;

      const request = window.indexedDB.open('drive-files');
      const createObjectStore = db => db.createObjectStore('files');

      return new Promise((resolve, reject) => {
        request.onerror = reject;
        request.onsuccess = async event => {
          this.db = event.target.result;
          resolve(this.db);
        };
        request.onupgradeneeded = event => {
          this.db = event.target.result;
          createObjectStore(this.db);
        };
      });
    },

    async dbTx(method, key, value) {
      return new Promise((resolve, reject) => {
        const tx = this.db.transaction(['files'], 'readwrite');
        const req = tx.objectStore('files')[method](key, value);

        req.onsuccess = event => resolve(event.target.result);
        req.onerror = event => reject(event);
      });
    },

    abortUpload() {
      api.abortUpload();

      alert2(this.L('Please check from Queued uploads view.'), this.L('Upload interrupted'), 'warning');
    },

    async getFileHash(file, size) {
      if (!file) return null;

      const partial = await file.slice(0, size).arrayBuffer();
      const hash = await crypto.subtle.digest('SHA-256', partial);
      return [...new Uint8Array(hash)].map(x => x.toString(16).padStart(2, '0')).join('');
    },

    async resumeUpload() {
      const uploads = this.prevUploads;
      if (!uploads?.files?.length) return;

      showLoader();
      let serverPartial = await api.getPartialUpload();
      if (!serverPartial.size || !serverPartial.hash)
        serverPartial = null;
      else {
        const hash = await this.getFileHash(uploads.files[0], serverPartial.size);

        if (hash !== serverPartial.hash)
          serverPartial = null;
      }
      hideLoader();

      const total = uploads.files.reduce((sum, f) => sum += f.size, 0);
      const ok = await confirm2(
        serverPartial ? this.L('{0} of {1} is left', formatFileSize(total - serverPartial.size), formatFileSize(total)) : '',
        this.L('Resume uploading {0} file(s)?', uploads.files.length),
        'question'
      );
      if (!ok) return;

      const files = uploads.files;
      files.forEach((f, i) => f.path = uploads.paths[i].slice(this.path.length));
      
      this.uploadFiles(files, serverPartial);
    },

    async clearUpload() {
      const ok = await confirm2('', this.L('Clear unfinished uploads?'), 'question');
      if (!ok) return;

      this.dbTx('delete', 'uploads');
      this.prevUploads = null;
    },

    handleOverwrite() {
      this.resolveAnswer('replace');
    },
    handleKeep() {
      this.resolveAnswer('keep');
    },

    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      const time = `${year}.${month}.${day} ${hour}:${minute}`;
      return time;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}.${month}.${day}. ${hour}:${minute}`;
      return formattedDate;
    },

    hiddenUpload(hiddenYn){
      if(hiddenYn){
        document.querySelectorAll('.queued-contents')[0].style.display='';
        document.getElementById('hiddenUploads').innerText = this.L('Hide');
        this.hiddenYn = false;
      }else{
        document.querySelectorAll('.queued-contents')[0].style.display='none';
        document.getElementById('hiddenUploads').innerText = this.L('Expose');
        this.hiddenYn = true;
      }
    }
  },
}
</script>

<style scoped>
.file-drop-pad {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  border: 6px dashed white;
}

.file-drop-pad * { pointer-events: none; }


element.style {
    width: 50%;
}
@-webkit-keyframes f {
	0% {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}

@keyframes f {
	0% {
		background-position: 40px 0;
	}

	to {
		background-position: 0 0;
	}
}
.progress {
	overflow: hidden;
	height: 2.5rem;
  text-align: center;
	border-radius: 4px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
	height: 100%;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.progress-bar-striped,
.progress-striped .progress-bar {
	background-image: linear-gradient(
		45deg,
		hsla(0, 0%, 95%, 0.8) 25%,
		transparent 0,
		transparent 50%,
		hsla(0, 0%, 95%, 0.8) 0,
		hsla(0, 0%, 95%, 0.8) 75%,
		transparent 0,
		transparent
	);
	background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {
	-webkit-animation: f 2s linear infinite;
	animation: f 2s linear infinite;
}
.progress-bar-info {
	background-color: #999;    
}
.progress-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.2rem;
  line-height: 2;
  font-weight: bold;
}

.progress-striped .progress-bar-info {
	background-image: linear-gradient(
		45deg,
		hsla(0, 0%, 95%, 0.8) 25%,
		transparent 0,
		transparent 50%,
		hsla(0, 0%, 95%, 0.8) 0,
		hsla(0, 0%, 95%, 0.8) 75%,
		transparent 0,
		transparent
	);
}
.modal.progress-modal {
  display: block !important;
  position:absolute;
  z-index: 999999999;
  top: 30%;
  left: 30%;
  width: 700px;
}
.progress-modal .modal-bg {
  /* position: absolute; */
  z-index: 999999999;
  width:100%;
  height: 100%;
  background-color:white;
  opacity: 0.3;
}
.progress-modal .modal-dialog.upload {
  top: 30%;
}
.progress-modal .modal-content {
  border: solid 1px !important;
}
.progress-modal .progress-popup {
  /* height:1.5rem; */
}

.prev-uploads {
  position: fixed; 
  right: 2rem; 
  bottom: .5rem; 
  width: 100%; 
  max-width: 360px;
  z-index: 101;
}
.prev-uploads .accordion-body {
  min-height: 200px; overflow-y: auto;
}
.queued-item {
  padding: 0.625rem;
  border-radius: 0.625rem;
  background-color: #999;
}
.queued-header {
  position: relative;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.queued-header:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #eee;
}
.queued-body ul {
  overflow-y: auto;
  height: 5rem;
}
.queued-body li {
  line-height: 1.2;
}
.btn-right {
  text-align: right;
}
.btn-right .btn {
  margin-left: 0.25rem;
}

@media(max-width: 767px) {
    .modal.progress-modal {   
    width: 90% !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .progress-txt {
    font-size: .8125rem;
  }
  .btn-right .btn {
    padding: 0 0.625rem;
  }
}
</style>