<template>
	<Modal ref="baseModal">
		<div class="exchange-confirm folder-select">
			<div class="exchange-confirm-wrap">
				<span>{{L('Select a folder')}}</span>
				<div class="folder-tree">
            <!-- <FileTree :items="treeItems" :filter="filter" :selected="selected" @select="handleSelect" /> -->
            <FileTree :items="treeItems" :filter="filter"/>
				</div>
				<div class="confirm-buttons">
					<button class="btn btn-gray btn-round btn-large" @click="close">{{L('Cancel')}}</button>
					<button class="btn btn-orange btn-round btn-large" @click="handleOk">{{L('Save')}}</button>
				</div>
				<a href="#" class="close-confirm" @click="close"><i class="icon icon-close"></i></a>
			</div>
		</div>
	</Modal>

  <div class="exchange-confirm" v-show="isShow" style="z-index: 1000;"> 
    <div class="exchange-confirm-wrap">
      <i class="icon icon-exclamation-mark"></i>
      <span>{{ L('A file or folder with the same name already exists.') }}</span>
      <table class="table th-gray exchange-content-table">
        <tbody>
          <colgroup>
            <col width="35%">
            <col width="65%">
          </colgroup>
          <tr>
            <th>{{ L('Duplicate item') }}</th>
            <td>{{ L('Including {0}, {1} items are duplicated', filename, trueCount) }}</td>
          </tr>
          <tr>
            <th>{{ L('Update CurrentTime') }}</th>
            <td>{{ getCurrentTime() }}</td>
          </tr>
          <tr>
            <th>{{ L('Existing') }}</th>
            <td>{{ lastModifiedDate }}</td>
          </tr>
        </tbody>
      </table>
      <div class="confirm-buttons">
        <button class="btn btn-gray btn-round btn-large" @click="isShow = false">{{ L('Cancel') }}</button>
        <button class="btn btn-orange-outline btn-round btn-large" @click="handleKeep">{{ L('Skip') }}</button>
        <button class="btn btn-orange btn-round btn-large" @click="handleOverwrite">{{ L('Overwrite') }}</button>
      </div>
      <a href="#" class="close-confirm">
        <i class="icon icon-close" @click="isShow = false"></i>
      </a>
    </div>
  </div>

</template>


<script>
import api from '../../api';

import Modal from "@/components/files/utils/Modal.vue";
import FileTree from '../files/FileTree.vue';

export default {
  name: "FolderModal",
  emits: ['select','close'],
  components: {
    Modal , FileTree
  },
  props:{
    filter: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      treeItems: [],
      cont:[],
      cont2:[],
      selected: null,
      treeItems2:null,
      isShow: false,
      answer: '',
      resolveAnswer: null,
      filename: '',
      trueCount: 0,
      lastModifiedDate: '',
    };
  },
  watch: {
    filter() {
     this.load();
    }
  },
  mounted() {
    //this.load();
  },

  methods :{
    async load() {
      this.cont =[];
      this.cont2 =[];
      this.treeItems =[];
      
      const folders = await api.listFolders2('');
      
      this.treeItems = folders;
      this.treeItems[0].children[0].path = "";
      this.selected = null;
    },

    show(){
        this.load();       
        this.$refs.baseModal.open();
    },

    close(){
      this.$emit('close');
      this.$refs.baseModal.close();
    },
    async handleOk() {


      var tree = $("#folder-tree").fancytree("getTree"),
            node = tree.getActiveNode();
      
            // var children = node.children;
            // if (children!==null) node.parent.addChildren(children,node.getNextSibling());

      this.selected = node.data;
     
      let paths = this.files.map(t => this.selected.path + '/' + t.filename);

      // let __path = this.files.map((t) =>{
          
      //     const k = {};
      //     k["userId"] = this.selected.userId;
      //     k["path"] = this.selected.path + '/' + t.filename;

      //     return k;
      // });

      let filename = "";
      let lastModifiedDate = "";
          
      let count = await api.exist(paths).then((res) => {
        res.forEach((t, idx) => {
          if(t === true && filename === "") {
            filename = paths[idx].split('/').pop(); 
            lastModifiedDate = this.formatDate(this.files[idx].lastModified);
          }
        });
        const trueCount = res.filter(t => t === true).length;
        
        this.filename = filename;
        this.trueCount = trueCount;
        this.lastModifiedDate = lastModifiedDate;
          
        return res.filter(t => t === true).length;
      })
      let overwrite = true;
      if (count > 0) {
        this.isShow = true;
        
        const answer = await new Promise((resolve) => {
          this.resolveAnswer = resolve;
        });
        if (!answer) return;
        
        overwrite = answer === 'replace';
        
        this.isShow = false;
      }
      
      this.$emit('select', this.selected, overwrite);
      this.close();
    },
    test(){ 
      var tree = $("#folder-tree").fancytree("getTree"),
                node = tree.getActiveNode();
      
             var children = node.children;
             if (children!==null)node.parent.addChildren(children,node.getNextSibling());
             node.remove();
    },

    handleOverwrite() {
      this.resolveAnswer('replace');
    },
    handleKeep() {
      this.resolveAnswer('keep');
    },

    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hour = String(now.getHours()).padStart(2, '0');
      const minute = String(now.getMinutes()).padStart(2, '0');
      const time = `${year}.${month}.${day} ${hour}:${minute}`;
      return time;
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}.${month}.${day}. ${hour}:${minute}`;
      return formattedDate;
    },

  }
};
</script>



