<template>
    <img :src="icon" :alt="this.file.filename" @dragstart.prevent="false" class="fileImg"> 
</template>

<script>

import z7 from '@/assets/img/file-icon/ico-file-7z.png';
import avi from '@/assets/img/file-icon/ico-file-avi.png';
import bmp from '@/assets/img/file-icon/ico-file-bmp.png';
import doc from '@/assets/img/file-icon/ico-file-doc.png';
import etc from '@/assets/img/file-icon/ico-file-etc.png';
import folder from '@/assets/img/file-icon/ico-file-folder.png';
import folderShared from '@/assets/img/file-icon/ico-file-folder-shared.png';
import gif from '@/assets/img/file-icon/ico-file-gif.png';
import html from '@/assets/img/file-icon/ico-file-html.png';
import hwp from '@/assets/img/file-icon/ico-file-hwp.png';
import jpg from '@/assets/img/file-icon/ico-file-jpg.png';
import mov from '@/assets/img/file-icon/ico-file-mov.png';
import mp4 from '@/assets/img/file-icon/ico-file-mp4.png';
import pdf from '@/assets/img/file-icon/ico-file-pdf.png';
import png from '@/assets/img/file-icon/ico-file-png.png';
import ppt from '@/assets/img/file-icon/ico-file-ppt.png';
import psd from '@/assets/img/file-icon/ico-file-psd.png';
import svg from '@/assets/img/file-icon/ico-file-svg.png';
import tar from '@/assets/img/file-icon/ico-file-tar.png';
import txt from '@/assets/img/file-icon/ico-file-txt.png';
import wmv from '@/assets/img/file-icon/ico-file-wmv.png';
import xls from '@/assets/img/file-icon/ico-file-xls.png';
import zip from '@/assets/img/file-icon/ico-file-zip.png';
import z7Locked from '@/assets/img/file-icon/ico-file-7z-locked.png';
import aviLocked from '@/assets/img/file-icon/ico-file-avi-locked.png';
import bmpLocked from '@/assets/img/file-icon/ico-file-bmp-locked.png';
import docLocked from '@/assets/img/file-icon/ico-file-doc-locked.png';
import etcLocked from '@/assets/img/file-icon/ico-file-etc-locked.png';
import folderLocked from '@/assets/img/file-icon/ico-file-folder-locked.png';
import folderSharedLocked from '@/assets/img/file-icon/ico-file-folder-shared-locked.png';
import gifLocked from '@/assets/img/file-icon/ico-file-gif-locked.png';
import htmlLocked from '@/assets/img/file-icon/ico-file-html-locked.png';
import hwpLocked from '@/assets/img/file-icon/ico-file-hwp-locked.png';
import jpgLocked from '@/assets/img/file-icon/ico-file-jpg-locked.png';
import movLocked from '@/assets/img/file-icon/ico-file-mov-locked.png';
import mp4Locked from '@/assets/img/file-icon/ico-file-mp4-locked.png';
import pdfLocked from '@/assets/img/file-icon/ico-file-pdf-locked.png';
import pngLocked from '@/assets/img/file-icon/ico-file-png-locked.png';
import pptLocked from '@/assets/img/file-icon/ico-file-ppt-locked.png';
import psdLocked from '@/assets/img/file-icon/ico-file-psd-locked.png';
import svgLocked from '@/assets/img/file-icon/ico-file-svg-locked.png';
import tarLocked from '@/assets/img/file-icon/ico-file-tar-locked.png';
import txtLocked from '@/assets/img/file-icon/ico-file-txt-locked.png';
import wmvLocked from '@/assets/img/file-icon/ico-file-wmv-locked.png';
import xlsLocked from '@/assets/img/file-icon/ico-file-xls-locked.png';
import zipLocked from '@/assets/img/file-icon/ico-file-zip-locked.png';

const fileIcons = {
                  z7,avi,bmp,doc,etc,folder,folderShared,gif,html,hwp,jpg,mov,mp4,pdf,png,ppt,psd,svg,tar,txt,wmv,xls,zip,
                  z7Locked,aviLocked,bmpLocked,docLocked,etcLocked,folderLocked,folderSharedLocked,gifLocked,htmlLocked,hwpLocked,
                  jpgLocked,movLocked,mp4Locked,pdfLocked,pngLocked,pptLocked,psdLocked,svgLocked,tarLocked,txtLocked,wmvLocked,
                  xlsLocked,zipLocked,
};

export default {
  CLASS_NAME: 'icon-column',
  props: {
    file: { type: Object, required: true },
    selected: { type: Boolean, default: false },
  },
  computed: {
    icon() {
      if (this.file?.isFolder || this.file?.contentType === 'FOLDER') {        

        if( this.file.fileLock){
          if(this.file?.sharedOut){
            return folderSharedLocked;
          }else{  
            return folderLocked;
          }
        } else{
          if(this.file?.sharedOut){
            return folderShared;
          }else{  
            return folder;
          }
        }        
      }

      let icon = etc;
      switch (this.ext) {
        case 'avi' : case 'bmp' : case 'gif' : case 'html' : case 'hwp' : case 'mov' : case 'mp4' : case 'pdf' : case 'png' : case 'psd' : case 'svg' : case 'tar' : case 'txt' : case 'wmv' : case 'zip' :
          if( this.file.fileLock){
            icon = fileIcons[this.ext+'Locked'];
          } else{
            icon = fileIcons[this.ext];
          }          
          break;
        
        case '7z' :
          if( this.file.fileLock){
            icon = z7Locked;
          } else{
            icon = z7;
          }          
          break;

        case 'doc' : case 'docx' :
          if( this.file.fileLock){
            icon = docLocked;
          } else{
            icon = doc;
          }          
          break;
        case 'jpg' : case 'jpeg' :
          if( this.file.fileLock){
            icon = jpgLocked;
          } else{
            icon = jpg;
          }          
          break;
        case 'ppt' : case 'pptx':
          if( this.file.fileLock){
            icon = pptLocked;
          } else{
            icon = ppt;
          }          
          break;
        case 'xls' : case 'xlsx':
          if( this.file.fileLock){
            icon = xlsLocked;
          } else{
            icon = xls;
          }          
          break;

        default :
          if( this.file.fileLock){
            icon = etcLocked;
          } else{
            icon = etc;
          }
      }
      return icon;
    },
    ext() {
 
      return this.file?.filename.split('.').pop().toLowerCase();
    },
  },
};
</script>