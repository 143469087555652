import { isMobile } from 'mobile-device-detect';
// import { isSafari } from 'mobile-device-detect';
// import { isIOS } from 'mobile-device-detect';

export const IS_MOBILE = isMobile; // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const FILE_LIST_SIZE = 100;

export const PAGE_COUNT_SIZE = 5;

export const INVALID_FILENAME_CHARS = /[/\\:*?"<>|^']/g;

export const MAX_FILENAME_LENGTH = 250;

export const MAX_PATH_LENGTH = 500;

export const TOAST_TIMEOUT = 3000;

export const Keys = {
  AUTH_TOKEN: 'drive.authToken',
  L10N: 'drive.l10n',
  LANG: 'drive.lang',
  LAST_LOGIN: 'drive.lastLogin',
  LAYOUT: 'drive.layout',
  DETAIL_OPEN: 'drive.detailOpen',
}
