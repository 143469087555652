<template>
	<Modal ref="baseModal">

    <div class="share-link-ins">
      <div class="share-link-ins-wrap">
        <h2 class="title">{{L('Link Info')}}</h2>
        <div class="flex-box" style="margin-top: 1rem;min-height: 3rem;">
          <span class="label-text">{{ L('Password') }}</span>
          <div class="inner-field">
            {{isTrue}}
            <img src="@/assets/img/ico-pw-hide.png" id="hide" :title="'hide'" @click="showClick"/> 
            <img src="@/assets/img/ico-pw-show.png" id="show" :title="'show'" style="display: none;" @click="hideClick"/> 
          </div>
        </div>
        <div class="flex-box" style="min-height: 2rem;">
          <span class="label-text">{{ L('Link Copy') }}</span>
          <div class="inner-field">
            <img src="@/assets/img/ico-sharer.png" alt="linkUrl" id="linkUrl" :title= "result.linkShareUrl" @click="linkUrl" />
          </div>
        </div>
        <div class="confirm-buttons" style="text-align:center;">
              <button class="btn btn-orange btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="close">{{L('Close')}}</button>
        </div>
        <a href="#" class="btn-close" @click="close">
          <i class="icon icon-close"></i>
        </a>
      </div>
    </div>
	</Modal>
</template>


<script>
import api from '../../api';
import { ref } from "vue";

import Modal from "@/components/files/utils/Modal.vue";
import { L, alert2, showToast, formatDate, formatDateTime, overwriteConfirm } from "../../utils";
import profileImg from '@/assets/img/default-profile.svg';

export default {
  name: "FolderModal",
  emits: ['select','close'],
  components: {
    Modal,
  },
  props:{
    files: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      result: [],
      isTrue: '******',
    };
  },

  methods :{
    
    show(item){ 
        this.$refs.baseModal.open();
        this.result = [item];      
    },

    showClick(){
      this.isTrue = this.result[0].password;
      document.getElementById('hide').style.display="none";
      document.getElementById('show').style.display="";
    },

    hideClick(){      
      this.isTrue = '******';
      document.getElementById('hide').style.display="";
      document.getElementById('show').style.display="none";
    },
    
    linkUrl(){
      navigator.clipboard.writeText(this.result[0].linkShareUrl).then(() => { 
         showToast(this.L('Link copied to clipboard'), 'success');
      });
    },

    close(){
        this.$refs.baseModal.close();        
        
    },
  }
};
</script>



