<template>
  <FileHeader ref="fileHeader" @selectionBoxReset="selectionBoxReset" @command="headerHandleCommand" @shareReset="shareReset"  @sort="onSort" :page="page"
              :path="$route.query.path" :FileHeaderTitle="FileHeaderTitle" :FileHeaderDisplay="FileHeaderDisplay" 
              :item="this.selected" :starredYn="starredYn" :selectionType="selectionType" :RecycleYn="RecycleYn" @allCheck="allCheck" 
              :shareYn ="shareYn" :deleteYn ="deleteYn" :etcYn ="etcYn" :upload ="upload" :newFolder ="newFolder" :newDoc ="newDoc" :download ="download" :deletedShareYn ="deletedShareYn"></FileHeader>
  <div class="right-inner-content"> 
    <div class="inner-content-wrap" @scroll="handleScroll">
      <div class="inner-main" ref="innerMain">

        <div v-if="preference.layout === 'table'" class="list list-type" ref="container">
          
          <table class="table" ref="listTable"> 
            <thead class="table-thead">
              <tr>
                <th v-for ="col in columns" :key="col.field" :width="col.width">
                  <template v-if="col.field === 'chk'">
                    <div v-if="RecycleYn != 'Y'" class="checkbox">
                      <input ref="checkAll" type="checkbox" class="chk_all" id="chk_all" @click="allCheck($event)"/>
                      <label for="chk_all"></label>
                    </div>
                    <div v-else class="checkbox trash-all-check">
                      <input ref="checkAll" type="checkbox" class="chk_all" id="chk_all" @click="allCheck($event)"/>
                      <label for="chk_all"></label>
                    </div>
                  </template>  
                  <template v-else>
                    <div class="table-header" >
                      <div v-if="col.sort" class="sortable">
                        <template v-if="this.sort.split(',')[0] === col.field && this.sort.split(',')[1] === 'desc' ">
                          <span @click="onSort(col.field, 'asc')" style="cursor:pointer;">{{ L(col.label) }} ▼ </span>
                        </template>
                        <template v-else-if="this.sort.split(',')[0] === col.field && this.sort.split(',')[1] === 'asc' ">
                          <span @click="onSort(col.field, 'desc')" style="cursor:pointer;">{{ L(col.label) }} ▲</span>
                        </template>
                        <template v-else>
                          <span @click="onSort(col.field, 'desc')" style="cursor:pointer;">{{ L(col.label) }} △</span>
                        </template>
                      </div>
                      <div v-else>
                        <span>{{ L(col.label) }} </span>
                      </div>
                    </div>
                  </template>  
                </th>
              </tr>
            </thead>

            <tbody>
            <template v-if="content?.length > 0">  
              <tr v-for="(item, index) in content" :key="item.id" class="itemList"
                  @click ="handleClickItem($event, item, index)"
                  @dblclick="$emit('open', item)"
                  @contextmenu.prevent="handleContextMenu($event, item)"
                  :style="{backgroundColor: $route.query.fileId == item.id ? '#FFFF00' : ''}"    
                >
                <td v-for ="col in columns" :key="col.field" :class="[col.field === 'filename'? 'item' : 'item text-center']" :id="col.field" >     
                  <template v-if="col.field === 'chk'">
                    <div class="checkbox">
                      <input type="checkbox" name="idx[]" class="chk" :id="'chk'+(index)" :value="index" alt="checkbox"/>
                      <label :for="'chk'+(index)"></label>
                    </div>
                  </template> 
                  <template v-else-if="col.field === 'starred'">
                    <div class="favorites">
                      <template v-if="item.starred === true">
                        <input type="checkbox" name="favorites[]" :id="item.id" value="on" checked>
                      </template>
                      <template v-else>
                        <input type="checkbox" name="favorites[]" :id="item.id" value="on">
                      </template>
                      <label :for="'favorites'+(index)"></label>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="col.field === 'icon'">
                        <div class="file-type">
                        <component  :is="columns.find(elem => (elem.field === col.field)).component" :file="item" 
                                    :field="columns.find(elem => (elem.field === col.field)).field" />
                                  </div>
                    </template>
                    <template v-else>                     
                        <template v-if ="columns.find(elem => (elem.field === col.field)).component != ''">
                          <component :is="columns.find(elem => (elem.field === col.field)).component" :file="item" 
                                     :field="columns.find(elem => (elem.field === col.field)).field" />
                        </template>
                        <template v-else>
                          {{ item[col.field] }}
                        </template>
                    </template>
                  </template>
                </td>
              </tr>
            </template>
            <template v-else-if="content">
              <tr><td :colspan="columns.length" class="item text-center"> {{ L('File not found') }}</td></tr>
            </template>
            </tbody>
          </table>                
        </div>        
        <div v-else class="list gallery-type" ref="container">
          <template v-if="content?.length > 0">  
            <div v-for="(item, index) in content" :key="item.id" 
                 :class="{ 'gallery-active': selected.includes(item), [item.className]: !!item.className }" class="item"
                 @click="handleClickItem($event, item, index)"
                 @dblclick="$emit('open', item)"
                 @contextmenu.prevent="handleContextMenu($event, item)"
            >
                <div class="item-status">
                  <template v-if="!item._loadError && item.hasThumbnail">
                    <img
                      :src="`/api/public/thumbnail?token=${item.token}`"
                      :alt="item.filename"
                      :style="{height: '60%', objectFit: item.contentType.startsWith('image/svg') ? 'contain' : 'cover'}"                       
                      class="thumbnail"
                      loading="lazy"
                      @error="item._loadError = true"
                      @dragstart.prevent="false"
                    >
                  </template>
                  <FileIconColumn v-else :file="item"/>
                  <div class="checkbox" v-if="deletedShareYn !== 'D'">
                    <input type="checkbox" name="idx[]" class="chk" :id="'chk'+(index)" :value="(index)" />
                    <label :for="'chk'+(index)"></label>
                  </div>
                  <div class="share" >
                    <template v-if="item.sharedOut == true && deletedShareYn !== 'Y' && deletedShareYn !== 'D'">
                        <a href="#" class="file-sharing"><img src="@/assets/img/ico-sharing.png" alt="sharing" id="sharing" /></a>
                    </template>
                  </div>
                  <div class="favorites">
                      <template v-if="item.starred === true">
                          <input type="checkbox" name="favorites[]" :id="item.id" value="on" checked>
                      </template>
                      <template v-else>
                          <input type="checkbox" name="favorites[]" :id="item.id" value="on">
                      </template>
                      <label :for="'favorites'+(index)"></label>
                  </div>
              </div>
              <div class="filename">               
                <span :tooltip="item.filename" flow="up"> {{ contextType(item.filename) }}</span>  
              </div>
          </div>
          </template>
          <template v-else-if="content">
              <div class="item">
                <div class="item-status empty"> {{ L('File not found') }}</div>
              </div>
          </template>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="copy">Copyright Smartertools Co., Ltd</span>
      <span class="url">www.smartertools.co.kr</span>
    </div>
    <div class="inner-info">
      <FileDetail ref="fileDetail"/>
    </div>
  </div>
  <ShareUserPanel ref="shareUser"  :files="selected" @select="load(page.page)"/>
  <UserPanel ref="UserPanel"  :files="selected" @select="load()"/>
</template>

<script>
import { commonLoad } from '../../assets/js/common';
import { FILE_LIST_SIZE, IS_MOBILE, PAGE_COUNT_SIZE } from '../../const';
import { usePreferenceStore } from '../../stores/preference';
import { useSessionStore } from '../../stores/session';
import { cutByteLength, showToast } from '../../utils';

import DragSelectContainer from './utils/DragSelectContainer.vue';
import FileIconColumn from './utils/IconColumn.vue';

import FileDetail from './FileDetail.vue';
import FileHeader from './FileHeader.vue';

import api from '../../api';
import ShareUserPanel from '../panel/ShareUserPanel.vue';
import UserPanel from '../panel/UserInfo.vue';

export default {
  components: { FileIconColumn,  FileHeader, FileDetail, DragSelectContainer, ShareUserPanel, UserPanel },
  emits: ['select', 'open', 'menu', 'updated','headHandle','selectionBoxReset','allCheck','share'],
  setup() {
    const session = useSessionStore();
    const preference = usePreferenceStore();
    return { session, preference }

  },
  props: {
    path: {
      type: String,
    },
    columns: {
      type: Array,
      required: true,
    },
    loader: {
      type: Function,
      required: true,
    },
    defaultSort: {
      type: String,
      required: true,
    }, 
    emptyMessage: {
      type: String,
      required: true,
    },

    FileHeaderTitle: String,
    FileHeaderDisplay : String,
    RecycleYn : String,          
    shareYn  : String,       
    deleteYn  : String,       
    etcYn  : String,
    upload: String, 
    newFolder: String, 
    newDoc: String, 
    deletedShareYn : String,
    download : String,
    disableFolderSelection: Boolean,
  },

  data() {
    return {

      content: null,
      totalElements: 0,
      sort: this.defaultSort,
      page: {
            sort: localStorage.getItem('sort'),
            total: localStorage.getItem('total'),
            page: localStorage.getItem('page') | 0,
            count: 5
      },
      totalpage: 0,
      preNext : false,
      selected: [],
      selectionBox: [],
      checkarr: [],
      allChecked: false,
      files: [],
      headerHeight: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
      asideWidth: 0, // 초기값으로 0을 설정하거나 적절한 기본값을 설정합니다.
      starredYn: true,
      selectionType : null,
      oldCheckValue:null,
    };
  },
  computed: {    
    IS_MOBILE() {
      return IS_MOBILE;
    },
  },

  watch: {
    
    path() {
      
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(0);
    },
    columns() { 
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(this.page.page);
    },
    loader() {
      if(this.page.page === null) this.page.page = 0
      if (this.loader) this.load(this.page.page);
    },
    // selected() { 
    //   if(this.page.page === null) this.page.page = 0
    //   if (this.loader) this.load(this.page.page);
    // },
    defaultSort(){
      this.sort = this.defaultSort;
    }
  },

  mounted() {
   
    if(this.session.login.admin === true){
      this.$router.push("/adminShare");
    }else{
      if(this.page.page === null) this.page.page = 0
      //if(this.page.sort !== null) this.sort = this.page.sort
      if (this.loader) this.load(this.page.page);
    

      const headerElement = document.querySelector('.top-header');
      if (headerElement) {
        this.headerHeight = headerElement.offsetHeight;
      }

      const asideElement = document.querySelector('.left-inner-content');
      if (asideElement) {
        this.asideWidth = asideElement.offsetWidth;
      }

      const tableElement = document.querySelector('.table-thead');
      if (tableElement) {
        this.tableHeight = tableElement.offsetHeight;
      }
    }
    this.sort  = this.defaultSort;

    api.addSseListener('file-list-update', (m) => {
      if (m.eventType.startsWith('LIVE:'))
        this.load(0);
    });
  },
  unmounted() {
    api.removeSseListener('file-list-update');
  },
  updated(){
    commonLoad();
  },
  methods: {

    onPage(_page, _sort) {
      localStorage.setItem('page', _page);
      localStorage.setItem('sort', _sort); 
    },

    onSort(_command, _sort){ 
        this.sort =  _command +"," + _sort;
        this.load(0);
    },

    async load(page) {
      const result = await this.loader(this.sort, page);
      
      if (!result) return;

      this.content = result.content;
      this.content.forEach(item => {
        if (item.contentType.startsWith('image/') && !item.contentType.startsWith('image/svg')) {
          item.hasThumbnail = true;
          return;
        }

        const ext = item.filename.split('.').pop().toLowerCase();
        item.hasThumbnail = ['docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt', 'pdf', 'txt'].includes(ext);
      });

      //this.content = this.content.splice(0,5);

      this.totalElements = result.totalElements;
      this.totalpage = result.totalPages;
      if(PAGE_COUNT_SIZE < this.totalpage) this.preNext = true;     

      this.page = { 
            sort: this.sort,
            total: result.totalElements,
            page: page,
            count: FILE_LIST_SIZE
      }  
      this.updateCheckAll();

      this.$emit('updated', this.content);

    },

    handleClickItem(e, item, index) {

      let selected = [...this.selected];

      if (!e.ctrlKey && !e.shiftKey) {
        if(e.target.type === "checkbox"){
          if(e.target.checked){
            selected.push(item);
          }else{
            let _idx =selected.findIndex(v => v.id ===  item.id);
            if(_idx > -1) selected.splice(_idx, 1);
          }          
        }else{
          
          if(e.target.nodeName != 'LABEL'){ 
            if(e.target.id === "sharing"){
              this.$refs.shareUser.show(item);
            }else if(e.target.id === "password"){
              let _taregt = e.target.parentNode;
              if(e.target.children[0] != undefined){
                _taregt = e.target;
              }
              if(e.target.title == 'hide'){
                  e.target.title = 'show';
                  _taregt.children[1].style.display="none";
                  _taregt.children[2].style.display="";
                  _taregt.children[0].textContent = item.password;
                }else{
                  e.target.title = 'hide';
                  _taregt.children[1].style.display="";
                  _taregt.children[2].style.display="none";
                  _taregt.children[0].textContent = "******";
                }
            }else if(e.target.id === "linkUrl"){
              navigator.clipboard.writeText(e.target.title).then(() => { 
                showToast(this.L('Link copied to clipboard'), 'success');
              });
            }else if(e.target.id === "sharedBy"){
              /*공유 받은 파일일 경우에는 공유한 사용자 정보 팝업 호출 */
              this.$refs.UserPanel.show(item);

            }else{
                            
              let _idx =selected.findIndex(v => v.id ===  item.id);
              if(selected.length == 1){
                selected = [item];
                document.querySelectorAll('.chk').forEach(function(ul, j) {
                  if( j == index){
                      if(!ul.checked) ul.checked = true;
                  }else{
                    if(ul.checked) ul.checked = false;
                  }
                });
              }else{
                if(_idx < 0){
                  selected = [item];
                
                  document.querySelectorAll('.chk').forEach(function(ul, j) {
                    if( j == index){
                        if(!ul.checked) ul.checked = true;
                    }else{
                      if(ul.checked) ul.checked = false;
                    }
                  });
                }
              }
            }
          }
        }

      } else if (e.ctrlKey) {

        if(!document.getElementById('chk'+(index)).checked){
          selected.push(item);
        }else{
          let _idx =selected.findIndex(v => v.id ===  item.id);

          if(_idx > 0) selected.splice(_idx, 1);
        }

        document.querySelectorAll('.chk').forEach(function(ul, j) {
          if( j == index){
            ul.checked = true;
          }
        });

      } else if (e.shiftKey) {

        let _idx =this.content.findIndex(v => v.id ===  selected[selected.length - 1].id);
        
        if(_idx < index){
          document.querySelectorAll('.chk').forEach(function(ul, j) {
            if(  _idx <= j  && index >= j){
              if(!ul.checked) ul.checked = true;
            }
          });
          
          for(let i=0; i<this.content.length; i++){
            if(  _idx <= i &&  index >= i){
              selected.push(this.content[i]);
            }
          }

        }else{
          document.querySelectorAll('.chk').forEach(function(ul, j) {
            if(  index <= j  && _idx >= j){
              if(!ul.checked) ul.checked = true;
            }
          });

          for(let i=0; i<this.content.length; i++){
            if(  index <= i  && _idx >= i){
              selected.push(this.content[i]);
            }
          }
        }
      }      
    
      this.selected = selected;

      if( document.getElementById('chk_all') != null){        
        if(this.selected.length > 0){
          document.getElementById('chk_all').checked = true;
          let component = document.querySelectorAll('.btn-brown-outline');
          for(var i=0 ; i<component.length; i++){
            for(var j=0; j <component[i].classList.length; j++){
              component[i].classList.add('checked-on');
            }
          }
        }else{
          document.getElementById('chk_all').checked = false;
        }
      }
        
      let _idx2 =this.selected.findIndex(v => v.starred === false);
      if(_idx2 < 0){
        this.starredYn =false;
      } else{
        this.starredYn =true;
      }

      this.$emit('select', this.selected);
      
    },

    handleContextMenu(event, item) {

      let _selectedIdx =this.selected.findIndex(v => v.id ===  item.id);

      if(_selectedIdx < 0){
        this.selected = [item];
        let _idx =this.content.findIndex(v => v.id ===  item.id);

        document.querySelectorAll('.chk').forEach(function(ul, j) {        
          if( j == _idx){
            if(!ul.checked) ul.checked = true;
          }else{
            if(ul.checked) ul.checked = false;
          }
        });
     }

      this.$emit('select', this.selected);
      this.$emit('menu', event);
      
    },
  
    // 상위 컴포넌트에서 파일 목록의 파일을 제거하려는 경우 사용
    removeFiles(files) {
      let component = document.querySelectorAll('.btn-brown-outline');
      for(let i=0 ; i<component.length; i++){
        for(let j=0; j <component[i].classList.length; j++){
          if(component[i].classList[j] === 'checked-on'){
            component[i].classList.remove('checked-on');
          }
        }
      }
      
      if (files.length) {
        files.forEach(f => {
          const idx = this.content.findIndex(item =>
            ('path' in f && item.path === f.path) || (
              'location' in f && item.location === f.location && item.filename === f.filename
            ));
          this.content.splice(idx, 1);
        });
      }

      this.$emit('select', this.selected = []);
      this.$emit('updated', this.content);
      this.load(0);
    },

    // 상위 컴포넌트에서 파일 목록에 추가/갱신할 경우 사용
    async updateList(files) {      
      if (!files || !files.length) return;
      this.load(0);
    },

    async handleScroll(e) {
      if (this.content.length >= this.totalElements) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !this.loading) {
        this.loading = true;
        await this.load(this.page.page + 1);
        this.loading = false;
      }
    },

    fileDetailInfo(item) {
      this.$refs.fileDetail.show(item);            
    },

    headerHandleCommand(command) {
      this.$emit('headHandle' ,command);
    },

    pageHandle(idx){ 
     this.load(idx -1);
    },

    selectionBoxReset(){
      this.selected = [];
      this.selectionBox = [];
      this.starredYn=true;
      this.selectionType="";
      
      this.unSelectColor();

    },

    unSelectColor(){
      if(this.preference.layout === "table"){
        document.querySelectorAll('tr').forEach(function(ul) {
          ul.style.backgroundColor="";
        });
      }else{
        document.querySelectorAll('.item').forEach(function(ul) {
          ul.style.backgroundColor="";
        });
      }
    },
    allCheck(event){
      this.selected=[];
      this.selectionBox=[];

      if(this.content.length == 0) {
        event.target.checked = false;
        return;
      }
      
      if(event.target.checked){
        for(var i=0; i < this.content.length; i++){
          this.selected.push(this.content[i]);
          this.selectionBox.push(this.content[i]);
        }
      }

      let _idx2 =this.selectionBox.findIndex(v => v.starred === false);

      if(_idx2 < 0){
        this.starredYn =false;
      } else{
        this.starredYn =true;
      }

      this.selectionType = null;
      if(this.selectionBox.length > 0) {
        const result = this.selectionBox.filter((elem) => {
          return (elem.contentType === "FOLDER");
        });
        
        if(result.length ===0){
          this.selectionType = "FILE";
        }else{
          if(result.length == this.selectionBox.length){
            this.selectionType = "FOLDER";
          }else{
            this.selectionType = "MIX";
          }
        }
      } 

      if(this.preference.layout != 'table'){ 
        document.querySelector('.count-file').querySelector('.count').innerHTML = this.content.length;
      }

      this.$emit('select', this.selected); 
    },

    shareReset(){
      this.selected = [];
      let component = document.querySelectorAll('.btn-brown-outline');
      for(var i=0 ; i<component.length; i++){
        for(var j=0; j <component[i].classList.length; j++){
          if(component[i].classList[j] === 'checked-on'){
            component[i].classList.remove('checked-on');
          }
        }
      }

      document.querySelectorAll('.chk').forEach(function(ul, j) {
        if(ul.checked) ul.checked = false;
      });

      if(this.preference.layout != 'table'){ 
        if(document.querySelectorAll('.count-file').length > 0){
          document.querySelectorAll('.count-file')[0].classList.remove('on');
        }
      }
      this.load(this.page.page);
    },

    share(){      
      this.$refs.fileHeader.share();
    },
    contextType1(filename){
      return cutByteLength(filename,34);
    },

    updateCheckAll() {
      if (this.$refs.checkAll) {
        const chk = this.$refs.checkAll[0];
        // const contentCount = this.disableFolderSelection ? this.content.filter(item => !item.isFolder).length : this.content.length;
        chk.checked = this.selected.length > 0;//&& this.selected.length === contentCount;
        chk.indeterminate = !chk.checked && this.selected.length;
      }
    },

    contextType(filename){
      const check_num = /[0-9]/;      // 숫자 
      const check_eng = /[a-zA-Z]/;   // 영문
      const check_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글      
      const check_engkor = /[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 영문+한글
      const check_numengkor = /[0-9a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 숫자+영문+한글

      let txt = "";
      if( check_num.test(filename) ){
          if(filename.length > 15){
            txt = filename.substring(0,15)+'...';
          }else{
            txt = filename;
          }         
          return txt;
      }else if( check_eng.test(filename) ){
          if(filename.length > 15){
            txt = filename.substring(0,15)+'...';
          }else{
            txt = filename;
          }
          return txt;
      }else if( check_kor.test(filename) ){
          if(filename.length > 10){
            txt = filename.substring(0,10)+'...';
          }else{
            txt = filename;
          }
          return txt;
      }else if( check_engkor.test(filename) ){
          if(filename.length > 10){
            txt = filename.substring(0,10)+'...';
          }else{
            txt = filename;
          }
          return txt;
      }else if( check_numengkor.test(filename) ){
          if(filename.length > 10){
            txt = filename.substring(0,10)+'...';
          }else{
            txt = filename;
          }
          return txt;
      }
     
    }
  },
}
</script>
<style>
/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size:  1em; /* opinion 3 */
  line-height: 2;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 99999; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 15em;
  max-width: 15em;
  white-space: normal;
  overflow: visible;  
  padding: 1ch 1.5ch;
  border-radius: .5ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 99999; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  word-break: break-all;
  transform: translate(-50%, -.5em); 
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}










/* UNRELATED to tooltips */
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  background: #ededed;
}
main {
  flex: 1 1 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
aside {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #49b293;
  color: #fff;
  padding: 1em;
}
main div {
  text-align: center;
  color: #353539;
}
main span {
  padding: .5em 1em;
  margin: .5em;
  display: inline-block;
  background: #dedede;
}

aside a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  padding: .4em 1em;
}
</style>