const storage = {
  async get(key) {
    if (window.flutter_inappwebview?.callHandler) {
      return window.flutter_inappwebview.callHandler('getStorageValue', key);
    }

    return localStorage[key];
  },

  async set(key, value) {
    if (window.flutter_inappwebview?.callHandler) {
      return window.flutter_inappwebview.callHandler('setStorageValue', key, value);
    }

    localStorage[key] = value;
  },
}

export default storage;

// window['myStorage'] = storage;
