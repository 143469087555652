<template>
	<Modal ref="baseModal">
		<div class="share-member">
      <div class="share-member-wrap" style="overflow: auto">
        <h4>{{L('Sharing Members')}}</h4>
        <table class="scrolltable">
        <thead>
        <tr><th><div class="checkbox">
                 
              <input type="checkbox" class="chk_all" id="share_chk_all" @click="allCheck($event)"/>
              <label for="share_chk_all"></label></div>
            </th>
            <th>{{L('User')}}</th>
            <th>{{L('AuthName')}}</th>
            <th>{{L('Shared Date')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in content" :key="item.id" @click="handleSelect($event, item)">
          <td class="item text-center">
            <div class="checkbox">
              <template v-if="item.shareName != ''">
                <input type="checkbox" name="idx[]" class="_chk" :id="'share_chk'+(index +1)" value="1"/>
                <label :for="'share_chk'+(index +1)"></label>
              </template>
              <template v-else>
                <input type="checkbox" name="idx[]" class="_chk" :id="'share_chk'+(index +1)" value="1"/>
                <label :for="'share_chk'+(index +1)"></label>
              </template>
            </div>
          </td>
          <td>
            <div class="profile">
              <div class="profile-thumb">
                <template v-if="item.shareName != ''">
                  <img v-if="item.profileImg === null||item.profileImg === undefined" style="width:2rem; border-radius: 1.25rem;" src="@/assets/img/default-profile.svg">
                  <img v-else style="width:2rem; border-radius: 1.25rem;" :src= item.profileImg> 
                </template>     
              </div>
              <span>{{item.shareName == '' ? L('SharedLink') : item.shareName }}</span> 
            </div>
          </td>
          <td class="item text-center">
            <template v-if="item.shareName == ''"> - </template>
            <template v-else> 
              {{ item.shareAuthName}}
            </template>
          </td>
          <td class="item text-center">{{formatDate(item.createdAt)}}</td>
        </tr>        
        </tbody>
        </table>
        <div class="confirm-buttons">
          <button class="btn btn-gray btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="close">{{L('Cancel')}}</button>
          <button class="btn btn-orange btn-round btn-large" style="padding-left: 2.25rem;padding-right: 2.25rem;" @click="handleSharingDelete">{{L('Decline share')}}</button>
        </div>
        <a href="#" class="btn-close" @click="close">
          <i class="icon icon-close"></i>
        </a>
      </div>
    </div>
	</Modal>
</template>


<script>
import api from '../../api';
import { ref } from "vue";

import Modal from "@/components/files/utils/Modal.vue";
import { L, alert2, showToast, formatDate, formatDateTime, overwriteConfirm } from "../../utils";


export default {
  name: "FolderModal",
  emits: ['select','close'],
  components: {
    Modal,
  },
  props:{
    files: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      content:[],
      selected: [],
      shareSelect:[],
      contentType:String,
      filename:String,
      path:String,
    };
  },

  methods :{
    formatDate, 
    formatDateTime,
    async load(item) {
      this.selected = [];
      this.shareSelect = [];
      this.contentType = item.contentType;
      this.filename = item.filename;
      this.path = item.path;

      this.content = await api.listShares(item.userId, item.path);
 
      for(var i=0; i< this.content.length; i++){
        if(this.content[i].shareWith != ''){ 
          const _profileImg = await api.getUserInfo(this.content[i].shareWith);
          this.content[i].profileImg = _profileImg.profileImg;
        }else{
          this.content[i].profileImg = "";
        }
      }
    },

    show(item){
        this.$refs.baseModal.open();
        this.load(item);
    },

    close(){
        this.$refs.baseModal.close();        
        
    },
    allCheck(event){
      this.selected=[];
      if(event.target.checked){
        for(var i=0; i < this.content.length; i++){
          this.selected.push(this.content[i]);
        }
        document.querySelectorAll('.share-member')[0].querySelectorAll('.checkbox').forEach(function(ul, j) {
          ul.children[0].checked = true;
        });
      }else{
        document.querySelectorAll('.share-member')[0].querySelectorAll('.checkbox').forEach(function(ul, j) {
          ul.children[0].checked = false;
        });
      }
    },
    handleSelect(e, item) {
      if(e.target.nodeName === "INPUT"){
            if(e.target.checked){
              this.selected.push(item);
            }else{
              let _idx =this.selected.findIndex(v => v.id ===  item.id);
              this.selected.splice(_idx, 1);
            }
      }
    },
    /* 공유 해제시 호출 스크립트 */  
    async handleSharingDelete() {
      if(this.selected.length == 0) {
        showToast(this.L('Please select an option'), 'success');
        return;
      }

      const unshareFileIds = [];
      unshareFileIds[0] = this.selected[0].fileId;

      const unshareResult = await api.checkOfficeLock(unshareFileIds);

      if (unshareResult.status && unshareResult.type =='officeLock'){
        if(this.contentType === "FOLDER"){
          showToast(this.L('Among the sub-files of the selected [{0}] directory, file [{1}] is in use.', this.filename,unshareResult.path.replaceAll("/home"+this.$route.query.path, ' ')), 'info');
          return;
        }else{
          showToast(unshareResult.filename+' '+this.L('Someone else is using the file.'), 'error');
          return;
        } 
      }

      let cont ="";
      for(var i=0; i <this.selected.length; i++){
        cont="{\"id\":\""+this.selected[i].id+"\", \"sharedBy\":\""+this.selected[i].sharedBy+"\"}" ; 
        this.shareSelect.push(cont)
      }
      const response = await api.unshare(this.shareSelect);
      if(response) {
        showToast(this.L('Share deleted'), 'success');
        this.$emit('select');
        this.close();
      }
    }  
  }
};
</script>
<style>
.scrolltable{
    table-layout: fixed;
    border-collapse: collapse;
}
.scrolltable thead {
    display:block;
    background: #f0f0f0;
    color:#fff;
}
.scrolltable tbody{
    display:block;
    overflow:auto;
    height:17rem; 
    width:100%;
}
/* 행 장식 */
.scrolltable th, .scrolltable td {
  padding: 10px;
  text-align: left;
  width: 9rem;
  text-align: center;
  font-size: 0.875em;
}
</style>


