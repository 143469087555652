<template>
	<div class="title-wrap sub">
		<h2 class="main-title" @click="goHome" style="cursor:pointer;">{{ FileHeaderTitle }}</h2>
		<ul class="sub-navigation" v-if="path?.length > 0">
			<template v-for="(code, idx) in (path.split('/'))"  :key="code" >
				<li @click="goto(idx)" v-if="idx > 0 && code != ''" style="cursor:pointer;">{{  code }}</li>
			</template>
		</ul>
	</div>
	<div class="flex-sb align-center">
		<div class="prefix">
			<div v-if="RecycleYn != 'Y'" class="btn-group">
				<div v-if="preference.layout === 'gallery'  && deletedShareYn !='Y' && deletedShareYn !='D'" class="checkbox" id="gallery_chk">
					<input type="checkbox" class="chk_all" id="chk_all"  @click="allCheck($event)"/>
					<label for="chk_all"></label>
				</div>

				<button v-if="upload == 'Y'" type="button" id="upload" class="btn btn-brown btn-dropdown" data-type="upload" @click="clickEvent($event)"><i class="icon icon-add"></i>{{ L('Upload') }}</button>

				<!-- 체크박스 선택 안했을 때 -->
				<button v-if="newFolder == 'Y'" type="button" id="newFolder" class="btn btn-brown-outline btn-none-checked" @click.prevent="$emit('command', 'new')">{{L('New folder')}}</button>
				<button v-if="newDoc == 'Y'" type="button" id="newDoc" class="btn btn-brown-outline btn-none-checked btn-dropdown" data-type="doc" @click="clickEvent($event)">{{L('New File')}}</button>
				<!-- 체크박스 선택 했을 때 -->
				<button v-if="download == 'Y'" type="button" id="download" class="btn btn-brown-outline btn-checked" @click.prevent="$emit('command', 'download')">{{L('Download')}}</button>
				<button v-if="shareYn == 'Y'" type="button" class="btn btn-brown-outline btn-checked" @click.prevent="share">{{ L('Sharing') }}</button>
				<button v-if="deleteYn == 'Y'" type="button" class="btn btn-brown-outline btn-checked" @click.prevent="deleteConfirm">{{L('Delete')}}</button>
				<!-- <button v-if="etcYn == 'Y'" type="button" class="btn btn-brown-outline btn-checked btn-dropdown" data-type="etc" @click="clickEvent($event)">&hellip;</button> -->
				<button type="button" class="btn btn-brown-outline btn-checked"  @click.prevent="$emit('command', 'star')">{{ starredYn === true ? L('Add to starred') : L('Remove from starred')}}</button>
				
				<button type="button" class="btn btn-brown btn-trash full-delete on" style="pointer-events:none;background-color: white;color: black;width: 40rem;">
					{{'휴지통에 있는 항목은 30일 후 완전삭제됩니다.'}}
				</button>
				<button type="button" class="btn btn-brown btn-trash select-delete">
					<i class="icon icon-x-white"></i>{{L('Delete permanently')}}
				</button>				
				<button type="button" class="btn btn-brown-outline btn-trash restore">{{L('Restore')}}</button>


				<div v-if="preference.layout === 'gallery'" class="count-file">
					<span class="count"></span>
					<span class="text">개 선택</span>
				</div>
				<ul class="dropdown-menu" data-dropdown="upload">
					<li><a href="#" @click.prevent="$emit('command', 'upload-files')">{{L('File upload')}}</a></li>
					<li><a href="#" @click.prevent="$emit('command', 'upload-folder')">{{L('Folder upload')}}</a></li>
				</ul>
				<ul class="dropdown-menu" data-dropdown="doc">
					<li><a href="#" @click.prevent="$emit('command', 'createWord')">{{L('Word')}}</a></li>
					<li><a href="#" @click.prevent="$emit('command', 'createExcel')">{{L('Excel')}}</a></li>
					<li><a href="#" @click.prevent="$emit('command', 'createPpt')">{{L('PowerPoint')}}</a></li>
				</ul>
				<ul class="dropdown-menu" data-dropdown="etc">
					<li v-if="item.length <= '1'"><a href="#" @click.prevent="$emit('command', 'link')">{{L('Create Link')}}</a></li>
					<li v-if="item.length <= '1'"><a href="#" @click.prevent="$emit('command', 'detail')">{{L('View details')}}</a></li>						
					<li v-if="selectionType === 'FILE'"><a href="#copy" @click.prevent="$emit('command', 'copy')">{{L('Copy to')}}</a></li>
					<li><a href="#move" @click.prevent="$emit('command', 'move')">{{L('Move to')}}</a></li>
					<li v-if="item.length <= '1'"><a href="#" @click.prevent="$emit('command', 'rename')">{{L('Rename')}}</a></li>
					<li v-if="starredYn === true"><a href="#" @click.prevent="$emit('command', 'star')">{{L('Add to starred')}}</a></li>
					<li v-else><a href="#" @click.prevent="$emit('command', 'star')">{{L('Remove from starred')}}</a></li>
				</ul>
			</div>

			<div v-else class="btn-group trash">
				<!-- 체크박스 선택 했을 때 -->
				<div v-if="preference.layout === 'gallery'" class="checkbox trash-all-check">
					<input type="checkbox" class="chk_all" id="chk_all" @click="allCheck($event)"/>
					<label for="chk_all"></label>
				</div>
				<!-- 체크박스 선택 안했을 때 -->
				<button type="button" class="btn btn-brown btn-trash full-delete on" style="pointer-events:none;background-color: white;color: black;width: 40rem;">
					{{'휴지통에 있는 항목은 30일 후 완전삭제됩니다.'}}
				</button>				
				<button type="button" class="btn btn-brown btn-trash select-delete" @click.prevent="$emit('command', 'delete-perm')">
					<i class="icon icon-x-white"></i>{{L('Delete permanently')}}
				</button>
				<button type="button" class="btn btn-brown-outline btn-trash restore" @click.prevent="$emit('command', 'restore')">{{L('Restore')}}</button>
			</div>
		</div>

		<div class="suffix">
			<div class="change-content-type">
				<a v-if="preference.layout === 'gallery'" href="#" class="filter" data-filter="filter" id="gallery_filter" value="lastModified" @click="filterClickEvent">{{L('Modified date')}}</a>
				<a v-if="preference.layout === 'gallery'" href="#" class="filter" data-filter="sort" id="gallery_sort" value="desc" @click="filterClickEvent">{{L('Descending')}}</a>
				<a v-if="preference.layout === 'table'" href="#" class="content-type" @click="handleToggleLayout" data-content-type="list"><img src="@/assets/img/ico-gallery.png" alt=""></a>
				<a v-else href="#" class="content-type" @click="handleToggleLayout"  data-content-type="gallery"><img src="@/assets/img/ico-list.png" alt=""></a>
				<ul v-if="preference.layout === 'gallery'" class="filter-ul" id="filter">
					<li v-if="RecycleYn != 'Y'"><button type="button" value="lastModified" @click="sortFilter($event)">{{L('Modified date')}}</button></li>
					<li v-if="RecycleYn == 'Y'"><button type="button" value="lastModified" @click="sortFilter($event)">{{L('Deleted date')}}</button></li>
					<li><button type="button" value="size" @click="sortFilter($event)">{{L('Size')}}</button></li>
					<li><button type="button" value="filename" @click="sortFilter($event)">{{L('Name')}}</button></li>
					<li><button type="button" value="icon" @click="sortFilter($event)">{{L('Type')}}</button></li>
					<li v-if="RecycleYn != 'Y'"><button type="button" value="starred" @click="sortFilter($event)">{{L('Starred files')}}</button></li>
				</ul>
				<ul v-if="preference.layout === 'gallery'" class="filter-ul" id="sort">
					<li><button type="button" value="desc" @click="sort($event)">{{L('Descending')}}</button></li>
					<li><button type="button" value="asc" @click="sort($event)">{{L('Ascending')}}</button></li>
				</ul>
			</div>
		</div>
	</div>
	<ConfirmationModal ref="modal" :item="item"  @share="shareReset"/>
</template>

<script>

import { IS_MOBILE } from '../../const.js';
import { parentOf, alert2, confirm2 } from '../../utils.js';
import * as bootstrap from 'bootstrap';
import { usePreferenceStore } from '../../stores/preference';
import ConfirmationModal from "../../components/files/utils/ConfirmationModal.vue";

// import { confirm2, createSelection, parentOf, prompt2, showToast } from '../../utils';

export default {
  name: 'FilesHeader',
  emits: ['command','selectionBoxReset','sort','shareReset','share','allCheck'],
  components : { ConfirmationModal},

  setup() {
    return {
      preference: usePreferenceStore()
    };
  },
  props: {
    path: String,
   
    FileHeaderTitle: String,
    FileHeaderDisplay : String,
    starredYn :Boolean,
    selectionType : String,
    deletedShareYn : String,
    RecycleYn : String,
    shareYn : String, 
    deleteYn : String,
    etcYn : String,
    upload: String, 
    newFolder: String, 
    newDoc: String, 
    download : String,
    deletedShareYn : String,
    item: {
      type: Array,
      required: true,
    },
    page: {
	type: Object,
	required: true,
    },
  },

  computed: {
    items() {
      return (this.path || '').split('/').filter(x => x);
    },
  },
  
  data() {
    return {
      folderUploadSupported: false,
      paths:String,
      popupView : false,
    };
  },
  created() { 
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    this.folderUploadSupported = ('webkitdirectory' in input) && !IS_MOBILE;
  },
  updated(){
	
	if(this.preference.layout === 'gallery'){		
		const _sort = this.page.sort.split(',');
		document.querySelectorAll('.filter-ul[id=filter]').forEach(function(ul, j) {
			for(var i=0; i<ul.children.length; i++){
				if(ul.children[i].childNodes[0].value === _sort[0]) {
					document.getElementById('gallery_filter').innerText =ul.children[i].childNodes[0].textContent;
					document.getElementById('gallery_filter').setAttribute('value',ul.children[i].childNodes[0].value);
				
			 	}
			}
		});

		document.querySelectorAll('.filter-ul[id=sort]').forEach(function(ul, j) {
			for(var i=0; i<ul.children.length; i++){
				if(ul.children[i].childNodes[0].value === _sort[0]) {
					document.getElementById('gallery_sort').innerText =ul.children[i].childNodes[0].textContent;
					document.getElementById('gallery_sort').setAttribute('value',ul.children[i].childNodes[0].value);
				
			 	}
			}
		});		

	}
  },
  methods: {
    parentOf,
    handleToggleLayout(e) { 	
	let component = document.querySelectorAll('.btn-brown-outline');
	for(var i=0 ; i<component.length; i++){
		for(var j=0; j <component[i].classList.length; j++){
			if(component[i].classList[j] === 'checked-on'){
				component[i].classList.remove('checked-on');
			}
		}
	}
	
	this.$emit('selectionBoxReset');
       	this.preference.setLayout(this.preference.layout === 'gallery' ? 'table' : 'gallery');
	
    },
    goto(paths){
	let a= this.path.split('/');
	let b ="";
	for(var i=0; i <a.length; i++){ 
		if(i <= paths) {
			if( a ==='/' ) b +="";
			if( i !=0 ) b += '/' + a[i];
		} 
	}
	this.$router.push({ path: location.pathname, query: { path: b } });
    },
    goHome(){
	let rootPath = location.pathname;
	this.$router.push({ path: rootPath });
    },

    clickEvent(e){
	    let posX = e.target.offsetLeft;
            let dropdownUl = e.target.getAttribute('data-type');
    
            // 다른 버튼 클릭 했을 떄 기존 버튼에 해당하는 드롭다운 메뉴 끄기
            document.querySelectorAll('.dropdown-menu').forEach(function(ul, j) {
                if(ul.getAttribute('data-dropdown') !== dropdownUl) {
                    ul.classList.remove('active');
                }
            });
            // left 값 자동 계산
            document.querySelector('ul[data-dropdown="' + dropdownUl + '"]').style.left = posX + 'px';
            // 동일 버튼 클릭할 때마다 껐다 켜는 스위치
            document.querySelector('ul[data-dropdown="' + dropdownUl + '"]').classList.toggle('active');
    },

    filterClickEvent(e){
	let posX = e.target.offsetLeft;
        let filterUl = e.target.getAttribute('data-filter');
        
        // 다른 버튼 클릭 했을 떄 기존 버튼에 해당하는 드롭다운 메뉴 끄기
        document.querySelectorAll('.filter-ul').forEach(function(ul, j) {
		if(ul.getAttribute('id') !== filterUl) {
                        ul.classList.remove('active');
                    }
                });
	// left 값 자동 계산
	document.querySelector('.filter-ul[id="' + filterUl + '"]').style.left = posX + 'px';
	// 동일 버튼 클릭할 때마다 껐다 켜는 스위치
	document.querySelector('.filter-ul[id="' + filterUl + '"]').classList.toggle('active');
    },

    sortFilter(e){
	document.getElementById('gallery_filter').innerText =e.currentTarget.textContent;
	document.getElementById('gallery_filter').setAttribute('value',e.currentTarget.value);
	document.querySelectorAll('.filter-ul').forEach(function(ul, j) {
		ul.classList.remove('active');
        });
	
	let filter = e.currentTarget.value;
	let sort = document.getElementById('gallery_sort').getAttribute('value');

	this.$emit('sort', filter, sort);
    },

    sort(e){ 
	document.getElementById('gallery_sort').innerText =e.currentTarget.textContent;
	document.getElementById('gallery_sort').setAttribute('value',e.currentTarget.value);
	document.querySelectorAll('.filter-ul').forEach(function(ul, j) {
		ul.classList.remove('active');
        });
	let filter = document.getElementById('gallery_filter').getAttribute('value');
	let sort = e.currentTarget.value;

        this.$emit('sort', filter, sort);

    },
    openPopup(){
      this.popupView = (this.popupView) ? false : true
    },
    share(){
	
	this.$refs.modal.show();
    },
    shareReset(){
	this.$emit('shareReset');
    },

    allCheck(event){
	
	this.$emit('allCheck', event);
    },

	async deleteConfirm(){ // 삭제 팝업
		//const ok = await confirm2(this.L('Move to the recycle bin'), this.L('Delete?'), 'warning');
		//if (!ok) return;

		this.$emit('command', 'delete');
	},


  },
}
</script>