
// --- Contextmenu helper --------------------------------------------------
export const buttonShowHide = (bool) =>{
    let btnGroup = document.querySelector('.flex-sb > .prefix > .btn-group');
    if(btnGroup.classList.contains('trash') === false) {
        if(bool === true) {
            document.querySelectorAll('.btn-none-checked').forEach(function(item, i) {
                item.classList.add('checked-on');
            });
            document.querySelectorAll('.btn-checked').forEach(function(item, i) {
                item.classList.add('checked-on');
            });
            
            document.querySelectorAll('.count-file').forEach(function(item, i) {
                item.classList.add('on');
            });
        } else {
            document.querySelectorAll('.btn-none-checked').forEach(function(item, i) {
                item.classList.remove('checked-on');
            });
            document.querySelectorAll('.btn-checked').forEach(function(item, i) {
                item.classList.remove('checked-on');
            });
            
            document.querySelectorAll('.count-file').forEach(function(item, i) {
                item.classList.remove('on');
            });
        }
    } else {
        if(bool === true) {
            //document.querySelector('.btn-trash.full-delete').classList.remove('on');
            document.querySelector('.trash-all-check').classList.add('on');
            document.querySelector('.btn-trash.select-delete').classList.add('on');
            document.querySelector('.btn-trash.restore').classList.add('on');
        } else {
           // document.querySelector('.btn-trash.full-delete').classList.add('on');
            document.querySelector('.trash-all-check').classList.remove('on');
            document.querySelector('.btn-trash.select-delete').classList.remove('on');
            document.querySelector('.btn-trash.restore').classList.remove('on');
        }
    }
};

export const commonLoad = () =>{
    const listTypeEl = document.querySelector('.list').classList.contains('gallery-type');
    const trashEl    = document.querySelector('.flex-sb > .prefix > .btn-group').classList.contains('trash');
    // checked 체크
    if(document.querySelector('.chk_all') !== null) { 
        document.querySelector('.chk_all').addEventListener('change', function() {
            // 드롭다운된 모든 메뉴의 초기화
            document.querySelectorAll('.dropdown-menu').forEach(function(ul, i) {
                ul.classList.remove('active');
            });


            var all = this.checked;

            buttonShowHide(all);
    
            document.querySelectorAll('.chk').forEach(function(item,i) {
                item.checked = all;
                if(listTypeEl === true) {
                    if(all === true) {
                        item.closest('.item').classList.add('on-checked');
                    } else {
                        item.closest('.item').classList.remove('on-checked');
                    }
                }
            });
        });
    }
    // checkbox control
    document.querySelectorAll('.chk').forEach(function(item,i) {

        item.addEventListener('change', function() {
            // 드롭다운된 모든 메뉴의 초기화
            document.querySelectorAll('.dropdown-menu').forEach(function(ul, i) {
                ul.classList.remove('active');
            });
            // 체크박스 전체 체크 여부 판단 변수
            var allChk1 = true;
            // 체크된 체크박스가 하나라도 있을 때 상단 일부 버튼 노출 여부 판단 변수
            var allChk2 = false;
            if(this.checked === true) {
                this.closest('.item').classList.add('on-checked');
            } else {
                this.closest('.item').classList.remove('on-checked');
            }

            // list type이 gallery일 때만 동작하도록 예외 처리
            if(listTypeEl === true && trashEl !== true) {
                let lengthChecked = document.querySelectorAll('.chk:checked').length;
                let countFile = document.querySelector('.count-file');
                if(lengthChecked > 0) {
                    countFile.classList.add('on');
                } else {
                    countFile.classList.remove('on');
                }
                countFile.querySelector('.count').innerHTML = lengthChecked;
            }

            document.querySelectorAll('.chk').forEach(function(item2,j) {
                if(item2.checked === false) {
                    allChk1 = false;
                }
                if(item2.checked === true) {
                    allChk2 = true;
                }
            });

            buttonShowHide(allChk2);
            if(document.querySelector('.chk_all') !== null) {
                document.querySelector('.chk_all').checked = allChk2;
            }
        });
    });
    // 더블 클릭 이벤트
    document.querySelectorAll('.list .item').forEach(function(item, i) {
        item.addEventListener('dblclick', function() {
            //document.querySelector('.right-inner-content').classList.add('active');
        });
    });
    // 정보창 닫기
    if(document.querySelector('.info-close')){
        document.querySelector('.info-close').addEventListener('click', function() {
            document.querySelector('.right-inner-content').classList.remove('active');
            
            document.querySelectorAll('.checkbox').forEach(function(ul, j) {
                if(ul.children[0] !== undefined && ul.children[0].checked){
                  ul.children[0].click();
                }
              });
        })

        
    };

    document.addEventListener('click', function(e) {
        if(e.target.alt !== "contextMenu" && e.pointerType != ''){
            if(document.querySelectorAll('.custom-context-menu')[0] !== undefined){
               document.querySelectorAll('.custom-context-menu')[0].style.display="none";
            }
        }
        if(e.target.alt !== "mobileMenu"){
            if(document.getElementById('mobileMenu') !== null  && e.target.role !== "button"){            
                document.getElementById('mobileMenu').style.display= 'none';
                document.getElementById('mobileMenuImgL').style.display = 'block';
                document.getElementById('mobileMenuImgX').style.display = 'none';
            }
        }
        if(e.target.alt !== "profileImg"){
            if(document.getElementById('toggleImg') !== null){ 
                document.getElementById('toggleImg').style.display= 'none';
            }
        }
        
        let filterUl = e.target.getAttribute('data-filter');        
        document.querySelectorAll('.filter-ul').forEach(function(ul, j) {
		    if(ul.getAttribute('id') !== filterUl) {
                ul.classList.remove('active');
            }
        });
 
        let dropdownUl = e.target.getAttribute('data-type');
        document.querySelectorAll('.dropdown-menu').forEach(function(ul, j) {
            if(ul.getAttribute('data-dropdown') !== dropdownUl) {
                ul.classList.remove('active');
            }
        });
    })

    // 조직도 아코디언 div 선언
    let organizationChart = document.querySelector('.organization-chart');

    // tab menu control
    document.querySelectorAll('.tabs ul li a').forEach(function(menu, i) {
        
        menu.addEventListener('click', function(e) {

            let id = this.getAttribute('href');
            let currentLi = this.closest('li');
            let sblLi = Array.prototype.filter.call(currentLi.parentElement.children, function(ev){ return ev !== currentLi;});
            sblLi.forEach(function(li,j) {
                li.classList.remove('active');
            });
            currentLi.classList.add('active');

            let currentContent = document.querySelector(id);
            let sblContent = Array.prototype.filter.call(currentContent.parentElement.children, function(ev){ return ev !== currentContent;});
            sblContent.forEach(function(con,j) {
                con.classList.remove('active');
            });
            currentContent.classList.add('active');

            e.preventDefault();
        });
    });
    //  // 조직도 아코디언 메뉴
    //  if(organizationChart !== null) {
    //     document.querySelectorAll('.organization-chart > ul li.dropdown > span').forEach((li, i) => {
    //         li.addEventListener('click', function(e) {
    //             this.parentNode.classList.toggleClass('active');
    //             //this.classList.toggle('active');
    //             e.preventDefault();
    //         });
    //     });
    // }
    let pwForm = document.querySelectorAll('.pw-form');
    if(pwForm.length > 0) {
        pwForm.forEach((pw, i) => {
            pw.querySelector('.show-pw').addEventListener('click', function(e) {
                let pwType = pw.querySelector('input').getAttribute('type');
                if(pwType === 'password') {
                    pw.querySelector('input').setAttribute('type', 'text');
                } else if(pwType === 'text') {
                    pw.querySelector('input').setAttribute('type', 'password');
                }
                e.preventDefault();
            })
        })
    }
};